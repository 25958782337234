/**
 * 기존 메인페이지 강의 커리큘럼 요청
 */
export const LECTURE_CURRICULUM_REQUEST =
  '@introduce/LECTURE_CURRICULUM_REQUEST';
export const LECTURE_CURRICULUM_SUCCESS =
  '@introduce/LECTURE_CURRICULUM_SUCCESS';
export const LECTURE_CURRICULUM_FAILURE =
  '@introduce/LECTURE_CURRICULUM_FAILURE';

export const getLectureCurriculumRequest = payload => ({
  type: LECTURE_CURRICULUM_REQUEST,
  payload,
});

export const getLectureCurriculumSuccess = payload => ({
  type: LECTURE_CURRICULUM_SUCCESS,
  payload,
});

export const getLectureCurriculumFailure = payload => ({
  type: LECTURE_CURRICULUM_FAILURE,
  payload,
});

/**
 * 기존 메인의
 * 장르별 시대별 난이도별 무드별 요청 api
 */
export const SEARCH_REQUEST = '@introduce/SEARCH_REQUEST';
export const SEARCH_SUCCESS = '@introduce/SEARCH_SUCCESS';
export const SEARCH_FAILURE = '@introduce/SEARCH_FAILURE';

export const getSearchRequest = (payload, callback) => ({
  type: SEARCH_REQUEST,
  payload,
  callback,
});

export const getSearchSuccess = payload => ({
  type: SEARCH_SUCCESS,
  payload,
});

export const getSearchFailure = payload => ({
  type: SEARCH_FAILURE,
  payload,
});

/**
 * 기존 메인의
 * 선생님별 카데고리 요청 api
 */
export const TEACHER_ALL_REQUEST = '@introduce/TEACHER_ALL_REQUEST';
export const TEACHER_ALL_SUCCESS = '@introduce/TEACHER_ALL_SUCCESS';
export const TEACHER_ALL_FAILURE = '@introduce/TEACHER_ALL_FAILURE';

export const getTeacherAllRequest = payload => ({
  type: TEACHER_ALL_REQUEST,
  payload,
});

export const getTeacherAllSuccess = payload => ({
  type: TEACHER_ALL_SUCCESS,
  payload,
});

export const getTeacherAllFailure = payload => ({
  type: TEACHER_ALL_FAILURE,
  payload,
});

/**
 * 텔레마케팅 저장?(상담신청)
 */
export const POST_SELLING_BENEFIT_REQUEST =
  '@introduce/POST_SELLING_BENEFIT_REQUEST';
export const POST_SELLING_BENEFIT_SUCCESS =
  '@introduce/POST_SELLING_BENEFIT_SUCCESS';
export const POST_SELLING_BENEFIT_FAILURE =
  '@introduce/POST_SELLING_BENEFIT_FAILURE';

export const postSellingBenefitRequest = payload => ({
  type: POST_SELLING_BENEFIT_REQUEST,
  payload,
});

export const postSellingBenefitSuccess = payload => ({
  type: POST_SELLING_BENEFIT_SUCCESS,
  payload,
});

export const postSellingBenefitFailure = payload => ({
  type: POST_SELLING_BENEFIT_FAILURE,
  payload,
});

/**
 * 팝송 저작권 정보 요청 api
 */
export const GET_COPYRIGHT_REQUEST = '@introduce/GET_COPYRIGHT_REQUEST';
export const GET_COPYRIGHT_SUCCESS = '@introduce/GET_COPYRIGHT_SUCCESS';
export const GET_COPYRIGHT_FAILURE = '@introduce/GET_COPYRIGHT_FAILURE';
export const getCopyrightRequest = (payload, callback) => ({
  type: GET_COPYRIGHT_REQUEST,
  payload,
  callback,
});
export const getCopyrightSuccess = (payload, callback) => ({
  type: GET_COPYRIGHT_SUCCESS,
  payload,
  callback,
});
export const getCopyrightFailure = (payload, callback) => ({
  type: GET_COPYRIGHT_FAILURE,
  payload,
  callback,
});
