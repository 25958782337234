import * as song from 'shared/actions/song.action';

export const initialState = {
  artist: '',
  length: '',
  mediaScript: [],
  mediaUrl: '',
  thumbnail: '',
  teacher: '',
  titleEng: '',
  savedLyrics: [],
  purchase: false,
  start: 0,
  end: 0,
  hideSubscription: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case song.GET_SONG_SUCCESS:
      const {
        mediaUrl,
        artist,
        titleEng,
        mediaScript,
        thumbnail,
        teacher,
        upc,
        purchase,
        start = 0,
        end = 0,
        length = 0,
        hideSubscription = false,
      } = payload;

      return {
        ...state,
        mediaUrl,
        artist,
        titleEng,
        mediaScript,
        thumbnail,
        teacher,
        upc,
        purchase,
        start,
        end,
        length,
        hideSubscription,
      };
    case song.LIKE_LYRIC_SUCCESS:
      return {
        ...state,
        mediaScript: state.mediaScript.map((item, index) =>
          payload === index ? { ...item, userScriptChoice: true } : item,
        ),
      };
    case song.CANCEL_LIKE_LYRIC_SUCCESS:
      return {
        ...state,
        mediaScript: state.mediaScript.map((item, index) =>
          payload === index ? { ...item, userScriptChoice: false } : item,
        ),
      };
    case song.COMPLETE_SONG_SUCCESS:
      return {
        ...state,
        savedLyrics: payload.mediaScript,
      };
    default:
      return state;
  }
};
