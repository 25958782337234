export const SET_SUBMIT_STATUS = '@payment/SET_SUBMIT_STATUS';
export const setSubmitStatus = payload => ({
  type: SET_SUBMIT_STATUS,
  payload,
});

export const GET_PURCHASE_WAY_REQUEST = '@payment/GET_PURCHASE_WAY_REQUEST';
export const GET_PURCHASE_WAY_SUCCESS = '@payment/GET_PURCHASE_WAY_SUCCESS';
export const GET_PURCHASE_WAY_FAILURE = '@payment/GET_PURCHASE_WAY_FAILURE';

export const getPurchaseWayRequest = () => ({
  type: GET_PURCHASE_WAY_REQUEST,
});

/**
 *
 * @param {Object} payload 요청에 대한 결과 값 (요청한 상품)
 */
export const getPurchaseWaySuccess = payload => ({
  type: GET_PURCHASE_WAY_SUCCESS,
  payload,
});

/**
 *
 * @param {Object} payload 요청 실패
 */
export const getPurchaseWayFailure = payload => ({
  type: GET_PURCHASE_WAY_FAILURE,
  payload,
});

export const GET_PRODUCTS_PACKAGES_REQUEST =
  '@payment/GET_PRODUCTS_PACKAGES_REQUEST';
export const GET_PRODUCTS_PACKAGES_SUCCESS =
  '@payment/GET_PRODUCTS_PACKAGES_SUCCESS';
export const GET_PRODUCTS_PACKAGES_FAILURE =
  '@payment/GET_PRODUCTS_PACKAGES_FAILURE';

export const getProductsPackageRequest = () => ({
  type: GET_PRODUCTS_PACKAGES_REQUEST,
});

/**
 *
 * @param {Object} payload 요청에 대한 결과 값 (요청한 상품)
 */
export const getProductsPackageSuccess = payload => ({
  type: GET_PRODUCTS_PACKAGES_SUCCESS,
  payload,
});

/**
 *
 * @param {Object} payload 요청 실패
 */
export const getProductsPackageFailure = payload => ({
  type: GET_PRODUCTS_PACKAGES_FAILURE,
  payload,
});

export const GET_PRODUCT_REQUEST = '@payment/GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = '@payment/GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILURE = '@payment/GET_PRODUCT_FAILURE';

/**
 *
 * @param {Object} payload 요청 할 상품의 아이디
 */
export const getProductRequest = payload => ({
  type: GET_PRODUCT_REQUEST,
  payload,
});

/**
 *
 * @param {Object} payload 요청에 대한 결과 값 (요청한 상품)
 */
export const getProductSuccess = payload => ({
  type: GET_PRODUCT_SUCCESS,
  payload,
});
/**
 *
 * @param {Object} payload 요청 실패
 */
export const getProductFailure = payload => ({
  type: GET_PRODUCT_FAILURE,
  payload,
});

export const GET_PRODUCTS_REQUEST = '@payment/GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = '@payment/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = '@payment/GET_PRODUCTS_FAILURE';

export const getProductsRequest = payload => ({
  type: GET_PRODUCTS_REQUEST,
  payload,
});

export const getProductsSuccess = payload => ({
  type: GET_PRODUCTS_SUCCESS,
  payload,
});

export const getProductsFailure = payload => ({
  type: GET_PRODUCTS_FAILURE,
  payload,
});

export const GET_ORDER_ID_REQUEST = '@payment/GET_ORDER_ID_REQUEST';
export const GET_ORDER_ID_SUCCESS = '@payment/GET_ORDER_ID_SUCCESS';
export const GET_ORDER_ID_FAILURE = '@payment/GET_ORDER_ID_FAILURE';

export const getOrderIdRequest = payload => ({
  type: GET_ORDER_ID_REQUEST,
  payload,
});

export const getOrderIdSuccess = payload => ({
  type: GET_ORDER_ID_SUCCESS,
  payload,
});

export const getOrderIdFailure = payload => ({
  type: GET_ORDER_ID_FAILURE,
  payload,
});

export const POST_PURCHASE_INFO_REQUEST = '@payment/POST_PURCHASE_INFO_REQUEST';
export const POST_PURCHASE_INFO_SUCCESS = '@payment/POST_PURCHASE_INFO_SUCCESS';
export const POST_PURCHASE_INFO_FAILURE = '@payment/POST_PURCHASE_INFO_FAILURE';

export const postPurchaseInfoRequest = payload => ({
  type: POST_PURCHASE_INFO_REQUEST,
  payload,
});

export const postPurchaseInfoSuccess = payload => ({
  type: POST_PURCHASE_INFO_SUCCESS,
  payload,
});

export const postPurchaseInfoFailure = payload => ({
  type: POST_PURCHASE_INFO_FAILURE,
  payload,
});

export const SET_PRODUCT_INIT = '@payment/SET_PRODUCT_INIT';

export const setProductInit = () => ({
  type: SET_PRODUCT_INIT,
});

export const SET_PRODUCT_STATE = '@payment/SET_PRODUCT_STATE';

export const setProductState = (payload, error) => ({
  type: SET_PRODUCT_STATE,
  payload,
  error,
});

export const GET_SELL_USER_PURCHASE_REQUEST =
  '@auth/GET_SELL_USER_PURCHASE_REQUEST';
export const GET_SELL_USER_PURCHASE_SUCCESS =
  '@auth/GET_SELL_USER_PURCHASE_SUCCESS';
export const GET_SELL_USER_PURCHASE_FAILURE =
  '@auth/GET_SELL_USER_PURCHASE_FAILURE';
export const getSellUserPurchaseRequest = (payload, authRequest) => ({
  type: GET_SELL_USER_PURCHASE_REQUEST,
  payload,
});
export const getSellUserPurchaseSuccess = (payload, action) => ({
  type: GET_SELL_USER_PURCHASE_SUCCESS,
  payload,
  action,
});
export const getSellUserPurchaseFailure = (payload, action) => ({
  type: GET_SELL_USER_PURCHASE_FAILURE,
  payload,
  action,
});

export const SAVE_CURRENT_FORM = '@payment/SAVE_CURRENT_FORM';
export const saveCurrentForm = () => ({
  // will read data from state
  type: SAVE_CURRENT_FORM,
});
// export const GET_USER_PURCHASE_REQUEST = '@payment'
