import { GET, POST, DELETE } from 'shared/utils/ajax';

const path = `/rest/media/script`;
/**
 * 스크립트 정보 가져오기
 * @param {string} upc
 */
export const getSong$ = upc => GET(`${path}/${upc}`);

/**
 * 가사 좋아요 API
 * @param {object} userScriptId
 * userscriptId: {
 *   mediaScriptId,
 *   userId
 * }
 */
export const likeLyric$ = ({ userScriptId }) => POST(path, { userScriptId });

export const cancelLikeLyric$ = ({ userScriptId }) =>
  DELETE(path, { userScriptId });
