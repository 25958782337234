import { GET, POST, DELETE } from 'shared/utils/ajax';

const media = `/rest/media`;
const path = media + `/userPlayList`;

export const teacherAll$ = () => GET(`${path}/ALL`);

export const getUserPlaylist$ = () => GET(`${path}`);

export const postUserPlaylistItem$ = upc =>
  POST(`${media}/userPlayList/`, {
    userPlayListId: {
      mediaUpc: upc,
      userId: 9999,
    },
  });

export const deleteUserPlaylistItem$ = upc =>
  DELETE(`${media}/userPlayList/`, {
    userPlayListId: {
      mediaUpc: upc,
      userId: 9999,
    },
  });
