/**
 *
 */
export const GET_PRACTICE_USER_STUDY_STATUS_REQUEST =
  '@practice/GET_PRACTICE_USER_STUDY_STATUS_REQUEST';
export const GET_PRACTICE_USER_STUDY_STATUS_SUCCESS =
  '@practice/GET_PRACTICE_USER_STUDY_STATUS_SUCCESS';
export const GET_PRACTICE_USER_STUDY_STATUS_FAILURE =
  '@practice/GET_PRACTICE_USER_STUDY_STATUS_FAILURE';

export const getPracticeUserStudyStatusRequest = payload => ({
  type: GET_PRACTICE_USER_STUDY_STATUS_REQUEST,
  payload,
});

export const getPracticeUserStudyStatusSuccess = (payload, upc) => ({
  type: GET_PRACTICE_USER_STUDY_STATUS_SUCCESS,
  payload,
  upc,
});

export const getPracticeUserStudyStatusFailure = payload => ({
  type: GET_PRACTICE_USER_STUDY_STATUS_FAILURE,
  payload,
});

// export const GET_PRACTICE_RESULT_REQUEST =
//   '@practice/GET_PRACTICE_RESULT_REQUEST';
// export const GET_PRACTICE_RESULT_SUCCESS =
//   '@practice/GET_PRACTICE_RESULT_SUCCESS';
// export const GET_PRACTICE_RESULT_FAILURE =
//   '@practice/GET_PRACTICE_RESULT_FAILURE';

// export const getPracticeResultRequest = payload => ({
//   type: GET_PRACTICE_RESULT_REQUEST,
//   payload,
// });

// export const getPracticeResultSuccess = (payload, upc) => ({
//   type: GET_PRACTICE_RESULT_SUCCESS,
//   payload,
//   upc,
// });

// export const getPracticeResultFailure = payload => ({
//   type: GET_PRACTICE_RESULT_FAILURE,
//   payload,
// });

/**
 *
 */
export const GET_PRACTICE_REQUEST = '@practice/GET_PRACTICE_REQUEST';
export const GET_PRACTICE_SUCCESS = '@practice/GET_PRACTICE_SUCCESS';
export const GET_PRACTICE_FAILURE = '@practice/GET_PRACTICE_FAILURE';

export const getPracticeRequest = payload => ({
  type: GET_PRACTICE_REQUEST,
  payload,
});

export const getPracticeSuccess = (payload, upc) => ({
  type: GET_PRACTICE_SUCCESS,
  payload,
  upc,
});

export const getPracticeFailure = payload => ({
  type: GET_PRACTICE_FAILURE,
  payload,
});

/**
 * AI실전모드 3문제
 */
export const GET_PRACTICE_USER_STATUS_REQUEST =
  '@practice/GET_PRACTICE_USER_STATUS_REQUEST';
export const GET_PRACTICE_USER_STATUS_SUCCESS =
  '@practice/GET_PRACTICE_USER_STATUS_SUCCESS';
export const GET_PRACTICE_USER_STATUS_FAILURE =
  '@practice/GET_PRACTICE_USER_STATUS_FAILURE';

export const getPracticeUserStatusRequest = (payload, isFirst = false) => ({
  type: GET_PRACTICE_USER_STATUS_REQUEST,
  payload,
  isFirst,
});

export const getPracticeUserStatusSuccess = payload => ({
  type: GET_PRACTICE_USER_STATUS_SUCCESS,
  payload,
});

export const getPracticeUserStatusFailure = payload => ({
  type: GET_PRACTICE_USER_STATUS_FAILURE,
  payload,
});

export const SET_INIT = '@practice/SET_INIT';
export const setInit = () => ({
  type: SET_INIT,
});
export const SET_STATE = '@practice/SET_STATE';
export const setState = payload => ({
  type: SET_STATE,
  payload,
});

export const REVISIT_CHECK = '@practice/REVISIT_CHECK';
export const revisitCheck = payload => ({
  type: REVISIT_CHECK,
  payload,
});

export const SET_SCRIPT_INDEX = '@practice/SET_SCRIPT_INDEX';
export const setScriptIndex = payload => ({
  type: SET_SCRIPT_INDEX,
  payload,
});

export const SET_NEXT_STEP = '@practice/SET_NEXT_STEP';
export const setNextStep = payload => ({
  type: SET_NEXT_STEP,
  payload,
});

export const SET_PRACTICE_RECORD_DATA_STANDBY =
  '@practice/SET_PRACTICE_RECORD_DATA_STANDBY';
export const POST_PRACTICE_RECORD_DATA_REQUEST =
  '@practice/POST_PRACTICE_RECORD_DATA_REQUEST';
export const POST_PRACTICE_RECORD_DATA_SUCCESS =
  '@practice/POST_PRACTICE_RECORD_DATA_SUCCESS';
export const POST_PRACTICE_RECORD_DATA_FAILURE =
  '@practice/POST_PRACTICE_RECORD_DATA_FAILURE';
export const setPracticeRecordDataStandby = payload => ({
  type: SET_PRACTICE_RECORD_DATA_STANDBY,
  payload,
});
export const postPracticeRecordDataRequest = (
  mediaScriptSpeakId,
  base64String,
  upc,
) => ({
  type: POST_PRACTICE_RECORD_DATA_REQUEST,
  mediaScriptSpeakId,
  base64String,
  upc,
});
export const postPracticeRecordDataSuccess = payload => ({
  type: POST_PRACTICE_RECORD_DATA_SUCCESS,
  payload,
});
export const postPracticeRecordDataFailure = payload => ({
  type: POST_PRACTICE_RECORD_DATA_FAILURE,
  payload,
});

export const POST_PRACTICE_RECORD_PASS_REQUEST =
  '@practice/POST_PRACTICE_RECORD_PASS_REQUEST';
export const POST_PRACTICE_RECORD_PASS_SUCCESS =
  '@practice/POST_PRACTICE_RECORD_PASS_SUCCESS';
export const POST_PRACTICE_RECORD_PASS_FAILURE =
  '@practice/POST_PRACTICE_RECORD_PASS_FAILURE';

export const postPracticeRecordPassRequest = (mediaScriptSpeakId, upc) => ({
  type: POST_PRACTICE_RECORD_PASS_REQUEST,
  mediaScriptSpeakId,
  upc,
});
export const postPracticeRecordPassSuccess = payload => ({
  type: POST_PRACTICE_RECORD_PASS_SUCCESS,
  payload,
});
export const postPracticeRecordPassFailure = payload => ({
  type: POST_PRACTICE_RECORD_PASS_FAILURE,
  payload,
});
