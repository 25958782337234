export const GET_SONG_REQUEST = '@song/GET_SONG_REQUEST';
export const GET_SONG_SUCCESS = '@song/GET_SONG_SUCCESS';
export const GET_SONG_FAILURE = '@song/GET_SONG_FAILURE';

export const getSongRequest = payload => ({
  type: GET_SONG_REQUEST,
  payload,
});

export const getSongSuccess = payload => ({
  type: GET_SONG_SUCCESS,
  payload,
});

export const getSongFailure = payload => ({
  type: GET_SONG_FAILURE,
  payload,
});

export const LIKE_LYRIC_REQUEST = '@song/LIKE_LYRIC_REQUEST';
export const LIKE_LYRIC_SUCCESS = '@song/LIKE_LYRIC_SUCCESS';
export const LIKE_LYRIC_FAILURE = '@song/LIKE_LYRIC_FAILURE';

export const likeLyricRequest = (payload, idx) => ({
  type: LIKE_LYRIC_REQUEST,
  payload,
  idx,
});

export const likeLyricSuccess = payload => ({
  type: LIKE_LYRIC_SUCCESS,
  payload,
});

export const likeLyricFailure = payload => ({
  type: LIKE_LYRIC_FAILURE,
  payload,
});

export const CANCEL_LIKE_LYRIC_REQUEST = '@song/CANCEL_LIKE_LYRIC_REQUEST';
export const CANCEL_LIKE_LYRIC_SUCCESS = '@song/CANCEL_LIKE_LYRIC_SUCCESS';
export const CANCEL_LIKE_LYRIC_FAILURE = '@song/CANCEL_LIKE_LYRIC_FAILURE';

export const cancelLikeLyricRequest = (payload, idx) => ({
  type: CANCEL_LIKE_LYRIC_REQUEST,
  payload,
  idx,
});

export const cancelLikeLyricSuccess = payload => ({
  type: CANCEL_LIKE_LYRIC_SUCCESS,
  payload,
});

export const cancelLikeLyricFailure = payload => ({
  type: CANCEL_LIKE_LYRIC_FAILURE,
  payload,
});

export const COMPLETE_SONG_REQUEST = '@song/COMPLETE_SONG_REQUEST';
export const COMPLETE_SONG_SUCCESS = '@song/COMPLETE_SONG_SUCCESS';
export const COMPLETE_SONG_FAILURE = '@song/COMPLETE_SONG_FAILURE';

export const completeSongRequest = payload => ({
  type: COMPLETE_SONG_REQUEST,
  payload,
});

export const completeSongSuccess = payload => ({
  type: COMPLETE_SONG_SUCCESS,
  payload,
});

export const completeSongFailure = payload => ({
  type: COMPLETE_SONG_FAILURE,
  payload,
});
