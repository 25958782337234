import * as listen from 'shared/actions/listen.action';

export const initialState = {
  artist: '',
  mediaScript: [],
  mediaUrl: '',
  titleEng: '',
  thumbnail: '',
  upc: '',
  teacher: '',
  userSort: 0,
  stepSize: 0,
  purchase: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case listen.GET_LISTEN_REQUEST:
      return initialState;
    case listen.GET_LISTEN_SUCCESS:
      const {
        mediaUrl,
        artist,
        titleEng,
        mediaScript,
        thumbnail,
        upc,
        teacher,
        userSort,
        purchase,
      } = payload;

      const res = {
        ...state,
        mediaUrl,
        artist,
        titleEng,
        mediaScript,
        thumbnail,
        upc,
        teacher,
        userSort,
        stepSize: mediaScript.length,
        purchase,
      };
      return res;
    case listen.SAVE_LISTEN_STEP_SUCCESS:
      return {
        ...state,
        userSort: payload.length + 1,
      };
    default:
      return state;
  }
};
