export const TOAST_POPUP_SHOW = '@toast/TOAST_POPUP_SHOW';
export const TOAST_POPUP_RESET = '@toast/TOAST_POPUP_RESET';

export const toastPopupShow = payload => {
  return {
    type: TOAST_POPUP_SHOW,
    payload: {
      ...payload,
    },
  };
};

export const toastPopupReset = () => ({
  type: TOAST_POPUP_RESET,
});
