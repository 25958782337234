import { GET, POST } from 'shared/utils/ajax';

const media = `/rest/media`;
const path = `${media}/userMediaScriptSpeakPractice`;

/**
 * 미디어 듣기 가져오기
 * @param {string} upc
 */

export const getPracticeUserStatus$ = upc => GET(`${path}/${upc}`);

export const postUserMediaScriptSpeakPractice$ = (
  mediaScriptSpeakId,
  base64String,
  upc,
) =>
  POST(`${path}/${upc}`, {
    base64String,
    userMediaScriptSpeakPracticeId: {
      mediaScriptSpeakId,
      speaker: false,
    },
  });

export const postUserMediaScriptSpeakPracticePass$ = (
  mediaScriptSpeakId,
  upc,
) =>
  POST(`${path}/fail/pass/${upc}`, {
    userMediaScriptSpeakPracticeId: {
      mediaScriptSpeakId,
      speaker: false,
    },
  });
