import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import * as lecture from 'shared/actions/lecture.action';
import * as detailAPI from 'shared/apis/media/detail.api';
import * as userLectureAPI from 'shared/apis/lecture/userLecture.api';

import { errorHandler } from 'shared/epics/auth.epic';

const getLectureRequestEpic = action$ =>
  action$.pipe(
    ofType(lecture.GET_LECTURE_REQUEST),
    mergeMap(action =>
      detailAPI.getDetailSong$(action.payload).pipe(
        map(data => lecture.getLectureSuccess(data.result, action.step)),
        // catchError(err => of(lecture.getLectureFailure(err))),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const saveLectureRequestEpic = action$ =>
  action$.pipe(
    ofType(lecture.SAVE_LECTURE_REQUEST),
    mergeMap(action =>
      userLectureAPI.saveLecture$(action.payload, action.upc).pipe(
        map(data => {
          if (data.code !== 200) return lecture.saveLectureFailure(data.result);
          return lecture.saveLectureSuccess(data.result);
        }),
        // catchError(err => of(lecture.saveLectureFailure())),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(getLectureRequestEpic, saveLectureRequestEpic);
