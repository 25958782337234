import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';

import * as studyList from 'shared/actions/studyList.action';

import * as teacherAPI from 'shared/apis/media/teacher.api';

const teacherAllRequestEpic = action$ =>
  action$.pipe(
    ofType(studyList.GET_STUDY_LIST_ALL_REQUEST),
    mergeMap(action =>
      teacherAPI.teacherAll$(action.payload).pipe(
        map(response => {
          console.log(`teacherAllRequestEpic `, response);
          if (response.code !== 200) {
            return;
          }
          return studyList.getStudyListAllSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(teacherAllRequestEpic);
