import * as payment from 'shared/actions/payment.action';

import productItem from 'shared/data/productItem';

export const initialState = {
  userInfo: {
    // buyerAddress: '',
    // buyerMemo: '',
    // buyerName: '',
    // buyerPhone: '',
    // orderId: '',
    // product: '', // 상품명
    // purchaseDetails: [],
    // shippingCompany: '',
    // startDate: '',
    // endDate: '',
    // status: '', // ORDER_COMPLETE

    result: {
      userPurchaseInfoDto: {
        userPurchaseShippingInfos: [],
      },
    },

    error: {
      code: '',
      message: '',
    },
  },
  comment: '',
  sellType: 'NORMAL', // enum = [ "NORMAL", "TM_BUTTON"]
  products: [],

  // [{ 계좌이체 }, { 무통장 }]
  way: [],

  // [{ 신한 }, { 현대 } ...]
  card: [],

  // 요청한 특정 상품에 대한 정보
  currentItem: {},

  // 상품 가져오기에 실패했을 경우 Home으로 Redirect
  shouldRedirect: false,

  options: {
    type: {
      title: '결제수단',
      value: 'type',
      list: [
        {
          title: '신용카드',
          value: 'credit',
        },
        {
          title: '계좌 이체',
          value: 'bank',
        },
        // {
        //   title: '무통장 입금',
        //   value: 'untouched',
        // },
      ],
    },
    // term: {
    //   title: '할부 기간',
    //   value: 'term',
    //   list: [
    //     {
    //       title: '일시불',
    //       value: '1',
    //     },
    //     {
    //       title: '12개월',
    //       value: '12',
    //       disabled: 2,
    //     },
    //     {
    //       title: '18개월',
    //       value: '18',
    //       disabled: 1,
    //     },
    //   ],
    // },
    card: {
      title: '카드사',
      value: 'card',
      list: [
        { title: '하나', value: 'HANA', key: '32' },
        { title: '현대', value: 'HYUNDAE', key: '61' },
        { title: '삼성', value: 'SAMSUNG', key: '51' },
        { title: '신한', value: 'SHINEHAN', key: '41' },
        { title: '롯데', value: 'LOTTE', key: '71' },
        { title: '농협', value: 'NH', key: '91' },
        { title: '국민', value: 'KB', key: '11' },
        { title: '비씨', value: 'BC', key: '31' },
        // { title: '현대', value: 'HYUNDAE', key: '61' },
        // { title: '신한', value: 'SHINEHAN', key: '41' },
        // { title: '비씨', value: 'BC', key: '31' },
        // { title: 'KB국민', value: 'KB', key: '11' },
        // { title: '삼성', value: 'SAMSUNG', key: '51' },
        // { title: '롯데', value: 'LOTTE', key: '71' },
        // { title: '외환', value: 'KEB', key: '21' },
        // { title: 'NH채움', value: 'NH', key: '91' },
        // { title: '하나SK', value: 'HANA', key: '32' },
        // { title: '산업', value: 'SANUP', key: '29' },
        // { title: '우리', value: 'WOORI', key: '33' },
        // { title: '수협', value: 'SOOHUP', key: '34' },
        // { title: '신협', value: 'SHINHUP', key: '62' }, //신협체크
        // { title: '씨티', value: 'CITI', key: '36' },
        // { title: '광주', value: 'KWANGJOO', key: '46' },
        // { title: '전북', value: 'JUNBOOK', key: '35' },
        // { title: '제주', value: 'JEJU', key: '42' },
        // { title: '우체국', value: 'POSTBANK', key: '37' }, //우체국카드
        // { title: 'MG새마을', value: 'SAEMAUL', key: '38' }, //MG새마을체크
        // { title: '저축은행', value: 'OKSAVINGS', key: '39' }, //저축은행체크
        // { title: 'KDB산업', value: 'KDB', key: '30' }, //KDB산업은행
        // { title: '카카오', value: 'KAKAO', key: '15' },
      ],
      freeList18M: [
        { title: '하나', value: 'HANA', key: '32' },
        { title: '현대', value: 'HYUNDAE', key: '61' },
        { title: '삼성', value: 'SAMSUNG', key: '51' },
        { title: '신한', value: 'SHINEHAN', key: '41' },
        // { title: '롯데', value: 'LOTTE', key: '71' },
        { title: '농협', value: 'NH', key: '91' },
        { title: '국민', value: 'KB', key: '11' },
        // { title: '비씨', value: 'BC', key: '31' },
      ],
      freeList12M: [
        { title: '하나', value: 'HANA', key: '32' },
        { title: '현대', value: 'HYUNDAE', key: '61' },
        { title: '삼성', value: 'SAMSUNG', key: '51' },
        { title: '신한', value: 'SHINEHAN', key: '41' },
        { title: '롯데', value: 'LOTTE', key: '71' },
        { title: '농협', value: 'NH', key: '91' },
        { title: '국민', value: 'KB', key: '11' },
        // { title: '비씨', value: 'BC', key: '31' },
      ],
    },
  },
  state: {
    oid: '',
    type: '',
    term: '',
    card: '',
    // name: '조동민',
    // phone: '01029641129',
    // tel: '',
    // email: 'dmcho@qualson.com',
    // address: '서울시 송파구 중대로 24',
    // address_detail: '215동 1506호',
    // zipCode: '05834',
    // post: '부재시 경비실에 맡겨주세요',
    // agree: true,

    name: '',
    phone: '',
    tel: '',
    email: '',
    address: '',
    address_detail: '',
    zipCode: '',
    post: '',
    agree: false,

    selectedItem: {},

    status: '', // enum:['completed', "standby", "failed", "modal"]
  },
  isSubmitting: false,
  error: {
    name: {
      status: false,
      value: '',
    },
    phone: {
      status: false,
      value: '',
    },
    email: {
      status: false,
      value: '',
    },
    address: {
      status: false,
      value: '',
    },
    zipCode: {
      status: false,
      value: '',
    },
    agree: {
      status: false,
      value: '',
    },
  },
};

let nextState;
export default (state = initialState, { type, payload, error }) => {
  switch (type) {
    case payment.SET_SUBMIT_STATUS:
      return {
        ...state,
        isSubmitting: payload,
      };

    case payment.GET_PURCHASE_WAY_SUCCESS:
      // debugger;
      console.log({ payload });
      return {
        ...state,
        way: payload?.map(p => ({
          code: p.code,
          name: p.name,
          payId: p.lgXPayCode,
        })),
        card: payload[payload.findIndex(p => p.code === 'CARD')].items,
      };
    case payment.GET_PRODUCT_SUCCESS:
      console.log({ GET_PRODUCT_SUCCESS: payload, state });
      return {
        ...state,
        currentItem: payload,
        shouldRedirect: false,
      };
    case payment.GET_PRODUCT_FAILURE:
      return {
        ...state,
        currentItem: {},
        shouldRedirect: true,
      };

    case payment.GET_PRODUCTS_PACKAGES_SUCCESS:
      // console.log({
      //   GET_PRODUCTS_PACKAGES_SUCCESS: payload,
      // });
      return { ...state, products: payload };
    // return { ...state, products: [] };

    case payment.GET_SELL_USER_PURCHASE_SUCCESS:
      nextState = { ...state };
      console.log({
        GET_SELL_USER_PURCHASE_SUCCESS: payload,
      });

      nextState.userInfo = {
        ...state.userInfo,
        ...payload,

        error: {
          code: payload.code,
          message: payload.message,
        },
      };
      // console.log('GET_SELL_USER_PURCHASE_SUCCESS data', nextState);

      // console.log({ 'GET_PRODUCTS_PACKAGES_SUCCESS!!!': nextState });

      return nextState;

    case payment.GET_SELL_USER_PURCHASE_FAILURE:
      nextState = { ...state };
      console.log({
        GET_SELL_USER_PURCHASE_FAILURE: payload,
        message: payload.message,
        state: state,
      });

      nextState.userInfo.error.code = payload.code;
      nextState.userInfo.error.message = payload.message;
      // nextState.userInfo.error = {
      //   code: payload.code,
      //   message: payload.message,
      // };

      // console.log('nextState', { nextState });
      return nextState;

    case payment.SET_PRODUCT_INIT:
      nextState = { ...initialState };
      return nextState;

    case payment.GET_ORDER_ID_SUCCESS:
      nextState = { ...state };
      nextState.state = {
        ...state.state,
        oid: payload.orderId,
      };

      return nextState;

    case payment.SET_PRODUCT_STATE: {
      // console.log(
      //   '================= SET_PRODUCT_STATE LOG START ===================',
      // );
      // console.log(payload);
      // console.log(
      //   '================= SET_PRODUCT_STATE LOG END ===================',
      // );
      nextState = { ...state };
      // console.log('nextState', { state: nextState.state, payload });
      nextState.state = {
        ...state.state,
        ...payload,
      };
      nextState.error = {
        ...state.error,
        ...error,
      };

      // console.log('================= LOG START ===================');
      // console.log(nextState);
      // console.log('================= LOG END ===================');

      return nextState;
    }

    case payment.GET_PRODUCTS_SUCCESS:
      // 데이터가 어떻게 오냐에 따라 구조화 시킬 것!
      nextState = { ...state };

      nextState.sellType = payload.btnType;
      nextState.comment = payload.comment;
      if (Array.isArray(payload.item)) {
        nextState.products = payload.item
          .sort((a, b) => {
            return b.id - a.id;
          })
          .map(item => {
            return productItem(item);
          });
      }
      console.log('GET_PRODUCTS_SUCCESS', { payload });

      return nextState;

    default:
      return state;
  }
};
