import { POST } from 'shared/utils/ajax';

/**
 *
 * @param {string} upc
 * {
 *   "sort": number,
 *   "userMediaScriptListenId": {
 *     "mediaScriptId": number
 *   }
 * }
 */

export const saveListenStep$ = ({ upc, data }) =>
  POST(`/rest/media/userMediaScriptListen/${upc}`, data);
