import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as word from 'shared/actions/word.action';
import * as recommendWordsAPI from 'shared/apis/media/recommendWords.api';
import * as dictionaryAPI from 'shared/apis/media/dictionary.api';
import * as userDictionaryAPI from 'shared/apis/media/userDictionary.api';
import { getLectureRecommendWords$ } from 'shared/apis/lecture/recommendWords.api';

import { errorHandler } from 'shared/epics/auth.epic';

const getLectureRecommendWordsRequestEpic = action$ =>
  action$.pipe(
    ofType(word.GET_LECTURE_RECOMMEND_WORDS_REQUEST),
    mergeMap(action =>
      getLectureRecommendWords$(action.payload).pipe(
        map(data => word.getRecommendWordsSuccess(data.result)),
        // catchError(err => of(word.getRecommendWordsFailure(err))),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getSongRecommendWordsRequestEpic = action$ =>
  action$.pipe(
    ofType(word.GET_SONG_RECOMMEND_WORDS_REQUEST),
    mergeMap(action =>
      recommendWordsAPI.getSongRecommendWords$(action.payload).pipe(
        map(data => word.getRecommendWordsSuccess(data.result)),
        // catchError(err => of(word.getRecommendWordsFailure(err))),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

// 에러 핸들링 테스트
const getRecommendWordsFailureEpic = action$ =>
  action$.pipe(
    ofType(word.GET_RECOMMEND_WORDS_FAILURE),
    mergeMap(action => console.log(action.payload)),
  );

// result 결과값 초기화 시키는 epic
const initSearchWordRequestEpic = action$ =>
  action$.pipe(
    ofType(word.INIT_SEARCH_WORD_REQUEST),
    map(() => word.initSearchWordSuccess()),
  );

const getSearchWordRequestEpic = action$ =>
  action$.pipe(
    ofType(word.GET_SEARCH_WORD_REQUEST),
    mergeMap(action =>
      dictionaryAPI.getSearchWord$(action.payload).pipe(
        map(data => word.getSearchWordSuccess(data.result)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const saveWordRequestEpic = action$ =>
  action$.pipe(
    ofType(word.SAVE_WORD_REQUEST),
    mergeMap(action =>
      userDictionaryAPI.saveWord$(action.payload).pipe(
        map(data => word.saveWordSuccess(action.word)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const cancelWordRequestEpic = action$ =>
  action$.pipe(
    ofType(word.CANCEL_WORD_REQUEST),
    mergeMap(action =>
      userDictionaryAPI.cancelWord$(action.payload).pipe(
        map(data => word.cancelWordSuccess(action.word)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(
  getLectureRecommendWordsRequestEpic,
  getSongRecommendWordsRequestEpic,
  getRecommendWordsFailureEpic,
  getSearchWordRequestEpic,
  initSearchWordRequestEpic,
  saveWordRequestEpic,
  cancelWordRequestEpic,
);
