import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as practice from 'shared/actions/practice.action';
import * as practiceAPI from 'shared/apis/media/practice.api';
import * as userMediaScriptSpeakPracticeAPI from 'shared/apis/media/userMediaScriptSpeakPractice.api';

import { errorHandler } from 'shared/epics/auth.epic';

const getPracticeUserStudyStatusRequestEpic = action$ =>
  action$.pipe(
    ofType(practice.GET_PRACTICE_USER_STUDY_STATUS_REQUEST),
    mergeMap(action =>
      userMediaScriptSpeakPracticeAPI
        .getPracticeUserStatus$(action.payload)
        .pipe(
          map(data => practice.getPracticeUserStudyStatusSuccess(data.result)),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

//////
const getPracticeRequestEpic = action$ =>
  action$.pipe(
    ofType(practice.GET_PRACTICE_REQUEST),
    mergeMap(action =>
      practiceAPI.getPractice$(action.payload).pipe(
        map(data => {
          return practice.getPracticeSuccess(data.result, action.payload);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getPracticeSuccessEpic = action$ =>
  action$.pipe(
    ofType(practice.GET_PRACTICE_SUCCESS),
    mergeMap(action => {
      console.log({ action });
      return of({
        type: practice.GET_PRACTICE_USER_STATUS_REQUEST,
        payload: action.upc,
      });
    }),
  );

const getPracticeUserStatusRequestEpic = action$ =>
  action$.pipe(
    ofType(practice.GET_PRACTICE_USER_STATUS_REQUEST),
    mergeMap(action =>
      userMediaScriptSpeakPracticeAPI
        .getPracticeUserStatus$(action.payload)
        .pipe(
          map(data => practice.getPracticeUserStatusSuccess(data.result)),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );
// const getPracticeUserStatusSuccessEpic = action$ =>
//   action$.pipe(
//     ofType(practice.GET_PRACTICE_USER_STATUS_SUCCESS),
//     mapTo({ type: practice.REVISIT_CHECK }),
//   );

const postPracticeRecordDataRequestEpic = action$ =>
  action$.pipe(
    ofType(practice.POST_PRACTICE_RECORD_DATA_REQUEST),
    mergeMap(action =>
      userMediaScriptSpeakPracticeAPI
        .postUserMediaScriptSpeakPractice$(
          action.mediaScriptSpeakId /** mediaScriptSpeakId */,
          action.base64String /** base64String */,
          action.upc,
        )
        .pipe(
          map(data => {
            // console.log({ data });
            if (data.code !== 200)
              return practice.postPracticeRecordDataFailure(data);
            if (data.result.accuracy < 75) {
              return practice.postPracticeRecordDataFailure(data);
            }
            return practice.postPracticeRecordDataSuccess(data.result);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

const postPracticeRecordPassRequestEpic = action$ =>
  action$.pipe(
    ofType(practice.POST_PRACTICE_RECORD_PASS_REQUEST),
    mergeMap(action =>
      userMediaScriptSpeakPracticeAPI
        .postUserMediaScriptSpeakPracticePass$(
          action.mediaScriptSpeakId,
          action.upc,
        )
        .pipe(
          map(data => {
            console.log('postPracticeRecordPassRequestEpic', { data });

            if (data.code !== 200) {
              return practice.postPracticeRecordPassFailure(data);
            }

            return practice.postPracticeRecordPassSuccess(data.result);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

export default combineEpics(
  getPracticeRequestEpic,
  getPracticeSuccessEpic,
  getPracticeUserStatusRequestEpic,
  postPracticeRecordDataRequestEpic,
  postPracticeRecordPassRequestEpic,
  getPracticeUserStudyStatusRequestEpic,
);
