import * as searchSong from 'shared/actions/searchSong.action';

export const initialState = {
  status: false,
  keyword: '',
  message:
    '검색어를 입력해주세요. 곡명 또는 아티스트명으로 검색할 수 있습니다.',
  list: [],
  loading: false,
};

let nextData = {};
export default (state = initialState, { type, payload, value }) => {
  switch (type) {
    case searchSong.SET_STATUS_ON:
      nextData = { ...state };
      nextData.status = true;
      return nextData;
    case searchSong.SET_STATUS_OFF:
      nextData = { ...state };
      nextData.status = false;
      return nextData;
    case searchSong.SET_KEYWORD:
      nextData = { ...state };
      nextData.keyword = payload;
      return nextData;
    case searchSong.GET_SEARCH_SONG_INIT:
      // console.log({ initialState });
      nextData = { ...initialState };
      return nextData;
    case searchSong.GET_SEARCH_SONG_EMPTY:
      nextData = { ...state };
      nextData.keyword = initialState.keyword;
      nextData.message = initialState.message;
      nextData.list = initialState.list;
      return nextData;
    case searchSong.GET_SEARCH_SONG_REQUEST:
      nextData = { ...state };
      if (nextData.keyword) nextData.loading = true;
      return nextData;
    case searchSong.GET_SEARCH_SONG_SUCCESS:
      nextData = { ...state };
      nextData.loading = false;
      nextData.list = payload.result.map(item => {
        const { thumbnail, ...rest } = item;
        return {
          ...rest,
          image: thumbnail,
        };
      });

      if (nextData.list.length) {
        nextData.message = `${nextData.list.length} 건의 <strong>"${nextData.keyword}"</strong> 검색 결과`;
      } else {
        nextData.message = `<strong>"${nextData.keyword}"</strong> 의 검색 결과가 없습니다.`;
      }

      return nextData;
    default:
      return state;
  }
};
