const productItem = item => {
  const unit = 10000;
  const point = item.title.match(`한정 수량`) ? true : false;
  const price = item.price / unit.toFixed(1);
  const paybackPrice = 5;
  let sale = -10; // 일시불시 즉시 할인
  let freeMonth = 12;
  let productDesc = `닥터뮤지와 함께 공부하는 과정이 더욱\n즐거워지는 이지펜 및 월간 뮤직 12권을 보내드립니다!`;
  if (point) {
    freeMonth = 18;
    sale = -15;
    productDesc = `지금 AI홈어학기+디지털 패키지 평생이용권을 구매하시면,\nAI홈어학기뿐만 아니라 오케이 닥터와 함께 공부하는 과정이 더욱\n즐거워지는 이지펜 및 월간 뮤직 12권을 보내드립니다!`;
  }

  const monthly = item.price / freeMonth / unit.toFixed(1);
  const payback = paybackPrice * freeMonth;

  let postProduct = [
    { title: '이지펜', type: 'pen' },
    { title: '월간뮤직', type: 'monthly' },
  ];
  if (point) {
    postProduct.unshift({ title: '어학기', type: 'doctor' });
  }

  return {
    id: item.id,
    point,
    price: item.price,
    freeMonth,
    title: item.title.replace(
      `한정 수량`,
      `<span class="badge">한정 수량</span>`,
    ),
    priceDetail: [
      // {
      //   primary: 'online',
      //   title: '온라인 판매가',
      //   price: price.toFixed(1),
      // },
      {
        primary: 'payment',
        title: '총 결제 금액',
        price: (price + sale).toFixed(1),
        className: 'black',
        key: 'final',
      },
      {
        primary: 'sale',
        // title: '즉시 할인',
        title: '환급 프로모션',
        price: sale.toFixed(1),
      },
    ],
    priceDetailSplit: [
      {
        primary: 'online_split',
        title: '온라인 판매가',
        price: price.toFixed(1),
      },
      {
        primary: 'sale_split',
        title: '18개월 무이자 할부',
      },
      {
        primary: 'payment_split',
        title: '결제 금액',
        price: monthly.toFixed(1),
        unit: '/ 월',
        className: 'black',
        key: 'final',
      },
    ],
    pricePlus: [
      {
        primary: 'payback',
        title: '페이백 프로모션',
        price: payback.toFixed(1),
        key: 'final',
      },
      {
        primary: 'payback_total',
        title: '최종혜택가',
        price: (price + sale - payback).toFixed(1),
        className: 'point',
      },
    ],
    pricePlusSplit: [
      {
        primary: 'payback_split',
        title: '페이백 프로모션',
        price: paybackPrice.toFixed(1),
        unit: '/ 월',
        key: 'final',
      },
      // 위 부분을 지우면... 에러가 난다.. 사용하는 곳에서 배열의 크기가
      // 2개로 고정되어 있어서 문제 생김...
      {
        primary: 'payback_total_split',
        title: '최종혜택가',
        price: (monthly - paybackPrice).toFixed(1),
        unit: '/ 월',
        className: 'point',
      },
    ],
    postProduct: postProduct,
    payment: [
      {
        type: 'card',
        title: '신용카드',
      },
      {
        type: 'card',
        title: '신용카드',
      },
      {
        type: 'card',
        title: '신용카드',
      },
    ],
    productDesc,
    guideMessage: [
      `- 패키지에 포함된 상품의 배송은 2~5 영업일 가량 소요됩니다.`,
      `- 구매와 관련된 더 자세한 정보는 [ MY>구매내역 ] 메뉴를 확인해주세요.`,
    ],
  };
};

export default productItem;
