import { grade } from 'shared/data/userData';

export const getGradeName = userPurchase => {
  let userGradeData = grade[userPurchase].title;
  return userGradeData;
};

export const getGradeValue = userPurchase => {
  let value = grade[userPurchase].grade;
  return value;
};

export const getGradeStyle = userPurchase => {
  console.log('getGradeStyle', { userPurchase });
  let value = grade[userPurchase].style || {};

  return value;
};
