import * as auth from 'shared/actions/auth.action';
import * as modal from 'shared/actions/modal.action';

export const initialState = {
  type: '',
  data: {},
  index: 0,
};

let nextState;
export default (state = initialState, { type, payload, action }) => {
  switch (type) {
    case auth.DELETE_USER_FAILURE:
      console.log('DELETE_USER_FAILURE', { payload, action });
      nextState = {
        type: 'DropOutCheck',
        data: payload,
        index: state && state.index ? state.index + 1 : payload.index + 1,
      };

      return nextState;

    case auth.POST_USER_DEVICE_SUCCESS:
      return initialState;
    case auth.DELETE_USER_DEVICE_SUCCESS:
      nextState = { ...state };
      if (
        nextState.type === 'DeviceList' &&
        nextState.data.list &&
        action &&
        action.id
      ) {
        const findItem = nextState.data.list.findIndex(
          item => item.id === action.id,
        );
        if (typeof findItem === 'number') {
          nextState.data.list.splice(findItem, 1);
        }
        console.log({ findItem });
      }

      return nextState;
    case auth.POST_PHONE_NUMBER_SUCCESS:
      return initialState;

    case modal.INIT_MODAL:
      return initialState;
    case modal.PUSH_MODAL:
      nextState = { ...state };
      nextState = {
        type: payload.type,
        data: payload.data,
        index: state && state.index ? state.index + 1 : payload.index + 1,
      };
      return nextState;

    case modal.POST_LIST_ITEM_NAME_SUCCESS:
      nextState = { ...state };
      console.log({
        action,
      });
      nextState.data.list[action.payload.i].name = action.payload.name;

      return nextState;

    default:
      return state;
  }
};
