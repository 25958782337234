export const SET_STATE = '@mediaInfo/SET_STATE';
export const INITIAL_STATE = '@mediaInfo/INITIAL_STATE';
export const GET_SEARCH_TYPE_REQUEST = '@mediaInfo/GET_SEARCH_TYPE_REQUEST';
export const GET_SEARCH_TYPE_SUCCESS = '@mediaInfo/GET_SEARCH_TYPE_SUCCESS';

//미디어쪽 이해 불가
export const setState = payload => ({
  type: SET_STATE,
  payload,
});

export const initialState = () => ({
  type: INITIAL_STATE,
});

export const getSearchTypeRequest = payload => ({
  type: GET_SEARCH_TYPE_REQUEST,
  payload,
});

export const getSearchTypeSuccess = payload => ({
  type: GET_SEARCH_TYPE_SUCCESS,
  payload,
});

export const GET_MEDIA_KEY_REQUEST = '@mediaInfo/GET_MEDIA_KEY_REQUEST';
export const GET_MEDIA_KEY_SUCCESS = '@mediaInfo/GET_MEDIA_KEY_SUCCESS';
export const GET_MEDIA_KEY_FAILURE = '@mediaInfo/GET_MEDIA_KEY_FAILURE';
export const getMediaKeyRequest = (payload, callback) => ({
  type: GET_MEDIA_KEY_REQUEST,
  payload,
  callback,
});

export const getMediaKeySuccess = (payload, callback) => ({
  type: GET_MEDIA_KEY_SUCCESS,
  payload,
  callback,
});
export const getMediaKeyFailure = (payload, callback) => ({
  type: GET_MEDIA_KEY_FAILURE,
  payload,
  callback,
});

export const GET_ANNON_MEDIA_KEY_REQUEST =
  '@mediaInfo/GET_ANNON_MEDIA_KEY_REQUEST';
export const GET_ANNON_MEDIA_KEY_SUCCESS =
  '@mediaInfo/GET_ANNON_MEDIA_KEY_SUCCESS';
export const GET_ANNON_MEDIA_KEY_FAILURE =
  '@mediaInfo/GET_ANNON_MEDIA_KEY_FAILURE';
export const getAnnonMediaKeyRequest = (payload, callback) => ({
  type: GET_ANNON_MEDIA_KEY_REQUEST,
  payload,
  callback,
});

export const getAnnonMediaKeySuccess = (payload, callback) => ({
  type: GET_ANNON_MEDIA_KEY_SUCCESS,
  payload,
  callback,
});
export const getAnnonMediaKeyFailure = (payload, callback) => ({
  type: GET_ANNON_MEDIA_KEY_FAILURE,
  payload,
  callback,
});

export const GET_STUDY_START_REQUEST = '@mediaInfo/GET_STUDY_START_REQUEST';
export const GET_STUDY_START_SUCCESS = '@mediaInfo/GET_STUDY_START_SUCCESS';
export const GET_STUDY_START_FAILURE = '@mediaInfo/GET_STUDY_START_FAILURE';
export const getStudyStartRequest = (payload, callback) => ({
  type: GET_STUDY_START_REQUEST,
  payload,
  callback,
});

export const getStudyStartSuccess = (payload, callback) => ({
  type: GET_STUDY_START_SUCCESS,
  payload,
  callback,
});
export const getStudyStartFailure = (payload, callback) => ({
  type: GET_STUDY_START_FAILURE,
  payload,
  callback,
});
