export function createActionCreator(actionName) {
  const actionCreator = payload => ({
    type: actionName,
    payload,
  });
  actionCreator.type = actionName;
  return actionCreator;
}
/**
 * EXAMPLE
 * const actionCreator = createRequestAction('@some', 'ACTION')
 * actionCreator.type == '@some/ACTION_REQUEST'
 * actionCreator() == { type: '@some/ACTION_REQUEST', payload: undefined }         // this is sugar for mapDispatchToProps
 * actionCreator.request() == { type: '@some/ACTION_REQUEST', payload: undefined }
 * actionCreator.success() == { type: '@some/ACTION_SUCCESS', payload: undefined }
 */
export function createRequestAction(prefix, actionName) {
  const requestActionCreator = createActionCreator(
    `${prefix}/${actionName}_REQUEST`,
  );
  requestActionCreator.request = createActionCreator(
    `${prefix}/${actionName}_REQUEST`,
  );
  requestActionCreator.success = createActionCreator(
    `${prefix}/${actionName}_SUCCESS`,
  );
  requestActionCreator.failure = createActionCreator(
    `${prefix}/${actionName}_FAILURE`,
  );
  return requestActionCreator;
}
