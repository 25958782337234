import { POST } from 'shared/utils/ajax';

/**
 * @param {string} upc
 * {
 *   {
 *   "userMediaScriptSpeakId": {
 *     "mediaScriptSpeakId": number,
 *   }
 * }
 */

export const saveSpeakStep$ = ({ upc, data }) =>
  POST(`/rest/media/userMediaScriptSpeak/${upc}`, data);
