import { GET } from 'shared/utils/ajax';

const media = `/rest/media`;
const path = `${media}/practice`;

/**
 * 미디어 듣기 가져오기
 * @param {string} upc
 */
export const getPractice$ = upc => GET(`${path}/${upc}`);
