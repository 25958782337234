/**
 * 강의페이지의 강의와 팝송, 따라부르기, 멜로딕 트레이닝 요청하는 api
 * /study/강의UPC넘버/detail
 */
export const GET_DETAIL_SONG_REQUEST = '@detail/GET_DETAIL_SONG_REQUEST';
export const GET_DETAIL_SONG_SUCCESS = '@detail/GET_DETAIL_SONG_SUCCESS';
export const GET_DETAIL_SONG_FAILURE = '@detail/GET_DETAIL_SONG_FAILURE';

export const getDetailSongRequest = payload => ({
  type: GET_DETAIL_SONG_REQUEST,
  payload,
});

export const getDetailSongSuccess = payload => ({
  type: GET_DETAIL_SONG_SUCCESS,
  payload,
});

export const getDetailSongFailure = payload => ({
  type: GET_DETAIL_SONG_FAILURE,
  payload,
});

/**
 * 특정강의 클릭시 나타나는 상담 네비 "내 리스트에 담기" 클릭 요청 api
 * /study/강의UPC넘버/detail
 */
export const SAVE_MY_PLAYLIST_REQUEST = '@detail/SAVE_MY_PLAYLIST_REQUEST';
export const SAVE_MY_PLAYLIST_SUCCESS = '@detail/SAVE_MY_PLAYLIST_SUCCESS';
export const SAVE_MY_PLAYLIST_FAILURE = '@detail/SAVE_MY_PLAYLIST_FAILURE';

export const saveMyPlaylistRequest = payload => ({
  type: SAVE_MY_PLAYLIST_REQUEST,
  payload,
});

export const saveMyPlaylistSuccess = payload => ({
  type: SAVE_MY_PLAYLIST_SUCCESS,
  payload,
});

export const saveMyPlaylistFailure = payload => ({
  type: SAVE_MY_PLAYLIST_FAILURE,
  payload,
});

export const CANCEL_MY_PLAYLIST_REQUEST = '@detail/CANCEL_MY_PLAYLIST_REQUEST';
export const CANCEL_MY_PLAYLIST_SUCCESS = '@detail/CANCEL_MY_PLAYLIST_SUCCESS';
export const CANCEL_MY_PLAYLIST_FAILURE = '@detail/CANCEL_MY_PLAYLIST_FAILURE';

export const cancelMyPlaylistRequest = payload => ({
  type: CANCEL_MY_PLAYLIST_REQUEST,
  payload,
});

export const cancelMyPlaylistSuccess = payload => ({
  type: CANCEL_MY_PLAYLIST_SUCCESS,
  payload,
});

export const cancelMyPlaylistFailure = payload => ({
  type: CANCEL_MY_PLAYLIST_FAILURE,
  payload,
});

export const INITIALIZE_DETAIL_INFO_REQUEST =
  '@detail/INITIAL_DETAIL_INFO_REQUEST';
export const INITIALIZE_DETAIL_INFO_SUCCESS =
  '@detail/INITIAL_DETAIL_INFO_SUCCESS';
export const INITIALIZE_DETAIL_INFO_FAILURE =
  '@detail/INITIAL_DETAIL_INFO_FAILURE';

export const initializeDetailInfoRequest = payload => ({
  type: INITIALIZE_DETAIL_INFO_REQUEST,
  payload,
});

export const initializeDetailInfoSuccess = payload => ({
  type: INITIALIZE_DETAIL_INFO_SUCCESS,
  payload,
});

export const initializeDetailInfoFailure = payload => ({
  type: INITIALIZE_DETAIL_INFO_FAILURE,
  payload,
});
