import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';

import * as searchSong from 'shared/actions/searchSong.action';
// import * as counter from 'shared/actions/counter.action';
import * as searchAPI from 'shared/apis/media/search.api';
import { of } from 'rxjs';

const getSearchSongRequestEpic = action$ =>
  action$.pipe(
    ofType(searchSong.GET_SEARCH_SONG_REQUEST),
    mergeMap(action => {
      return action.payload
        ? searchAPI.searchText$(action.payload).pipe(
            map(response => {
              console.log(`searchSongRequestEpic `, response);
              if (response.code !== 200) {
                return;
              }
              return searchSong.getSearchSongSuccess(response);
            }),
            catchError(error => errorHandler(error, action)),
          )
        : of({
            type: searchSong.GET_SEARCH_SONG_EMPTY,
          });
    }),
  );

export default combineEpics(getSearchSongRequestEpic);
