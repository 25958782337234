/**
 * 유저 정보 변경
 */
export const SET_USER = '@auth/SET_USER';

export const setUser = payload => ({
  type: SET_USER,
  payload,
});

/**
 * 브라우저 고유 값을 가져와서 쿠키로 설정하고
 * 비회원 로그인을 진행한다.
 */
export const GET_ANNON_INHERENCE_KEY_REQUEST =
  '@auth/GET_ANNON_INHERENCE_KEY_REQUEST';
export const GET_ANNON_INHERENCE_KEY_SUCCESS =
  '@auth/GET_ANNON_INHERENCE_KEY_SUCCESS';
export const GET_ANNON_INHERENCE_KEY_REJECTED =
  '@auth/POST_AUTH_TOKEN_REGET_ANNON_INHERENCE_KEY_';

export const getAnnonInherenceKeyRequest = (payload, callback) => ({
  type: GET_ANNON_INHERENCE_KEY_REQUEST,
  payload,
  callback,
});
export const getAnnonInherenceKeySuccess = payload => ({
  type: GET_ANNON_INHERENCE_KEY_SUCCESS,
  payload,
});
export const getAnnonInherenceKeyRejected = payload => ({
  type: GET_ANNON_INHERENCE_KEY_REJECTED,
  payload,
});

/**
 * 인증토큰 - 발급
 * 비회원일 경우 ?
 */

export const guestTokenData = {
  username: '',
  password: 'guest',
};

export const POST_AUTH_TOKEN_REQUEST = '@auth/POST_AUTH_TOKEN_REQUEST';
export const POST_AUTH_TOKEN_SUCCESS = '@auth/POST_AUTH_TOKEN_SUCCESS';
export const POST_AUTH_TOKEN_FAILURE = '@auth/POST_AUTH_TOKEN_FAILURE';

export const postAuthTokenRequest = (payload, callback) => ({
  type: POST_AUTH_TOKEN_REQUEST,
  payload,
  callback,
});
export const postAuthTokenSuccess = payload => ({
  type: POST_AUTH_TOKEN_SUCCESS,
  payload,
});
export const postAuthTokenFailure = payload => ({
  type: POST_AUTH_TOKEN_FAILURE,
  payload,
});

// 인증토큰 - 재발급
//인증토큰을 재발급 하는 이유? 갱신때문에?
export const POST_REFRESH_TOKEN_REQUEST = '@auth/POST_REFRESH_TOKEN_REQUEST';
export const POST_REFRESH_TOKEN_SUCCESS = '@auth/POST_REFRESH_TOKEN_SUCCESS';

export const postRefreshTokenRequest = payload => ({
  type: POST_REFRESH_TOKEN_REQUEST,
  payload,
});
export const postRefreshTokenSuccess = payload => ({
  type: POST_REFRESH_TOKEN_SUCCESS,
  payload,
});

/**
  사용자 설정의
  input의 target.value로 값이 변경하는 액션
 */
export const START_USER_SETTING = '@auth/START_USER_SETTING';
export const startUserSetting = payload => ({
  type: START_USER_SETTING,
  payload,
});
export const FINISH_USER_SETTING = '@auth/FINISH_USER_SETTING';
export const finishUserSetting = payload => ({
  type: FINISH_USER_SETTING,
  payload,
});

/*
  사용자 설정 완료
*/
export const SET_USER_SETTING = '@auth/SET_USER_SETTING';
export const setUserSetting = payload => ({
  type: SET_USER_SETTING,
  payload,
});

/**
 * 사용자 쿠폰 정보확인
 */
export const GET_COUPON_CHECK_REQUEST = '@auth/GET_COUPON_CHECK_REQUEST';
export const GET_COUPON_CHECK_SUCCESS = '@auth/GET_COUPON_CHECK_SUCCESS';

export const getCouponCheckRequest = payload => ({
  type: GET_COUPON_CHECK_REQUEST,
  payload,
});
export const getCouponCheckSuccess = payload => ({
  type: GET_COUPON_CHECK_SUCCESS,
  payload,
});

/**
 * 사용자 정보 확인 api
 * 로그인을 하고 로그인 정보가 맞는지 검증하는 api
 */
export const GET_AUTH_CHECK = '@auth/GET_AUTH_CHECK';
export const GET_AUTH_CHECK_REQUEST = '@auth/GET_AUTH_CHECK_REQUEST';
export const GET_AUTH_CHECK_SUCCESS = '@auth/GET_AUTH_CHECK_SUCCESS';
export const GET_AUTH_CHECK_FAILURE = '@auth/GET_AUTH_CHECK_FAILURE';
export const GET_AUTH_CHECK_AFTER_CALLBACK =
  '@auth/GET_AUTH_CHECK_AFTER_CALLBACK';

export const GET_AUTH_CHECK_CANCELLED = '@auth/GET_AUTH_CHECK_CANCELLED';
export const getAuthCheckCancelled = payload => ({
  type: GET_AUTH_CHECK_CANCELLED,
  payload,
});

export const getAuthCheck = payload => ({
  type: GET_AUTH_CHECK,
  payload,
});

export const getAuthCheckRequest = (callback, inherence_key, token) => ({
  type: GET_AUTH_CHECK_REQUEST,
  callback,
  inherence_key,
  token,
});
export const getAuthCheckSuccess = (
  payload,
  callback,
  inherence_key,
  token,
) => ({
  type: GET_AUTH_CHECK_SUCCESS,
  payload,
  callback,
  inherence_key,
  token,
});
export const getAuthCheckFailure = (payload, token) => ({
  type: GET_AUTH_CHECK_FAILURE,
  payload,
  token,
});
export const getAuthCheckAfterCallback = (payload, token) => ({
  type: GET_AUTH_CHECK_AFTER_CALLBACK,
  ...payload,
});

/**
 * 사용자 학습 정보
 * 사용자 최근 학습 리스트
 */
export const GET_LATEST_STUDY_LIST_REQUEST =
  '@auth/GET_LATEST_STUDY_LIST_REQUEST';
export const GET_LATEST_STUDY_LIST_SUCCESS =
  '@auth/GET_LATEST_STUDY_LIST_SUCCESS';

export const getLatestStudyListRequest = payload => ({
  type: GET_LATEST_STUDY_LIST_REQUEST,
  payload,
});
export const getLatestStudyListSuccess = payload => ({
  type: GET_LATEST_STUDY_LIST_SUCCESS,
  payload,
});

// 담은 가사정보
export const GET_USER_SCRIPT_REQUEST = '@auth/GET_USER_SCRIPT_REQUEST';
export const GET_USER_SCRIPT_SUCCESS = '@auth/GET_USER_SCRIPT_SUCCESS';
export const DELETE_USER_SCRIPT_REQUEST = '@auth/DELETE_USER_SCRIPT_REQUEST';
export const DELETE_USER_SCRIPT_FAILURE = '@auth/DELETE_USER_SCRIPT_FAILURE';
export const DELETE_USER_SCRIPT_SUCCESS = '@auth/DELETE_USER_SCRIPT_SUCCESS';

export const getUserScriptRequest = payload => ({
  type: GET_USER_SCRIPT_REQUEST,
  payload,
});
export const getUserScriptSuccess = payload => ({
  type: GET_USER_SCRIPT_SUCCESS,
  payload,
});
export const deleteUserScriptRequest = payload => ({
  type: DELETE_USER_SCRIPT_REQUEST,
  payload,
});
export const deleteUserScriptFailure = payload => ({
  type: DELETE_USER_SCRIPT_FAILURE,
  payload,
});
export const deleteUserScriptSuccess = payload => ({
  type: DELETE_USER_SCRIPT_SUCCESS,
  payload,
});

// 담은 가사에 메모 남기기
export const POST_USER_SCRIPT_MEMO_REQUEST =
  '@auth/POST_USER_SCRIPT_MEMO_REQUEST';
export const POST_USER_SCRIPT_MEMO_FAILURE =
  '@auth/POST_USER_SCRIPT_MEMO_FAILURE';
export const POST_USER_SCRIPT_MEMO_SUCCESS =
  '@auth/POST_USER_SCRIPT_MEMO_SUCCESS';
export const postUserScriptMemoRequest = ({ note, mediaScriptId }) => ({
  type: POST_USER_SCRIPT_MEMO_REQUEST,
  note,
  mediaScriptId,
});
export const postUserScriptMemoFailure = payload => ({
  type: POST_USER_SCRIPT_MEMO_FAILURE,
  payload,
});
export const postUserScriptMemoSuccess = payload => ({
  type: POST_USER_SCRIPT_MEMO_SUCCESS,
  payload,
});

export const SET_USER_SCRIPT_MEMO_ONCHANGE =
  '@auth/SET_USER_SCRIPT_MEMO_ONCHANGE';
export const setUserScriptMemoOnChange = ({ note, mediaScriptId }) => ({
  type: SET_USER_SCRIPT_MEMO_ONCHANGE,
  payload: {
    note,
    mediaScriptId,
  },
});

// 담은 단어정보
export const GET_USER_DICTIONARY_REQUEST = '@auth/GET_USER_DICTIONARY_REQUEST';
export const GET_USER_DICTIONARY_SUCCESS = '@auth/GET_USER_DICTIONARY_SUCCESS';

export const getUserDictionaryRequest = payload => ({
  type: GET_USER_DICTIONARY_REQUEST,
  payload,
});
export const getUserDictionarySuccess = payload => ({
  type: GET_USER_DICTIONARY_SUCCESS,
  payload,
});

export const DELETE_USER_DICTIONARY_REQUEST =
  '@auth/DELETE_USER_DICTIONARY_REQUEST';
export const DELETE_USER_DICTIONARY_FAILURE =
  '@auth/DELETE_USER_DICTIONARY_FAILURE';
export const DELETE_USER_DICTIONARY_SUCCESS =
  '@auth/DELETE_USER_DICTIONARY_SUCCESS';

export const deleteUserDictionaryRequest = (mediaUpc, userDictionaryId) => ({
  type: DELETE_USER_DICTIONARY_REQUEST,
  payload: {
    mediaUpc,
    userDictionaryId,
  },
});
export const deleteUserDictionaryFailure = payload => ({
  type: DELETE_USER_DICTIONARY_FAILURE,
  payload,
});
export const deleteUserDictionarySuccess = payload => ({
  type: DELETE_USER_DICTIONARY_SUCCESS,
  payload,
});

/**
 * 로그인
 */
export const START_LOGIN_DATA = '@auth/START_LOGIN_DATA';
export const FINISH_LOGIN_DATA = '@auth/FINISH_LOGIN_DATA';
export const SET_LOGIN_DATA = '@auth/SET_LOGIN_DATA';
export const SET_LOGIN_ERROR = '@auth/SET_LOGIN_ERROR';

export const startLoginData = payload => ({
  type: START_LOGIN_DATA,
  payload,
});
export const finishLoginData = payload => ({
  type: FINISH_LOGIN_DATA,
  payload,
});
export const setLoginData = payload => ({
  type: SET_LOGIN_DATA,
  payload,
});
export const setLoginError = payload => ({
  type: SET_LOGIN_ERROR,
  payload,
});

/**
 * 로그아웃
 */
export const LOGOUT = '@auth/LOGOUT';
export const logout = payload => ({
  type: LOGOUT,
  payload,
});
export const LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS';
export const logoutSuccess = payload => ({
  type: LOGOUT_SUCCESS,
  payload,
});

/**
 * 회원가입
 */
export const POST_REGISTER_REQUEST = '@auth/POST_REGISTER_REQUEST';
export const POST_REGISTER_SUCCESS = '@auth/POST_REGISTER_SUCCESS';

export const postRegisterRequest = payload => ({
  type: POST_REGISTER_REQUEST,
  payload,
});
export const postRegisterSuccess = payload => ({
  type: POST_REGISTER_SUCCESS,
  payload,
});

// 회원가입 - 데이터
export const START_JOIN_DATA = '@auth/START_JOIN_DATA';
export const FINISH_JOIN_DATA = '@auth/FINISH_JOIN_DATA';
export const SET_JOIN_DATA = '@auth/SET_JOIN_DATA';
export const SET_JOIN_VERIFICATION_DATA = '@auth/SET_JOIN_VERIFICATION_DATA';

export const startJoinData = payload => ({
  type: START_JOIN_DATA,
  payload,
});
export const finishJoinData = payload => ({
  type: FINISH_JOIN_DATA,
  payload,
});
export const setJoinData = payload => ({
  type: SET_JOIN_DATA,
  payload,
});
export const setJoinVerificationData = (payload, error) => ({
  type: SET_JOIN_VERIFICATION_DATA,
  payload,
  error,
});

// 회원가입 - 이메일 중복체크
export const GET_CHECK_EMAIL_REQUEST = '@auth/GET_CHECK_EMAIL_REQUEST';
export const GET_CHECK_EMAIL_SUCCESS = '@auth/GET_CHECK_EMAIL_SUCCESS';
export const GET_CHECK_EMAIL_FAILURE = '@auth/GET_CHECK_EMAIL_FAILURE';
export const getCheckEmailRequest = payload => ({
  type: GET_CHECK_EMAIL_REQUEST,
  payload,
});
export const getCheckEmailSuccess = payload => ({
  type: GET_CHECK_EMAIL_SUCCESS,
  payload,
});
export const getCheckEmailFailure = payload => ({
  type: GET_CHECK_EMAIL_FAILURE,
  payload,
});

// 회원가입 - 닉네임 중복체크
export const GET_CHECK_NICKNAME_REQUEST = '@auth/GET_CHECK_NICKNAME_REQUEST';
export const GET_CHECK_NICKNAME_SUCCESS = '@auth/GET_CHECK_NICKNAME_SUCCESS';
export const GET_CHECK_NICKNAME_FAILURE = '@auth/GET_CHECK_NICKNAME_FAILURE';
export const getCheckNicknameRequest = payload => ({
  type: GET_CHECK_NICKNAME_REQUEST,
  payload,
});

export const getCheckNicknameSuccess = payload => ({
  type: GET_CHECK_NICKNAME_SUCCESS,
  payload,
});

export const getCheckNicknameFailure = payload => ({
  type: GET_CHECK_NICKNAME_FAILURE,
  payload,
});

// 회원가입 - 핸드폰 인증코드 - 발급
export const GET_SEND_PHONE_AUTH_REQUEST = '@auth/GET_SEND_PHONE_AUTH_REQUEST';
export const GET_SEND_PHONE_AUTH_SUCCESS = '@auth/GET_SEND_PHONE_AUTH_SUCCESS';
export const GET_SEND_PHONE_AUTH_FAILURE = '@auth/GET_SEND_PHONE_AUTH_FAILURE';

export const getSendPhoneAuthRequest = payload => ({
  type: GET_SEND_PHONE_AUTH_REQUEST,
  payload,
});
export const getSendPhoneAuthSuccess = payload => ({
  type: GET_SEND_PHONE_AUTH_SUCCESS,
  payload,
});
export const getSendPhoneAuthFailure = payload => ({
  type: GET_SEND_PHONE_AUTH_FAILURE,
  payload,
});

// 회원가입 - 핸드폰 인증코드 - 인증
export const GET_VERIFY_CODE_REQUEST = '@auth/GET_VERIFY_CODE_REQUEST';
export const GET_VERIFY_CODE_SUCCESS = '@auth/GET_VERIFY_CODE_SUCCESS';
export const GET_VERIFY_CODE_FAILURE = '@auth/GET_VERIFY_CODE_FAILURE';

export const getVerifyCodeRequest = (payload, callback) => ({
  type: GET_VERIFY_CODE_REQUEST,
  payload,
  callback,
});

export const getVerifyCodeSuccess = payload => ({
  type: GET_VERIFY_CODE_SUCCESS,
  payload,
});
export const getVerifyCodeFailure = payload => ({
  type: GET_VERIFY_CODE_FAILURE,
  payload,
});

export const GET_VERIFY_CODE_FIND_EMAIL_REQUEST =
  '@auth/GET_VERIFY_CODE_FIND_EMAIL_REQUEST';
export const GET_VERIFY_CODE_FIND_EMAIL_SUCCESS =
  '@auth/GET_VERIFY_CODE_FIND_EMAIL_SUCCESS';
export const GET_VERIFY_CODE_FIND_EMAIL_FAILURE =
  '@auth/GET_VERIFY_CODE_FIND_EMAIL_FAILURE';

export const getVerifyCodeInFindEmailRequest = (payload, callback) => ({
  type: GET_VERIFY_CODE_FIND_EMAIL_REQUEST,
  payload,
  callback,
});
export const getVerifyCodeInFindEmailSuccess = payload => ({
  type: GET_VERIFY_CODE_FIND_EMAIL_SUCCESS,
  payload,
});
export const getVerifyCodeInFindEmailFailure = payload => ({
  type: GET_VERIFY_CODE_FIND_EMAIL_FAILURE,
  payload,
});

/**
 * 아아디,비밀번호 찾기
 */
export const GET_FIND_EMAIL_REQUEST = '@auth/GET_FIND_EMAIL_REQUEST';
export const GET_FIND_EMAIL_SUCCESS = '@auth/GET_FIND_EMAIL_SUCCESS';
export const GET_FIND_EMAIL_FAILURE = '@auth/GET_FIND_EMAIL_FAILURE';
export const GET_FIND_PASSWORD_REQUEST = '@auth/GET_FIND_PASSWORD_REQUEST';
export const GET_FIND_PASSWORD_SUCCESS = '@auth/GET_FIND_PASSWORD_SUCCESS';
export const GET_FIND_PASSWORD_FAILURE = '@auth/GET_FIND_PASSWORD_FAILURE';

export const getFindEmailRequest = payload => ({
  type: GET_FIND_EMAIL_REQUEST,
  payload,
});
export const getFindEmailSuccess = payload => ({
  type: GET_FIND_EMAIL_SUCCESS,
  payload,
});
export const getFindEmailFailure = payload => ({
  type: GET_FIND_EMAIL_FAILURE,
  payload,
});
export const getFindPasswordRequest = payload => ({
  type: GET_FIND_PASSWORD_REQUEST,
  payload,
});
export const getFindPasswordSuccess = payload => ({
  type: GET_FIND_PASSWORD_SUCCESS,
  payload,
});
export const getFindPasswordFailure = payload => ({
  type: GET_FIND_PASSWORD_FAILURE,
  payload,
});

// 아아디,비밀번호 찾기 - 데이터
export const START_FIND_DATA = '@auth/START_FIND_DATA';
export const FINISH_FIND_DATA = '@auth/FINISH_FIND_DATA';
export const SET_FIND_DATA = '@auth/SET_FIND_DATA';
export const startFindData = payload => ({
  type: START_FIND_DATA,
  payload,
});
export const finishFindData = payload => ({
  type: FINISH_FIND_DATA,
  payload,
});
export const setFindData = payload => ({
  type: SET_FIND_DATA,
  payload,
});

// 아아디,비밀번호 찾기 - 데이터
export const START_VERTIFY_PHONE_DATA = '@auth/START_VERTIFY_PHONE_DATA';
export const FINISH_VERTIFY_PHONE_DATA = '@auth/FINISH_VERTIFY_PHONE_DATA';
export const SET_VERTIFY_PHONE_DATA = '@auth/SET_VERTIFY_PHONE_DATA';
export const START_VERTIFY_PHONE_ERROR = '@auth/START_VERTIFY_PHONE_ERROR';

export const startVertifyPhoneData = payload => ({
  type: START_VERTIFY_PHONE_DATA,
  payload,
});
export const finishVertifyPhoneData = payload => ({
  type: FINISH_VERTIFY_PHONE_DATA,
  payload,
});
export const setVertifyPhoneData = payload => ({
  type: SET_VERTIFY_PHONE_DATA,
  payload,
});
export const setVertifyPhoneError = (payload, error) => ({
  type: START_VERTIFY_PHONE_ERROR,
  payload,
  error,
});

// 아아디,비밀번호 찾기 - 데이터
export const POST_TELEMARKETING_REQUEST = '@auth/POST_TELEMARKETING_REQUEST';
export const POST_TELEMARKETING_SUCCESS = '@auth/POST_TELEMARKETING_SUCCESS';
export const POST_TELEMARKETING_FAILURE = '@auth/POST_TELEMARKETING_FAILURE';
export const postTelemarketingRequest = (payload, callback) => ({
  type: POST_TELEMARKETING_REQUEST,
  payload,
  callback,
});
export const postTelemarketingSuccess = (payload, callback) => ({
  type: POST_TELEMARKETING_SUCCESS,
  payload,
  callback,
});
export const postTelemarketingFailure = (payload, callback) => ({
  type: POST_TELEMARKETING_FAILURE,
  payload,
  callback,
});

// 내 번호로 등록하기
export const POST_PHONE_NUMBER_REQUEST = '@auth/POST_PHONE_NUMBER_REQUEST';
export const POST_PHONE_NUMBER_SUCCESS = '@auth/POST_PHONE_NUMBER_SUCCESS';
export const POST_PHONE_NUMBER_FAILURE = '@auth/POST_PHONE_NUMBER_FAILURE';
export const postPhoneNumberRequest = (payload, token, callback) => ({
  type: POST_PHONE_NUMBER_REQUEST,
  payload,
  token,
  callback,
});
export const postPhoneNumberSuccess = (payload, token, callback) => ({
  type: POST_PHONE_NUMBER_SUCCESS,
  payload,
  token,
  callback,
});
export const postPhoneNumberFailure = (payload, callback) => ({
  type: POST_PHONE_NUMBER_FAILURE,
  payload,
  callback,
});

export const GET_USER_DEVICE = '@auth/GET_USER_DEVICE';
export const getUserDevice = (token, isController = false) => ({
  type: GET_USER_DEVICE,
  token,
  isController,
});

export const POST_USER_DEVICE_REQUEST = '@auth/POST_USER_DEVICE_REQUEST';
export const POST_USER_DEVICE_SUCCESS = '@auth/POST_USER_DEVICE_SUCCESS';
export const POST_USER_DEVICE_FAILURE = '@auth/POST_USER_DEVICE_FAILURE';
export const postUserDeviceRequest = (token, id) => ({
  type: POST_USER_DEVICE_REQUEST,
  token,
  id,
});
export const postUserDeviceSuccess = (payload, action) => ({
  type: POST_USER_DEVICE_SUCCESS,
  payload,
  action,
});
export const postUserDeviceFailure = (payload, action) => ({
  type: POST_USER_DEVICE_FAILURE,
  payload,
  action,
});

export const DELETE_USER_DEVICE_REQUEST = '@auth/DELETE_USER_DEVICE_REQUEST';
export const DELETE_USER_DEVICE_SUCCESS = '@auth/DELETE_USER_DEVICE_SUCCESS';
export const DELETE_USER_DEVICE_FAILURE = '@auth/DELETE_USER_DEVICE_FAILURE';
export const deleteUserDeviceRequest = (token, id) => ({
  type: DELETE_USER_DEVICE_REQUEST,
  token,
  id,
});
export const deleteUserDeviceSuccess = (payload, action) => ({
  type: DELETE_USER_DEVICE_SUCCESS,
  payload,
  action,
});
export const deleteUserDeviceFailure = (payload, action) => ({
  type: DELETE_USER_DEVICE_FAILURE,
  payload,
  action,
});

export const POST_USER_PROFILE_REQUEST = '@auth/POST_USER_PROFILE_REQUEST';
export const POST_USER_PROFILE_SUCCESS = '@auth/POST_USER_PROFILE_SUCCESS';
export const POST_USER_PROFILE_FAILURE = '@auth/POST_USER_PROFILE_FAILURE';
export const postUserProfileRequest = (payload, file) => ({
  type: POST_USER_PROFILE_REQUEST,
  payload, // nickname
  file,
});
export const postUserProfileSuccess = (payload, action) => ({
  type: POST_USER_PROFILE_SUCCESS,
  payload,
  action,
});
export const postUserProfileFailure = (payload, action) => ({
  type: POST_USER_PROFILE_FAILURE,
  payload,
  action,
});

export const POST_USER_PASSWORD_REQUEST = '@auth/POST_USER_PASSWORD_REQUEST';
export const POST_USER_PASSWORD_SUCCESS = '@auth/POST_USER_PASSWORD_SUCCESS';
export const POST_USER_PASSWORD_FAILURE = '@auth/POST_USER_PASSWORD_FAILURE';
export const postUserPasswordRequest = (
  oldPassword,
  newPassword,
  callback,
) => ({
  type: POST_USER_PASSWORD_REQUEST,
  oldPassword,
  newPassword,
  callback,
});
export const postUserPasswordSuccess = (payload, action) => ({
  type: POST_USER_PASSWORD_SUCCESS,
  payload,
  action,
});
export const postUserPasswordFailure = (payload, action) => ({
  type: POST_USER_PASSWORD_FAILURE,
  payload,
  action,
});

export const POST_MARKETING_REQUEST = '@auth/POST_MARKETING_REQUEST';
export const POST_MARKETING_SUCCESS = '@auth/POST_MARKETING_SUCCESS';
export const POST_MARKETING_FAILURE = '@auth/POST_MARKETING_FAILURE';
export const postMarketingRequest = agree => ({
  type: POST_MARKETING_REQUEST,
  agree,
});
export const postMarketingSuccess = (payload, action) => ({
  type: POST_MARKETING_SUCCESS,
  payload,
  action,
});
export const postMarketingFailure = (payload, action) => ({
  type: POST_MARKETING_FAILURE,
  payload,
  action,
});

export const DELETE_USER_REQUEST = '@auth/DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = '@auth/DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = '@auth/DELETE_USER_FAILURE';
export const deleteUserRequest = () => ({
  type: DELETE_USER_REQUEST,
});
export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});
export const deleteUserFailure = (payload, action) => ({
  type: DELETE_USER_FAILURE,
  payload,
  action,
});

export const POST_USER_LECTURE_SWITCH_REQUEST =
  '@auth/POST_USER_LECTURE_SWITCH_REQUEST';
export const POST_USER_LECTURE_SWITCH_SUCCESS =
  '@auth/POST_USER_LECTURE_SWITCH_SUCCESS';
export const POST_USER_LECTURE_SWITCH_FAILURE =
  '@auth/POST_USER_LECTURE_SWITCH_FAILURE';
export const postUserLectureSwitchRequest = (payload, authRequest) => ({
  type: POST_USER_LECTURE_SWITCH_REQUEST,
  payload,
  authRequest,
});
export const postUserLectureSwitchSuccess = (payload, action) => ({
  type: POST_USER_LECTURE_SWITCH_SUCCESS,
  payload,
  action,
});
export const postUserLectureSwitchFailure = (payload, action) => ({
  type: POST_USER_LECTURE_SWITCH_FAILURE,
  payload,
  action,
});

export const SET_CHALLENGE_STATUS = '@auth/SET_CHALLENGE_STATUS';
export const setChallengeStatus = payload => ({
  type: SET_CHALLENGE_STATUS,
  payload,
});
