import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';

import * as webPlayer from 'shared/actions/webPlayer.action';

import * as commonAPI from 'shared/apis/media/common.api';
import * as scriptAPI from 'shared/apis/media/script.api';
import * as userPlaylistAPI from 'shared/apis/media/userPlaylist.api';
import * as userMediaAPI from 'shared/apis/media/userMedia.api';
import { of } from 'rxjs';

const getMediaPlaylistRequestEpic = action$ =>
  action$.pipe(
    ofType(webPlayer.GET_MEDIA_PLAYLIST_REQUEST),
    mergeMap(action =>
      !action.payload.getType &&
      !Object.keys(action.payload.search).length &&
      action.payload.upc &&
      action.payload.upc.length === 12
        ? of({
            type: webPlayer.POST_USER_MEDIA_PLAY_REQUEST,
            payload: {
              upc: action.payload.upc,
              getPlaylist: action.payload.getPlaylist,
            },
          })
        : commonAPI
            .getMediaPlaylist$(
              action.payload.getType,
              action.payload.search,
              action.payload.upc,
            )
            .pipe(
              map(response => {
                console.log(`getMediaGenreRequestEpic `, response);
                if (response.code !== 200) return;
                return webPlayer.getMediaPlaylistSuccess(
                  response,
                  action.payload,
                );
              }),
              catchError(error => errorHandler(error, action)),
            ),
    ),
  );

const getMediaScriptRequestEpic = action$ =>
  action$.pipe(
    ofType(webPlayer.GET_MEDIA_SCRIPT_REQUEST),
    mergeMap(action =>
      scriptAPI.getSong$(action.payload).pipe(
        map(response => {
          console.log(`getMediaScriptRequestEpic `, response);
          if (response.code !== 200) {
            return;
          }
          return webPlayer.getMediaScriptSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const postUserPlaylistItemRequestEpic = action$ =>
  action$.pipe(
    ofType(webPlayer.POST_USER_PLAYLIST_ITEM_REQUEST),
    mergeMap(action =>
      userPlaylistAPI.postUserPlaylistItem$(action.payload).pipe(
        map(response => {
          console.log(`postUserPlaylistItemRequestEpic `, response);
          if (response.code !== 200 || response.result !== 'SUCCESS') {
            return webPlayer.postUserPlaylistItemFailure(action.payload);
          }
          return webPlayer.postUserPlaylistItemSuccess(action.payload);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );
const deleteUserPlaylistItemRequestEpic = action$ =>
  action$.pipe(
    ofType(webPlayer.DELETE_USER_PLAYLIST_ITEM_REQUEST),
    mergeMap(action =>
      userPlaylistAPI.deleteUserPlaylistItem$(action.payload).pipe(
        map(response => {
          console.log(`deleteUserPlaylistItemRequestEpic `, response);
          if (response.code !== 200 || response.result !== 'SUCCESS') {
            return webPlayer.deleteUserPlaylistItemFailure(action.payload);
          }
          // return webPlayer.getMediaPlaylistRequest(
          //   action.action.getType,
          //   action.action.search,
          //   action.action.upc,
          // );

          return webPlayer.deleteUserPlaylistItemSuccess(action.action.upc);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );
const postUserMediaPlayRequestEpic = action$ =>
  action$.pipe(
    ofType(webPlayer.POST_USER_MEDIA_PLAY_REQUEST),
    mergeMap(action =>
      userMediaAPI.userMediaPlay$(action.payload.upc).pipe(
        map(response => {
          console.log(`postUserMediaPlayRequestEpic`, response);
          if (response.code !== 200) return;
          if (action.payload.getPlaylist) {
            console.log(`has getPlaylist`);
            return webPlayer.getMediaPlaylistSuccess(response, {
              getType: '',
              obj: {},
              upc: action.payload.upc,
            });
          }
          return webPlayer.postUserMediaPlaySuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(
  getMediaPlaylistRequestEpic,
  getMediaScriptRequestEpic,
  postUserPlaylistItemRequestEpic,
  deleteUserPlaylistItemRequestEpic,
  postUserMediaPlayRequestEpic,
);
