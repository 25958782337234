import * as freelecture from 'shared/actions/freelecture.action';

export const initialState = {
  freelectureList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case freelecture.GET_FREE_LECUTRE_SUCCESS:
      console.log({ type, payload });
      return {
        ...state,
        freelectureList: {
          ...payload,
        },
      };
    default:
      return state;
  }
};
