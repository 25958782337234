import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';

import * as song from 'shared/actions/song.action';
import * as scriptAPI from 'shared/apis/media/script.api';
import * as userMediaAPI from 'shared/apis/media/userMedia.api';

const getSongRequestEpic = action$ =>
  action$.pipe(
    ofType(song.GET_SONG_REQUEST),
    mergeMap(action =>
      scriptAPI.getSong$(action.payload).pipe(
        map(data => song.getSongSuccess(data.result)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const likeLyricRequestEpic = action$ =>
  action$.pipe(
    ofType(song.LIKE_LYRIC_REQUEST),
    mergeMap(action =>
      scriptAPI.likeLyric$(action.payload).pipe(
        map(data => song.likeLyricSuccess(action.idx)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const cancelLikeLyricRequestEpic = action$ =>
  action$.pipe(
    ofType(song.CANCEL_LIKE_LYRIC_REQUEST),
    mergeMap(action =>
      scriptAPI.cancelLikeLyric$(action.payload).pipe(
        map(data => song.cancelLikeLyricSuccess(action.idx)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const completeSongRequestEpic = action$ =>
  action$.pipe(
    ofType(song.COMPLETE_SONG_REQUEST),
    mergeMap(action =>
      userMediaAPI.completeSong$(action.payload).pipe(
        map(data => song.completeSongSuccess(data.result)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(
  getSongRequestEpic,
  likeLyricRequestEpic,
  cancelLikeLyricRequestEpic,
  completeSongRequestEpic,
);
