import {
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_FAILURE,
} from 'shared/actions/payment.action';
import storage from 'shared/utils/storage';

const CURRENT_VISITED_ITEM = 'CURRENT_VISITED_ITEM';

// 마지막으로 사용자가 본 페이지를 로컬 스토리지에 적용합니다.
// 이 값은 실패 페이지 리다이렉트 할 때 사용됩니다.
const purchaseMiddleware = store => next => action => {
  if (action.type === GET_PRODUCT_SUCCESS) {
    // console.log('purchaseMiddleware', action.payload);
    const { productId } = action.payload;
    storage.set(CURRENT_VISITED_ITEM, productId);
  } else if (
    action.type === GET_PRODUCT_REQUEST ||
    action.type === GET_PRODUCT_FAILURE
  ) {
    storage.del(CURRENT_VISITED_ITEM);
  }
  next(action);
};

export const getCurrentVisitedItem = () => storage.get(CURRENT_VISITED_ITEM);

export default purchaseMiddleware;
