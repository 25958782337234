import * as mediaInfo from 'shared/actions/mediaInfo.action';

export const initialState = {
  searchType: {},
};

let nextData = {};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case mediaInfo.INITIAL_STATE:
      nextData = { ...state };
      return nextData;
    // case mediaInfo.GET_SEARCH_TYPE_REQUEST:
    //   nextData = { ...state };
    //   console.log({ state, payload });
    //   return nextData;
    case mediaInfo.GET_SEARCH_TYPE_SUCCESS:
      nextData = { ...state };
      nextData.searchType = payload.result;
      return nextData;
    default:
      return state;
  }
};
