import * as speak from 'shared/actions/speak.action';

export const initialState = {
  artist: '',
  mediaScript: [],
  mediaUrl: '',
  titleEng: '',
  thumbnail: '',
  teacher: '',
  stepSize: 0,
  upc: '',
  purchase: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case speak.GET_SPEAK_REQUEST:
      return initialState;
    case speak.GET_SPEAK_SUCCESS:
      const {
        mediaUrl,
        artist,
        titleEng,
        mediaScript,
        thumbnail,
        teacher,
        upc,
        purchase,
      } = payload;
      return {
        ...state,
        mediaUrl,
        artist,
        titleEng,
        mediaScript,
        thumbnail,
        teacher,
        stepSize: mediaScript.length,
        upc,
        purchase,
      };
    default:
      return state;
  }
};
