import {
  GET,
  POST,
  DELETE,
  POST_CUSTOM_TOKEN,
  GET_CUSTOM_TOKEN,
  DELETE_CUSTOM_TOKEN,
  publicAPI,
} from 'shared/utils/ajax';

import { getCookie } from 'shared/utils/cookie';

const path = `/rest/user`;

const encode = value => {
  // console.log({ encode: value });
  return encodeURI(value.trim());
};

export const lectureSwitch$ = boolean =>
  POST(`${path}/lecture/switch?switching=${boolean}`);

export const deviceName$ = (id = '', name = '') =>
  POST(`${path}/device/name`, { id, name });

export const postProfile$ = (nickname = '', file) =>
  POST(`${path}/profile/base64`, {
    base64Image: file,
    nickname,
  });

export const postPassword$ = (oldPassword = '', newPassword = '') =>
  POST(`${path}/password`, {
    oldPassword,
    newPassword,
  });
export const postMarketing$ = agree =>
  POST(`${path}/marketing/agree?agree=${agree}`);

export const postPhone$ = (phone = '', token = {}) =>
  POST_CUSTOM_TOKEN(
    `${path}/phone?phone=${encode(phone)}`,
    null,
    token.access_token,
  );

export const postRegister$ = (userData = {}) =>
  POST(`${path}/register`, userData);
export const postLogout$ = () => POST(`${path}/logout`);

export const getCheckUserEmail$ = (email = '') =>
  GET(`${path}/exist/email?email=${encode(email)}`);

export const postTelemarketing$ = (
  { name = '', phone = '', gender = null, age = null },
  isAnnon = false,
) => {
  let data = {
    deviceType: 'WEB',
    name,
    phone,
  };

  if (gender) data.gender = gender;
  if (age) data.age = Number(age);

  console.log({ isAnnon });
  if (isAnnon) {
    return publicAPI.post(`/annon/ask/telemarketing`, data);
  } else {
    return POST(`${path}/ask/telemarketing`, data);
  }
};

export const getUserDevice$ = access_token =>
  GET_CUSTOM_TOKEN(
    `${path}/device`,
    access_token ? access_token : getCookie('dr_access_token_'),
  );
export const postUserDevice$ = (accessToken, refreshToken, id) =>
  POST_CUSTOM_TOKEN(
    `${path}/device`,
    {
      id,
      accessToken,
      refreshAccessToken: refreshToken,
    },
    accessToken,
  );

export const deleteUserDevice$ = (accessToken, id) =>
  DELETE_CUSTOM_TOKEN(`${path}/device?id=${id}`, null, accessToken);

export const deleteUser$ = agree => DELETE(`${path}/delete`);
