import { publicAPI } from 'shared/utils/ajax';

const path = `/intro`;

export const getLectureCurriculum$ = () =>
  publicAPI.get(`${path}/lecture/curriculum`);
export const getSearch$ = () => publicAPI.get(`${path}/search`);
export const getTeacherAll$ = () => publicAPI.get(`${path}/teacher/ALL`);

export const getLicense$ = () => publicAPI.get(`${path}/license`);
