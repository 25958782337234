import { Token, GET_CUSTOM_TOKEN } from 'shared/utils/ajax';

const formData = new FormData();
formData.append('grant_type', 'password');
formData.append('username', 'jd@qualson.com');
formData.append('password', 'password');

export const postAuthToken$ = (grant_type, data = {}) =>
  Token(`/oauth/token`, {
    grant_type,
    ...data,
  });

// export const getAuthCheck$ = () => GET('/rest/user/info');
export const getAuthCheck$ = action => {
  // console.log(`getAuthCheck$ Param : `, { token: action.token });
  return GET_CUSTOM_TOKEN('/rest/user/info', action.token.access_token);
};
