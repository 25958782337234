import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';

import * as auth from 'shared/actions/auth.action';
import * as challenge from 'shared/actions/challenge.action';
import * as modal from 'shared/actions/modal.action';
import * as toast from 'shared/actions/toast.action';
import * as loading from 'shared/actions/loading.action';

import * as challengeAPI from 'shared/apis/challenge.api';

const postChallengeStudyCompleteRequsetEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_STUDY_COMPLETE_REQUEST),
    mergeMap(action =>
      challengeAPI.postChallengeStudyComplete$(action.payload).pipe(
        map(response => {
          if (response.code !== 200) {
            return challenge.postChallengeStudyCompleteFailure(response);
          }
          return challenge.getChallengeDailyMissionRequset(action.payload);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getChallengeDailyMissionRequsetEpic = action$ =>
  action$.pipe(
    ofType(challenge.GET_CHALLENGE_DAILY_MISSION_REQUEST),
    mergeMap(action =>
      challengeAPI.getChallengeDailyMisiion$(action.payload).pipe(
        map(response => {
          if (response.code !== 200) {
            return challenge.getChallengeDailyMissionFailure(response);
          }
          return challenge.getChallengeDailyMissionSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getChallengeDailyMissionSuccessEpic = action$ =>
  action$.pipe(
    ofType(challenge.GET_CHALLENGE_DAILY_MISSION_SUCCESS),
    map(action => {
      return modal.initModal();
    }),
  );

const getChallengeDailyMissionFailureEpic = action$ =>
  action$.pipe(
    ofType(challenge.GET_CHALLENGE_DAILY_MISSION_FAILURE),
    map(action => {
      const response = action.payload;
      // 유저가 아직 챌린지를 시작하지 않은 경우, dailyMission을 가져오지 못하고 failure로 오게 됨.
      // reducer에서 status = NONE으로 설정
      if (response.code === 8100) {
        return modal.initModal();
      }
      if (response.code === 6251) {
        // 기기연결댓수 초과 에러에 대응
        return auth.getUserDevice();
      }
      // 8101, "챌린지를 시작할 수 없습니다."
      // 8102, "진행중인 챌린지가 있습니다."
      // 8200, "진행중인 챌린지가 없습니다."
      // 위 경우에는 무언가 꼬인 것
      // 챌린지 소개 페이지로 리다이렉트
      if (window.location.pathname !== '/my/challenge/intro') {
        window.location.pathname = '/my/challenge/intro';
      }
      return { type: 'CHALLENGE_ERROR', payload: response.code };
    }),
  );

const getChallengeListRequsetEpic = action$ =>
  action$.pipe(
    ofType(challenge.GET_CHALLENGE_LIST_REQUEST),
    mergeMap(action =>
      challengeAPI.getChallengeList$().pipe(
        map(response => {
          console.log(`getChallengeListRequsetEpic `, response, action);
          if (response.code !== 200) {
            return challenge.getChallengeListFailure(response, action);
          }
          return challenge.getChallengeListSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getChallengeStatusRequsetEpic = action$ =>
  action$.pipe(
    ofType(challenge.GET_CHALLENGE_STATUS_REQUEST),
    mergeMap(action =>
      challengeAPI.getChallengeStatus$().pipe(
        map(response => {
          // console.log(`getChallengeStatusRequsetEpic `, response, action);
          if (response.code !== 200) {
            return challenge.getChallengeStatusFailure(response, action);
          }
          return challenge.getChallengeStatusSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getChallengeStatusSuccessEpic = action$ =>
  action$.pipe(
    ofType(challenge.GET_CHALLENGE_STATUS_SUCCESS),
    map(response => {
      const service = response.payload.result.service;
      // console.log({ response, service: response.payload.result.service });

      return auth.setChallengeStatus(service);

      // return auth.getAuthCheckRequest(null, inherence_key, token);
    }),
  );

const postChallengeStartRequestEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_START_REQUEST),
    mergeMap(action =>
      challengeAPI.postChallengeStart$().pipe(
        map(response => {
          console.log(`postChallengeStartRequestEpic `, response, action);
          if (response.code !== 200) {
            return challenge.postChallengeStartFailure(response, action);
          }
          if (response.result.code === 8102) {
            return challenge.postChallengeStartFailure(response);
          }
          return challenge.postChallengeStartSuccess(response, action);
        }),
        catchError(error => {
          if (error.message === 'ajax timeout') {
            return challenge.postChallengeStartFailure(error, action);
          }

          return errorHandler(error, action);
        }),
      ),
    ),
  );

const postChallengeStartFailureFirstEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_START_FAILURE),
    map((response, action) => {
      console.log(
        `postChallengeStartFailureEpic - initModal`,
        response,
        action,
      );

      return modal.initModal();
    }),
  );

const postChallengeStartFailureEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_START_FAILURE),
    map(action => {
      // 챌린지를 이미 시작한 유저라고 나온다
      // 어쩌다가 이 유저가 챌린지 시작 버튼을 누른건진 모르겠지만
      // 일단 로그아웃시켜버리자
      if (action.payload.code === 8102) {
        return auth.logout();
      }

      return toast.pushToastApp({
        message:
          '챌린지 시작이 정상적으로 처리되지 않았습니다. 다시 시도해주세요.',
      });
    }),
  );

const postChallengeStartSuccessEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_START_SUCCESS),
    map((response, action) => {
      console.log(`postChallengeStartSuccessEpic `, response, action);

      return challenge.getChallengeDailyMissionRequset();
    }),
  );

const postChallengeDayMissionRequestEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_DAY_MISSION_REQUEST),
    map((response, action) => {
      return modal.pushModal({
        type: 'Loading',
      });
    }),
  );

//포스트 요청
const postChallengeDayMissionRequest1Epic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_DAY_MISSION_REQUEST),
    mergeMap(action =>
      challengeAPI.postChallengeDayMission$(action.payload, action.date).pipe(
        map(response => {
          console.log(`postChallengeDayMissionRequestEpic `, response, action);
          if (response.code !== 200) {
            return challenge.postChallengeDayMissionFailure(response, action);
          }
          return challenge.postChallengeDayMissionSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const postChallengeDayMissionFailureEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_DAY_MISSION_FAILURE),
    map((response, action) => {
      return modal.initModal();
    }),
  );

const postChallengeDayMissionSuccessEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_DAY_MISSION_SUCCESS),
    map((response, action) => {
      return modal.initModal();
    }),
  );

const postChallengeDayMissionSuccess1Epic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_DAY_MISSION_SUCCESS),
    map((response, action) => {
      return toast.pushToastApp({
        message: '미션 저장에 성공했습니다.',
      });
    }),
  );

const postChallengeDayMissionSuccess2Epic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_DAY_MISSION_SUCCESS),
    map((response, action) => {
      console.log(`postChallengeStartSuccessEpic `, response, action);
      return challenge.getChallengeDailyMissionRequset();
    }),
  );

const postChallengePaperRequestEpic = action$ =>
  action$.pipe(
    ofType(challenge.POST_CHALLENGE_PAPER_REQUEST),
    mergeMap(action =>
      challengeAPI.postChallengePaper$(action.payload).pipe(
        map(response => {
          console.log('postChallengePaperRequestEpic', response, action);

          return challenge.getChallengePaperRequest();

          // if (response.code !== 200) {
          //   console.log('response.code !== 200');
          //   return challenge.postChallengePaperFailure(response, action);
          // } else {
          //   console.log('response.code === 200');
          //   return challenge.getChallengePaperRequest();
          //   // of({
          //   //   type: challenge.POST_CHALLENGE_PAPER_SUCCESS,
          //   //   payload: response,
          //   //   action,
          //   // });
          // }
        }),
        // map(response => {
        //   console.log('NEXT');
        //   return modal.initModal();
        // }),
      ),
    ),
  );

// const postChallengePaperSuccessEpic = action$ =>
//   action$.pipe(
//     ofType(challenge.POST_CHALLENGE_PAPER_SUCCESS),
//     map(action => {
//       console.log(
//         'postChallengePaperSuccessEpic',
//         action.payload,
//         action.action,
//       );

//       // return modal.initModal();
//     }),
//   );

// const postChallengePaperFailureEpic = action$ =>
//   action$.pipe(
//     ofType(challenge.POST_CHALLENGE_PAPER_FAILURE),
//     map((response, action) => {
//       console.log('postChallengePaperFailureEpic', response, action);

//       return modal.initModal();
//     }),
//   );

const getChallengePaperRequestEpic = action$ =>
  action$.pipe(
    ofType(challenge.GET_CHALLENGE_PAPER_REQUEST),
    mergeMap(action =>
      challengeAPI.getChallengePaper$().pipe(
        map(response => {
          console.log(`getChallengePaperRequestEpic `, response, action);
          if (response.code !== 200) {
            return challenge.getChallengePaperFailure(response, action);
          } else {
            return challenge.getChallengePaperSuccess(response, action);
          }
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getChallengePaperSuccessEpic = action$ =>
  action$.pipe(
    ofType(challenge.GET_CHALLENGE_PAPER_REQUEST),
    map(action => {
      return modal.initModal();
    }),
  );

export default combineEpics(
  postChallengeStudyCompleteRequsetEpic,
  getChallengeDailyMissionRequsetEpic,
  getChallengeDailyMissionFailureEpic,
  getChallengeDailyMissionSuccessEpic,
  getChallengeListRequsetEpic,
  getChallengeStatusRequsetEpic,
  getChallengeStatusSuccessEpic,
  postChallengeStartRequestEpic,
  postChallengeStartSuccessEpic,
  postChallengeStartFailureFirstEpic,
  postChallengeStartFailureEpic,
  postChallengeDayMissionRequestEpic,
  postChallengeDayMissionRequest1Epic,
  postChallengeDayMissionSuccessEpic,
  postChallengeDayMissionSuccess1Epic,
  postChallengeDayMissionSuccess2Epic,
  postChallengeDayMissionFailureEpic,
  postChallengePaperRequestEpic,
  // postChallengePaperSuccessEpic,
  // postChallengePaperFailureEpic,
  getChallengePaperRequestEpic,
  getChallengePaperSuccessEpic,
);
