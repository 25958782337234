import commonReducer, { initialState as commonState } from './common.reducer';

import detailReducer, { initialState as detailState } from './detail.reducer';
import studyListReducer, {
  initialState as studyListState,
} from './studyList.reducer';
import searchSongReducer, {
  initialState as searchSongState,
} from './searchSong.reducer';
import songReducer, { initialState as songState } from './song.reducer';
import webPlayerReducer, {
  initialState as webPlayerState,
} from './webPlayer.reducer';

import listenReducer, { initialState as listenState } from './listen.reducer';

import wordReducer, { initialState as wordState } from './word.reducer';
import mediaInfoReducer, {
  initialState as mediaInfoState,
} from './mediaInfo.reducer';
import authReducer, { initialState as authState } from './auth.reducer';
import practiceReducer, {
  initialState as practiceState,
} from './practice.reducer';
import lectureReducer, {
  initialState as lectureState,
} from './lecture.reducer';
import speakReducer, { initialState as speakState } from './speak.reducer';
import toastReducer, { initialState as toastState } from './toast.reducer';
import modalReducer, { initialState as modalState } from './modal.reducer';
import introReducer, { initialState as introState } from './intro.reducer';
import paymentReducer, {
  initialState as paymentState,
} from './payment.reducer';
import customerReducer, {
  initialState as customerState,
} from './customer.reducer';
import challengeReducer, {
  initialState as challengeState,
} from './challenge.reducer';

import loadingReducer, {
  initialState as loadingState,
} from './loading.reducer';

import registerReducer, {
  initialState as registerState,
} from './register.reducer';

import freelectureReducer, {
  initialState as freelectureState,
} from './freelecture.reducer';

import videoPlayReducer, {
  initialState as videoPlayState,
} from './videoplay.reducer';

import couponReducer, { initialState as couponState } from './coupon.reducer';

import toastPopupReducer, {
  initialState as toastPopupState,
} from './toastpopup.reducer';

const initialState = {
  auth: authState,
  common: commonState,
  detail: detailState,
  studyList: studyListState,
  searchSong: searchSongState,
  song: songState,
  webPlayer: webPlayerState,
  word: wordState,
  listen: listenState,
  mediaInfo: mediaInfoState,
  practice: practiceState,
  lecture: lectureState,
  speak: speakState,
  toast: toastState,
  modal: modalState,
  intro: introState,
  payment: paymentState,
  customer: customerState,
  challenge: challengeState,
  loading: loadingState,
  register: registerState,
  coupon: couponState,
  freelecture: freelectureState,
  videoplay: videoPlayState,
  toastPopup: toastPopupState,
};

export default (state = initialState, action) => ({
  auth: authReducer(state.auth, action),
  common: commonReducer(state.common, action),
  detail: detailReducer(state.detail, action),
  studyList: studyListReducer(state.studyList, action),
  searchSong: searchSongReducer(state.searchSong, action),
  song: songReducer(state.song, action),
  webPlayer: webPlayerReducer(state.webPlayer, action),
  word: wordReducer(state.word, action),
  listen: listenReducer(state.listen, action),
  mediaInfo: mediaInfoReducer(state.mediaInfo, action),
  practice: practiceReducer(state.practice, action),
  lecture: lectureReducer(state.lecture, action),
  speak: speakReducer(state.speak, action),
  toast: toastReducer(state.toast, action),
  modal: modalReducer(state.modal, action),
  intro: introReducer(state.intro, action),
  payment: paymentReducer(state.payment, action),
  customer: customerReducer(state.customer, action),
  challenge: challengeReducer(state.challenge, action),
  loading: loadingReducer(state.loading, action),
  register: registerReducer(state.register, action),
  coupon: couponReducer(state.coupon, action),
  freelecture: freelectureReducer(state.freelecture, action),
  videoplay: videoPlayReducer(state.videoplay, action),
  toastPopup: toastPopupReducer(state.toastPopup, action),
});
