import * as auth from 'shared/actions/auth.action';

import { getGradeValue, getGradeName } from 'shared/utils/user';

export const initialState = {
  user: {
    nickname: '',
    username: '',
    phone: '',
    grade: 0,
    challengeStatus: false,
  },
  study: {
    latestStudy: [],
    recommendMediaList: [],
    locker: [
      {
        id: 'script',
        title: '가사',
        msg: '',
        count: 0,
        list: [],
      },
      {
        id: 'dictionary',
        title: '단어',
        msg: '',
        count: 0,
        list: [],
      },
    ],
  },
};

// const initialVertifyPhoneState = {
//   phone: '',
//   phoneCode: '',
//   verification: {
//     phone: null,
//     phoneCode: null,
//   },
//   error: {
//     phone: clone(errorInit),
//     phoneCode: clone(errorInit),
//   },
// };

const clone = obj => {
  if (obj === null || typeof obj !== 'object') return obj;
  let copy = obj.constructor();
  for (const attr in obj) {
    if (obj.hasOwnProperty(attr)) {
      copy[attr] = obj[attr];
    }
  }
  return copy;
};

const errorInit = {
  code: '',
  message: '',
};

const initialJoinState = {
  nickname: '',
  username: '',
  password: '',
  phone: '',
  phoneCode: '',
  verification: {
    username: null,
    nickname: null,
    password: null,
    phone: null,
    phoneCode: null,
  },
  error: {
    username: clone(errorInit),
    nickname: clone(errorInit),
    password: clone(errorInit),
    phone: clone(errorInit),
    phoneCode: clone(errorInit),
  },
};

const initialLoginState = {
  username: '',
  password: '',
  error: {
    status: false,
    type: '',
    desc: '',
  },
};

const initialFindState = {
  phone: '',
  username: '',
  message: '',
};

const initialSettingState = user => ({
  password_status: false,
  changed: false,
  input: {
    thumbnail: user.thumbnail,
    nickname: user.nickname,
    email: user.username,
    current_password: '',
    change_password: '',
    phone: user.phone,
    marketing: user.marketing ? true : false,
    lecturePopsongSwitch: user.lecturePopsongSwitch ? true : false,
  },
  error: {
    thumbnail: clone(errorInit),
    nickname: clone(errorInit),
    current_password: clone(errorInit),
    change_password: clone(errorInit),
  },
});

const addUpcLink = li => {
  let item = { ...li };
  item.link = '';
  if (li.upc) item.link = `/study/${li.upc}/detail`;
  return item;
};

let nextData = {};
export default (state = initialState, { type, payload, error, action }) => {
  switch (type) {
    case auth.SET_CHALLENGE_STATUS:
      nextData = { ...state };
      nextData.user.challengeStatus = payload;

      return nextData;

    case auth.POST_USER_LECTURE_SWITCH_SUCCESS:
      // console.log('POST_USER_LECTURE_SWITCH_SUCCESS', { payload, action });
      nextData = { ...state };
      nextData.user.lecturePopsongSwitch = action.payload;

      return nextData;

    case auth.DELETE_USER_FAILURE:
      // console.log('DELETE_USER_FAILURE', { payload, action });
      nextData = { ...state };

      return nextData;

    case auth.POST_USER_PROFILE_FAILURE:
      // console.log('POST_USER_PROFILE_FAILURE');
      nextData = { ...state };
      // console.log(payload, action, error);
      if (payload.code !== 200) {
        if (payload.code === 6501) {
          nextData.setting.error = {
            ...nextData.setting.error,
            nickname: {
              code: payload.code,
              message: payload.message,
            },
          };
        } else if (payload.code === 6203) {
          nextData.setting.error = {
            ...nextData.setting.error,
            thumbnail: {
              code: payload.code,
              message: payload.message,
            },
          };
        }
      }

      return nextData;

    case auth.POST_USER_PASSWORD_FAILURE:
      // console.log('POST_USER_PASSWORD_FAILURE');
      nextData = { ...state };
      // console.log(payload, action, error);
      if (payload.code !== 200) {
        if (payload.code === 6002) {
          nextData.setting.error = {
            ...nextData.setting.error,
            current_password: {
              code: payload.code,
              message: payload.message,
            },
          };
        } else if (payload.code === 6202) {
          const list = ['current_password', 'change_password'];
          list.forEach(li => {
            if (nextData.setting.input[li].length < 8) {
              nextData.setting.error = {
                ...nextData.setting.error,
                [li]: {
                  code: payload.code,
                  message: payload.message,
                },
              };
            }
          });
        }
      }

      return nextData;

    case auth.SET_USER_SETTING:
      nextData = { ...state };
      // console.log({ nextData });
      nextData.setting = {
        ...nextData.setting,
        ...payload,
      };

      return nextData;

    case auth.START_USER_SETTING:
      nextData = { ...state };
      nextData.setting = {
        ...initialSettingState(state.user),
      };

      // console.log({ nextData });

      return nextData;

    case auth.FINISH_USER_SETTING:
      nextData = { ...state };
      delete nextData.setting;

      return nextData;

    // 사용자 정보
    case auth.SET_USER:
      nextData = { ...state };
      nextData.user = {
        ...state.user,
        ...payload,
      };
      return nextData;

    case auth.GET_AUTH_CHECK:
      nextData = { ...state };
      nextData.user = {
        ...initialState.user,
        ...guestData,
      };
      return nextData;

    case auth.GET_AUTH_CHECK_SUCCESS:
      nextData = { ...state };

      if (payload.code === 6103) {
        return nextData;
      }

      const grade = getGradeValue(payload.result.userPurchase);
      const gradeName = getGradeName(payload.result.userPurchase);

      // console.log({ user: payload.result });
      nextData.user = {
        ...state.user,
        ...payload.result,
        grade,
        gradeName,
      };

      // console.log('GET_AUTH_CHECK_SUCCESS', { nextData });

      return nextData;

    case auth.GET_AUTH_CHECK_FAILURE:
      nextData = { ...state };
      nextData.user = {
        ...initialState.user,
        // ...guestData,
      };
      return nextData;

    // 인증토큰
    case auth.POST_AUTH_TOKEN_REQUEST:
      nextData = { ...state };
      // console.log({ nextData });
      return nextData;
    case auth.POST_AUTH_TOKEN_FAILURE:
      nextData = { ...state };
      // console.log('POST_AUTH_TOKEN_FAILURE', { nextData, payload });
      // alert(JSON.stringify(payload));
      return nextData;

    // 사용자 학습 정보
    case auth.GET_LATEST_STUDY_LIST_SUCCESS:
      nextData = { ...state };

      // study: {
      //   latestStudy: [],
      //   locker: [
      //     {
      //       id: 'script',
      //       title: '가사',
      //       msg: '',
      //       list: [],
      //     },
      //     {
      //       id: 'dictionary',
      //       title: '단어',
      //       msg: '',
      //       list: [],
      //     },
      //   ],
      // },

      // console.log({ payload: payload.result });
      let latestStudy = payload.result.recentMedia;
      if (!Array.isArray(latestStudy)) latestStudy = [latestStudy];
      nextData.study.latestStudy = latestStudy.map(addUpcLink);
      let recommendMediaList = payload.result.recommendMediaList;
      if (!Array.isArray(recommendMediaList))
        recommendMediaList = [recommendMediaList];
      nextData.study.recommendMediaList = recommendMediaList.map(addUpcLink);

      nextData.study.locker = nextData.study.locker.map(lock => {
        let msg = '';
        let count = 0;
        if (lock.id === 'script') {
          count = payload.result.scriptCount;
          msg = count ? `${count}개` : '마음에 드는 가사를\n차곡차곡 담아봐요';
        } else if (lock.id === 'dictionary') {
          count = payload.result.dictionaryCount;
          msg = count ? `${count}개` : '몰라서 찾아본 단어는\n여기서 한 눈에!';
        }
        lock.msg = lock.msg = msg;
        lock.count = lock.count = count;
        return lock;
      });

      // nextData.study.latestStudy = payload.result.map(li => {
      //   let item = { ...li };
      //   item.link = '';
      //   if (li.upc) item.link = `/study/${li.upc}/detail`;
      //   return item;
      // });
      return nextData;

    // 사용자 학습 정보 - 담은 가사
    case auth.GET_USER_SCRIPT_SUCCESS:
      nextData = { ...state };
      // console.log('GET_USER_SCRIPT_SUCCESS', { payload });
      if (payload.result) {
        let lockerIndex = state.study.locker.findIndex(
          lock => lock.id === 'script',
        );
        if (typeof lockerIndex === 'number' && lockerIndex >= 0) {
          let count = payload.result.length;
          nextData.study.locker[lockerIndex] = {
            ...nextData.study.locker[lockerIndex],
            msg: count ? `${count}개` : '마음에 드는 가사를\n차곡차곡 담아봐요',
            list: payload.result.map(li => {
              const note = li.userScript.note;
              li.userScript.note = note ? note : '';
              li.userScript.noteChanged = li.userScript.note;
              li.userScript.isChanged = false;
              return li;
            }),
          };
        }
      }
      return nextData;

    case auth.POST_USER_SCRIPT_MEMO_SUCCESS:
      nextData = { ...state };
      if (nextData.study.locker.length) {
        let scriptIndex = nextData.study.locker.findIndex(
          item => item.id === 'script',
        );
        let scriptItemIndex = nextData.study.locker[scriptIndex].list.findIndex(
          item => item.id === payload.mediaScriptId,
        );
        const userScript =
          nextData.study.locker[scriptIndex].list[scriptItemIndex].userScript;
        userScript.note = payload.note;
        userScript.noteChanged = payload.note;
        userScript.isChanged = false;
      }
      return nextData;

    case auth.SET_USER_SCRIPT_MEMO_ONCHANGE:
      nextData = { ...state };
      // console.log({ payload });

      if (nextData.study.locker.length) {
        let scriptIndex = nextData.study.locker.findIndex(
          item => item.id === 'script',
        );
        let scriptItemIndex = nextData.study.locker[scriptIndex].list.findIndex(
          item => item.id === payload.mediaScriptId,
        );
        const userScript =
          nextData.study.locker[scriptIndex].list[scriptItemIndex].userScript;
        userScript.noteChanged = payload.note;
        if (userScript.note !== userScript.noteChanged) {
          userScript.isChanged = true;
        } else {
          userScript.isChanged = false;
        }
      }

      return nextData;
    case auth.DELETE_USER_SCRIPT_REQUEST:
      nextData = { ...state };
      if (nextData.study.locker.length) {
        let scriptIndex = nextData.study.locker.findIndex(
          item => item.id === 'script',
        );
        nextData.study.locker[scriptIndex].list = nextData.study.locker[
          scriptIndex
        ].list.filter(item => item.id !== payload);
      }
      return nextData;

    // 사용자 학습 정보 - 담은 단어
    case auth.GET_USER_DICTIONARY_SUCCESS:
      nextData = { ...state };
      // console.log('GET_USER_DICTIONARY_SUCCESS', { payload });
      if (payload.result) {
        let lockerIndex = state.study.locker.findIndex(
          lock => lock.id === 'dictionary',
        );
        if (typeof lockerIndex === 'number' && lockerIndex >= 0) {
          let count = payload.result.length;
          nextData.study.locker[lockerIndex] = {
            ...nextData.study.locker[lockerIndex],
            msg: count ? `${count}개` : '몰라서 찾아본 단어는\n여기서 한 눈에!',
            list: [...payload.result],
          };
        }
      }
      return nextData;

    //DELETE_USER_DICTIONARY_REQUEST
    case auth.DELETE_USER_DICTIONARY_REQUEST:
      nextData = { ...state };
      let dictionaryIndex = nextData.study.locker.findIndex(
        item => item.id === 'dictionary',
      );

      // console.log({ payload });
      nextData.study.locker[dictionaryIndex].list = nextData.study.locker[
        dictionaryIndex
      ].list.filter(
        item =>
          JSON.stringify(item.userDictionaryId) !==
          JSON.stringify(payload.userDictionaryId),
      );
      return nextData;

    // 회원가입 - 데이터
    case auth.START_JOIN_DATA:
      nextData = { ...state };
      nextData.join = initialJoinState;
      return nextData;
    case auth.FINISH_JOIN_DATA:
      let { join, ...joinRest } = state;
      nextData = { ...joinRest };
      return nextData;
    case auth.SET_JOIN_DATA:
      nextData = { ...state };
      if (!nextData.join) nextData.join = initialJoinState;
      nextData.join = {
        ...nextData.join,
        ...payload,
      };
      return nextData;
    case auth.SET_JOIN_VERIFICATION_DATA:
      nextData = { ...state };
      if (!nextData.join) nextData.join = initialJoinState;

      nextData.join.verification = {
        ...nextData.join.verification,
        ...payload,
      };

      Object.keys(payload).forEach(item => {
        if (nextData.join.verification[item]) {
          nextData.join.error[item] = { ...errorInit };
        } else if (nextData.join.verification[item] === null) {
          nextData.join.error[item] = { ...errorInit };
        } else {
          nextData.join.error[item] = { ...error };
        }
      });

      return nextData;
    // 회원가입 - 이메일 중복체크 :: 성공
    case auth.GET_CHECK_EMAIL_SUCCESS:
      nextData = { ...state };
      if (nextData.join && nextData.join.verification) {
        nextData.join.verification.username = payload.result;
        nextData.join.error.username = { ...errorInit };
      }
      return nextData;
    // 회원가입 - 이메일 중복체크 :: 실패
    case auth.GET_CHECK_EMAIL_FAILURE:
      nextData = { ...state };
      // console.log('GET_CHECK_EMAIL_FAILURE', { payload });
      nextData.join.verification.username = false;
      nextData.join.error.username = {
        code: payload.code,
        message: payload.message,
      };
      return nextData;
    // 회원가입 - 닉네임 중복체크
    case auth.GET_CHECK_NICKNAME_SUCCESS:
      nextData = { ...state };
      console.log('verification', { nextData });
      // if(){}
      nextData.join.verification.nickname = payload.result;
      nextData.join.error.nickname = { ...errorInit };

      console.log('GET_CHECK_NICKNAME_SUCCESS', { nextData });
      return nextData;
    case auth.GET_CHECK_NICKNAME_FAILURE:
      nextData = { ...state };
      nextData.join.verification.nickname = false;
      nextData.join.error.nickname = {
        code: payload.code,
        message: payload.message,
      };
      // console.log('GET_CHECK_NICKNAME_FAILURE', { nextData });

      return nextData;

    // 회원가입 - 핸드폰 인증번호 - 발급 - 성공
    case auth.GET_SEND_PHONE_AUTH_SUCCESS:
      // console.log(`GET_SEND_PHONE_AUTH_SUCCESS`, { payload });
      nextData = { ...state };
      nextData[payload.type].phoneCode = '';
      nextData[payload.type].verification.phone = true;
      nextData[payload.type].verification.phoneCode = null;
      nextData[payload.type].error.phone = { ...errorInit };
      nextData[payload.type].error.phoneCode = { ...errorInit };

      return nextData;
    // 회원가입 - 핸드폰 인증번호 - 발급 - 실패
    case auth.GET_SEND_PHONE_AUTH_FAILURE:
      nextData = { ...state };
      nextData[payload.type].verification.phone = false;
      nextData[payload.type].verification.phoneCode = false;
      // console.log('GET_SEND_PHONE_AUTH_FAILURE', { payload });
      nextData[payload.type].error.phone = {
        code: payload.code,
        message: payload.message,
      };
      nextData[payload.type].error.phoneCode = { ...errorInit };

      nextData[payload.type].phoneCode = '';
      return nextData;
    // 회원가입 - 핸드폰 인증번호 - 검증
    case auth.GET_VERIFY_CODE_SUCCESS:
      nextData = { ...state };
      nextData[payload.type].verification.phoneCode = payload.result;
      if (typeof payload.result === 'boolean') {
        // console.log(`boolean`);
        nextData[payload.type].verification.phoneCode = payload.result;
      } else if (
        payload.result &&
        payload.result.email &&
        payload.result.email.match('@')
      ) {
        // console.log(`match('@')`);
        nextData[payload.type].verification.phoneCode = true;
        nextData[payload.type].error.phoneCode.code = payload.code;
        nextData[payload.type].error.phoneCode.email = payload.result.email;
        nextData[payload.type].error.phoneCode.message =
          '이미 가입하신 아이디가 있습니다.';
      }
      return nextData;
    // 회원가입 - 핸드폰 인증번호 - 검증 - 실패
    case auth.GET_VERIFY_CODE_FAILURE:
      nextData = { ...state };
      nextData[payload.type].verification.phoneCode = false;
      nextData[payload.type].error.phoneCode.message =
        '핸드폰번호 인증에 실패하였습니다.';

      return nextData;
    // 회원가입 - 요청
    case auth.POST_REGISTER_SUCCESS:
      nextData = { ...state };
      nextData.result = payload.result;
      return nextData;

    // 로그인 - 데이터
    case auth.START_LOGIN_DATA:
      nextData = { ...state };
      nextData.login = initialLoginState;
      return nextData;
    case auth.FINISH_LOGIN_DATA:
      let { login, ...loginRest } = state;
      nextData = { ...loginRest };
      return nextData;
    case auth.SET_LOGIN_DATA:
      nextData = { ...state };
      if (!nextData.login) nextData.login = initialLoginState;
      nextData.login = {
        ...nextData.login,
        ...payload,
      };
      return nextData;
    // 로그인 - 실패
    case auth.SET_LOGIN_ERROR:
      // console.log({ payload });
      nextData = { ...state };
      if (!nextData.login) nextData.login = initialLoginState;

      if (!validateEmail(nextData.login.username)) {
        //
        nextData.login.error = {
          ...nextData.login.error,
          status: true,
          type: 'username',
          desc: '올바른 이메일을 입력해주세요.',
        };
        return nextData;
      } else if (payload) {
        nextData.login.error = {
          ...nextData.login.error,
          ...payload,
        };
      } else if (nextData.login.password.length < 8) {
        nextData.login.error = {
          ...nextData.login.error,
          status: true,
          type: 'password',
          desc: '비밀번호는 최소 8자 입니다.',
        };
        return nextData;
      } else {
        nextData.login.error = {
          ...nextData.login.error,
          status: false,
          type: '',
          desc: '',
        };
      }

      if (nextData.login.error.type === 'password') {
        nextData.login.error.desc = '올바른 비밀번호를 입력해주세요.';
      } else if (nextData.login.error.type === 'username') {
        // nextData.login.error.desc = '올바른 이메일을 입력해주세요.';
        nextData.login.error.desc = '이메일 정보가 올바르지 않습니다.';
      }
      return nextData;

    // 아이디,비밀번호 찾기 - 데이터
    case auth.START_FIND_DATA:
      nextData = { ...state };
      nextData.find = initialFindState;
      return nextData;
    case auth.FINISH_FIND_DATA:
      let { find, ...findRest } = state;
      nextData = { ...findRest };
      return nextData;
    case auth.SET_FIND_DATA:
      nextData = { ...state };
      if (!nextData.find) nextData.find = initialFindState;
      nextData.find = {
        ...nextData.find,
        ...payload,
      };
      return nextData;
    // 아이디,비밀번호 찾기 - 이메일(아이디) 검색
    case auth.GET_FIND_EMAIL_SUCCESS:
      nextData = { ...state };
      if (!nextData.find) nextData.find = initialFindState;
      nextData.find.username = payload.result;
      nextData.find.message = '';
      if (!payload.result.trim()) nextData.find.message = noEmail;
      else {
        let email = emailPartialEncryption(payload.result);
        nextData.find.message = findEmailSuccessMessage(email);
      }
      return nextData;
    // 아이디,비밀번호 찾기 - 이메일(아이디) 검색 - 실패
    case auth.GET_FIND_EMAIL_FAILURE:
      nextData = { ...state };
      if (!nextData.find) nextData.find = initialFindState;
      nextData.find.message = noResult;
      return nextData;
    // 아이디,비밀번호 찾기 - 임시 비밀번호 발급
    case auth.GET_FIND_PASSWORD_SUCCESS:
      nextData = { ...state };

      console.log('GET_FIND_PASSWORD_SUCCESS', { payload, nextData });
      if (payload.result === 'SUCCESS') {
        nextData.vertifyPhone.verification.phoneCode = true;
      }

      if (payload.code === 6000) {
        nextData.vertifyPhone.error.phone.message =
          '가입된 휴대폰 번호가 아닙니다.';
      }

      // if (!nextData.find) nextData.find = initialFindState;
      // nextData.find.username = '';
      // nextData.find.message = sendPasswordSuccessMessage;

      return nextData;

    case auth.GET_VERIFY_CODE_FIND_EMAIL_FAILURE:
      nextData = { ...state };

      // console.log(`GET_VERIFY_CODE_FIND_EMAIL_FAILURE`, { nextData, payload });

      nextData.vertifyPhone.verification.phoneCode = payload.result.vertify;
      nextData.vertifyPhone.error.phoneCode.message = `인증번호가 일치하지 않습니다.`;

      return nextData;

    // 아이디,비밀번호 찾기 - 임시 비밀번호 발급 - 실패
    case auth.GET_FIND_PASSWORD_FAILURE:
      nextData = { ...state };
      if (!nextData.find) nextData.find = initialFindState;
      nextData.find.username = '';
      nextData.find.message = sendPasswordFailureMessage;
      return nextData;

    // 핸드폰번호 인증
    case auth.START_VERTIFY_PHONE_DATA:
      nextData = { ...state };
      nextData.vertifyPhone = {
        phone: '',
        phoneCode: '',
        verification: {
          phone: null,
          phoneCode: null,
        },
        error: {
          phone: { ...errorInit },
          phoneCode: { ...errorInit },
        },
      };

      // console.log('START_VERTIFY_PHONE_DATA', { nextData });
      return nextData;
    case auth.FINISH_VERTIFY_PHONE_DATA:
      nextData = { ...state };
      if (nextData.vertifyPhone) {
        delete nextData.vertifyPhone;
      }
      // console.log('FINISH_VERTIFY_PHONE_DATA', { nextData });
      return nextData;

    case auth.SET_VERTIFY_PHONE_DATA:
      nextData = { ...state };
      nextData.vertifyPhone = {
        ...nextData.vertifyPhone,
        ...payload,
      };

      Object.keys(payload).forEach(key => {
        nextData.vertifyPhone.verification[key] = null;
        nextData.vertifyPhone.error[key].code = '';
        nextData.vertifyPhone.error[key].message = '';
      });

      return nextData;

    case auth.START_VERTIFY_PHONE_ERROR:
      nextData = { ...state };
      nextData.vertifyPhone.verification = {
        ...nextData.vertifyPhone.verification,
        ...payload,
      };

      nextData.vertifyPhone.error = {
        ...nextData.vertifyPhone.error,
        ...error,
      };

      return nextData;

    case auth.POST_PHONE_NUMBER_SUCCESS:
      nextData = { ...state };
      // console.log(`POST_PHONE_NUMBER_SUCCESS`, { payload });
      nextData.user.phone = payload.phone;

      return nextData;

    default:
      return state;
  }
};

const guestData = {
  username: 'GUEST',
  grade: 0,
};
const noResult = '검색 결과가 없습니다.';
const noEmail = '입력하신 번호로 등록된 이메일이 존재하지 않습니다.';
// const sendPasswordSuccessMessage = '임시 비밀번호를 전송하였습니다.';
const sendPasswordFailureMessage = '임시 비밀번호 발급을 실패하였습니다.';
const findEmailSuccessMessage = email => `검색하신 이메일은 ${email} 입니다.`;
const emailPartialEncryption = emailText => {
  let emailArr = emailText.split('@');
  let eArr = emailArr[0].split('');
  emailArr[0] = eArr.map((item, i) => {
    if (i % 2) return '*';
    return item;
  });
  emailArr[0] = emailArr[0].join('');
  const email = emailArr.join('@');
  return email;
};

const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
