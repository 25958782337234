import * as studyList from 'shared/actions/studyList.action';

export const initialState = [];

let nextData = [];
let result = '';
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case studyList.GET_STUDY_LIST_ALL_SUCCESS:
      result = payload.result;
      nextData = result.map(r => {
        const { media, ...rest } = r;

        return {
          ...rest,
          media: media.map(m => {
            const { thumbnail, ...another } = m;
            return { ...another, image: thumbnail };
          }),
        };
      });
      return nextData;
    default:
      return state;
  }
};
