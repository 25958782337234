import * as lecture from 'shared/actions/lecture.action';

export const initialState = {
  artist: '',
  titleEng: '',
  note: '',
  mediaScript: [],
  mediaUrl: '',
  thumbnail: '',
  teacher: '',
  lectureId: 0,
  progress: 0,
  upc: '',
  purchase: null,
};

// detail 정보 강의 정보와 통일하기 때문에 곡 상세 API 가져옴.
export default (state = initialState, { type, payload, step }) => {
  switch (type) {
    case lecture.SET_INIT:
      return initialState;

    case lecture.GET_LECTURE_SUCCESS:
      return {
        ...state,
        artist: payload.artist,
        titleEng: payload.titleEng,
        note: payload.mediaScriptLecture.lectureList[step - 1].note,
        mediaUrl:
          payload.mediaScriptLecture.lectureList[step - 1].urlHorizontal,
        mediaScript: payload.mediaScriptLecture.lectureList,
        thumbnail: payload.thumbnail,
        teacher: payload.teacher,
        lectureId: payload.mediaScriptLecture.id,
        progress: payload.mediaScriptLecture.lectureList[step - 1].progress,
        upc: payload.upc,
        horizontalLength:
          payload.mediaScriptLecture.lectureList[step - 1].horizontalLength,
        purchase: payload.purchase,
      };
    case lecture.SAVE_LECTURE_SUCCESS:
      // console.log({ payload });
      return {
        ...state,
        mediaScript: payload.lectureList,
      };
    default:
      return state;
  }
};
