import { EMPTY } from 'rxjs';
import { ofType, combineEpics } from 'redux-observable';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { errorHandler } from 'shared/epics/auth.epic';
import * as payment from 'shared/actions/payment.action';
import * as auth from 'shared/actions/auth.action';
import * as sellAPI from 'shared/apis/sell.api';

import storage from 'shared/utils/storage';
import { getCookie } from 'shared/utils/cookie';

const getPurchaseWayEpic = action$ =>
  action$.pipe(
    ofType(payment.GET_PURCHASE_WAY_REQUEST),
    mergeMap(action =>
      sellAPI.getPurchaseWay$().pipe(
        map(data => {
          console.log('GET_PURCHASE_WAY_REQUEST', { data });
          if (data.code !== 200) {
            return payment.getPurchaseWayFailure(data?.result);
          }
          return payment.getPurchaseWaySuccess(data?.result);
        }),
        catchError(error => {
          errorHandler(error, action);
        }),
      ),
    ),
  );

const getProductsPackagesEpic = action$ =>
  action$.pipe(
    ofType(payment.GET_PRODUCTS_PACKAGES_REQUEST),
    mergeMap(action =>
      sellAPI.getProductsPackageInfo$().pipe(
        map(data => {
          if (data.code !== 200) {
            return payment.getProductsPackageFailure(data.result);
          }

          return payment.getProductsPackageSuccess(data.result);
        }),
        catchError(error => {
          errorHandler(error, action);
        }),
      ),
    ),
  );

const getProductEpic = action$ =>
  action$.pipe(
    ofType(payment.GET_PRODUCT_REQUEST),
    mergeMap(action =>
      sellAPI.getProduct$(action.payload).pipe(
        map(data => {
          if (data.code !== 200) {
            return payment.getProductFailure(data.result);
          }
          console.log('GET_PRODUCT_REQUEST!!!!', data.result);
          return payment.getProductSuccess(data.result);
        }),
        catchError(error => {
          errorHandler(error, action);
        }),
      ),
    ),
  );

const getProductsRequestEpic = action$ =>
  action$.pipe(
    ofType(payment.GET_PRODUCTS_REQUEST),
    mergeMap(action =>
      sellAPI.getProductsInfo$(action.payload).pipe(
        map(data => {
          if (data.code !== 200)
            return payment.getProductsFailure(data.result, action.payload);
          return payment.getProductsSuccess(data.result, action.payload);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getOrderIdRequestEpic = action$ =>
  action$.pipe(
    ofType(payment.GET_ORDER_ID_REQUEST),
    mergeMap(action =>
      sellAPI.getOrderId$(action.payload).pipe(
        map(data => {
          console.log(`getOrderIdRequestEpic`, { data });
          if (data.code !== 200)
            return payment.getOrderIdFailure(data.result, action.payload);
          return payment.getOrderIdSuccess(data.result, action.payload);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const postPurchaseInfoRequestEpic = action$ =>
  action$.pipe(
    ofType(payment.POST_PURCHASE_INFO_REQUEST),
    mergeMap(action =>
      sellAPI.postPurchaseInfo$(action.payload).pipe(
        map(data => {
          console.log(`postPurchaseInfoRequestEpic`, { data });
          if (data.code !== 200)
            return payment.postPurchaseInfoFailure(data.result, action.payload);

          const access_token = getCookie('dr_access_token');
          const refresh_token = getCookie('dr_refresh_token');
          const expires_in = getCookie('dr_expires_in');
          const inherence_key = getCookie('dr_inherence_key');

          const token = {
            access_token,
            refresh_token,
            expires_in,
          };
          return auth.getAuthCheckRequest(null, inherence_key, token);

          // return payment.postPurchaseInfoSuccess(data.result, action.payload);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getSellUserPurchaseRequestEpic = action$ =>
  action$.pipe(
    ofType(payment.GET_SELL_USER_PURCHASE_REQUEST),
    mergeMap(action =>
      sellAPI.getUserPurchase$().pipe(
        map(data => {
          console.log(`getSellUserPurchaseRequestEpic`, { data });
          if (data.code === 401 || data.code === 403 || data.code === 404) {
            console.log('getSellUserPurchaseFailure', data);
            return payment.getSellUserPurchaseFailure(data, action.payload);
          }

          console.log({ SuccessData: data, action });
          return payment.getSellUserPurchaseSuccess(data, action.payload);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const saveCurrentFormEpic = (action$, state$) =>
  action$.pipe(
    ofType(payment.SAVE_CURRENT_FORM),
    mergeMap(action => {
      const formData = state$.value.shared.payment.state;
      storage.set(
        `saved_payment_form_${formData.email}`,
        JSON.stringify(formData),
      );
      return EMPTY;
    }),
  );

export default combineEpics(
  getProductsRequestEpic,
  getOrderIdRequestEpic,
  postPurchaseInfoRequestEpic,
  getSellUserPurchaseRequestEpic,
  getProductsPackagesEpic,
  getProductEpic,
  getPurchaseWayEpic,
  saveCurrentFormEpic,
);
