import { GET, publicAPI } from 'shared/utils/ajax';

const encode = (value = '') => {
  // console.log(value);
  return encodeURI(value);
};

/**
 * 곡 상세
 * @param {string} upc
 */
export const authKey$ = ({ type, step, mediaUrl }) =>
  GET(`/rest/media/authKey?url=${encode(mediaUrl)}`);

export const authKeyAnnon$ = ({ type, step, mediaUrl }) =>
  publicAPI.get(`/annon/authKey?url=${encode(mediaUrl)}`);

export const getStudyStart$ = (payload = {}) => {
  const query = makeQueryString(payload);
  return GET(`/rest/media/study/start${query ? query : ''}`);
};

const makeQueryString = payload => {
  let arr = [];
  if (Array.isArray(payload)) {
    arr = payload.map();
  } else {
    arr = Object.keys(payload).map(k => ({ [k]: payload[k] }));
  }

  let string = '';
  if (arr.length) string += '?';
  arr.forEach(ar => {
    const items = Object.keys(ar).map(a => `${a}=${encode(ar[a])}`);
    items.forEach(item => {
      if (string !== '?') {
        string += '&';
      }

      // const encoded = encode(item);
      string += `${item}`;
    });
  });

  return string;
};
