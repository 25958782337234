import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';

import * as customer from 'shared/actions/customer.action';

import * as annonAPI from 'shared/apis/annon/annon.api';

const getCustomerFaqRequestEpic = action$ =>
  action$.pipe(
    ofType(customer.GET_CUSTOMER_FAQ_REQUEST),
    mergeMap(action =>
      annonAPI.getAnnonFAQKey$().pipe(
        map(response => {
          console.log(`getCustomerFaqRequestEpic `, response);

          if (response.code !== 200) {
            return customer.getCustomerFaqFailure(response);
          }

          return customer.getCustomerFaqSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getCustomerReviewRequestEpic = action$ =>
  action$.pipe(
    ofType(customer.GET_CUSTOMER_REVIEW_REQUEST),
    mergeMap(action =>
      annonAPI.getAnnonReview$(54).pipe(
        map(response => {
          // console.log(`getCustomerReviewRequestEpic `, response);

          if (response.code !== 200) {
            return customer.getCustomerReviewFailure(response);
          }

          return customer.getCustomerReviewSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(
  getCustomerFaqRequestEpic,
  getCustomerReviewRequestEpic,
);
