export const GET_CUSTOMER_FAQ_REQUEST = '@introduce/GET_CUSTOMER_FAQ_REQUEST';
export const GET_CUSTOMER_FAQ_SUCCESS = '@introduce/GET_CUSTOMER_FAQ_SUCCESS';
export const GET_CUSTOMER_FAQ_FAILURE = '@introduce/GET_CUSTOMER_FAQ_FAILURE';

/**
 * FAQ 요청 api
 */
export const getCustomerFaqRequest = payload => ({
  type: GET_CUSTOMER_FAQ_REQUEST,
  payload,
});

export const getCustomerFaqSuccess = payload => ({
  type: GET_CUSTOMER_FAQ_SUCCESS,
  payload,
});

export const getCustomerFaqFailure = payload => ({
  type: GET_CUSTOMER_FAQ_FAILURE,
  payload,
});

/**
 * 리뷰(후기)요청 api
 * 현재는 메인의 후기 섹션의만 노출
 */
export const GET_CUSTOMER_REVIEW_REQUEST =
  '@introduce/GET_CUSTOMER_REVIEW_REQUEST';
export const GET_CUSTOMER_REVIEW_SUCCESS =
  '@introduce/GET_CUSTOMER_REVIEW_SUCCESS';
export const GET_CUSTOMER_REVIEW_FAILURE =
  '@introduce/GET_CUSTOMER_REVIEW_FAILURE';

export const getCustomerReviewRequest = payload => ({
  type: GET_CUSTOMER_REVIEW_REQUEST,
  payload,
});

export const getCustomerReviewSuccess = payload => ({
  type: GET_CUSTOMER_REVIEW_SUCCESS,
  payload,
});

export const getCustomerReviewFailure = payload => ({
  type: GET_CUSTOMER_REVIEW_FAILURE,
  payload,
});
