import { ofType } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as freelecture from 'shared/actions/freelecture.action';
import * as freelectureApi from 'shared/apis/media/detail.api';

const getFreeLectureRequestEpic = action$ =>
  action$.pipe(
    ofType(freelecture.GET_FREE_LECTURE_REQUEST),
    mergeMap(action =>
      freelectureApi.getDetailSong$(action.payload).pipe(
        map(res => freelecture.getFreeLectureSuccess(res.result)),
        catchError(error => console.log(error)),
      ),
    ),
  );

export default getFreeLectureRequestEpic;
