import { ofType, combineEpics } from 'redux-observable';
import {
  map,
  mapTo,
  mergeMap,
  switchMap,
  catchError,
  delay,
  take,
  takeUntil,
} from 'rxjs/operators';
import { of, merge, EMPTY } from 'rxjs';

import * as auth from 'shared/actions/auth.action';
import * as toast from 'shared/actions/toast.action';
import * as modal from 'shared/actions/modal.action';
import * as coupon from 'shared/actions/coupon.action';

import * as authAPI from 'shared/apis/auth.api';
import * as userAPI from 'shared/apis/user.api';
import * as scriptAPI from 'shared/apis/media/script.api';
import * as userDictionaryAPI from 'shared/apis/media/userDictionary.api';
import * as userScriptAPI from 'shared/apis/media/userScript.api';
import * as mediaUserAPI from 'shared/apis/media/user.api';
import * as annonUserAPI from 'shared/apis/annon/user.api';
import * as annonWebAPI from 'shared/apis/annon/web.api';
import { setCookie, getCookie, deleteCookie } from 'shared/utils/cookie';
import storage from 'shared/utils/storage';

/**
 * ERROR HANDLER ( 공통 )
 */

export const errorHandler = (error, action, fn) => {
  console.log(`catchError!!`, action, error.status, error.message, error);

  if (
    error.message === 'ajax timeout' ||
    !error.status ||
    error.status === 0 ||
    error.status === 401 ||
    error.status === 405 ||
    error.status === 500
  ) {
    // if (error.status !== 200 || (error.code && error.code !== 200)) {
    const accessToken = getCookie('dr_access_token');
    const refreshToken = getCookie('dr_refresh_token');
    const body = `${
      error && error.request && error.request.body ? error.request.body : ''
    }`;

    let refreshRequest = body
      ? `${body}`.search('grant_type=refresh_token')
      : -1;

    let isRefreshRequest =
      typeof refreshRequest === 'number' && refreshRequest >= 0;
    console.log({ body }, refreshRequest, isRefreshRequest);

    if (accessToken && refreshToken) {
      deleteCookie('dr_access_token');
    } else if (!accessToken && refreshToken && !isRefreshRequest) {
      deleteCookie('dr_access_token');
    } else if (!accessToken && refreshToken && isRefreshRequest) {
      deleteCookie('dr_access_token');
      deleteCookie('dr_refresh_token');
      deleteCookie('dr_expires_in');
    }
    console.log({ refreshToken, isRefreshRequest });
    if (refreshToken && !isRefreshRequest) {
      console.log(`if (refreshToken && !isRefreshRequest)`, {
        refreshToken,
        isRefreshRequest,
      });

      return of({
        type: auth.POST_REFRESH_TOKEN_REQUEST,
        payload: refreshToken,
      });
    } else if (isRefreshRequest) {
      // token refresh 중 40x / 50x 에러가 발생한 경우
      return of({
        type: auth.GET_ANNON_INHERENCE_KEY_REQUEST,
      });
    }
  }

  if (typeof fn === 'function') {
    return fn(error);
  }
  return EMPTY;
};

// 마케팅 수신에 동의했거나, 다시 물어봤는데 동의했든 거절했든, 여튼 사용자의 의사 표시가 끝남-에 대한 임시 액션
const MARKETING_CHECKED = '@auth-temp/MARKETING_CHECKED';
// 휴태폰 인증 및 기기연결댓수 초과 등의 함정에 빠지지 않고 어쨋거나 완료된 액션
const AUTH_CHECKED_FINALLY = '@auth-temp/AUTH_CHECKED_FINALLY';

// GET_AUTH_CHECK_FAILURE
const getAuthCheckFailureEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_AUTH_CHECK_FAILURE),
    map(action => {
      console.log({ action });
      if (action && action.error && action.error.code) {
        if (action.error.code < 500) {
          window.location.reload();
        }
      } else if (action.payload.code === 6001) {
        console.log({ FAILURE: action });
      }
    }),
  );

/**
 * 로그아웃
 */
const logoutEpic = action$ =>
  action$.pipe(
    ofType(auth.LOGOUT),
    mergeMap(action =>
      userAPI.postLogout$().pipe(
        map(response => {
          setCookie('dr_access_token', null, -1);
          setCookie('dr_refresh_token', null, -1);
          setCookie('dr_expires_in', null, -1);

          window.dataLayer.push({
            event: 'logout',
          });

          let path = '/';
          if (action.payload && action.payload.returnUrl) {
            path = action.payload.returnUrl;
          }
          // window.location.href = path;
          return auth.logoutSuccess(path);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const logoutSuccessEpic = action$ =>
  action$.pipe(
    ofType(auth.LOGOUT_SUCCESS),
    delay(500),
    map(action => {
      console.log({ action });
      window.location.href = action.payload ? action.payload : '/';
      return { type: '' };
      // { type: auth.SET_LOGIN_DATA, payload: { error: false } }
    }),
  );

/**
 * GUEST 토큰 - 발급
 */
const getAnnonInherenceKeyRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_ANNON_INHERENCE_KEY_REQUEST),
    mergeMap(action =>
      annonWebAPI.getInherenceKey$().pipe(
        map(response => {
          console.log(`getAnnonInherenceKeyRequestEpic `, response);
          if (response.code !== 200) return;
          if (!response.result) return;
          setCookie('dr_inherence_key', response.result, 36500);

          return auth.postAuthTokenRequest(
            {
              ...auth.guestTokenData,
              username: response.result,
            },
            action.callback,
          );
        }),
        catchError(error =>
          errorHandler(error, action, e => {
            console.log(`########### CATCH`, e);
            const errorCode = `${error.status}`.split('');
            if (errorCode[0] === '4') {
              console.log('Invalid request', errorCode[0]);
              return of({
                type: auth.SET_LOGIN_ERROR,
                payload: { status: true },
              });
            }
          }),
        ),
      ),
    ),
  );

/**
 * 인증토큰 - 발급
 */
const postAuthTokenRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_AUTH_TOKEN_REQUEST),
    mergeMap(action =>
      authAPI
        .postAuthToken$('password', {
          username: action.payload.username, // 'jd@qualson.com',
          password: action.payload.password, // 'password',
        })
        .pipe(
          map(response => {
            // console.log(`postAuthTokenRequestEpic `, response);
            if (response.code !== 200) return;

            if (response.result && response.result.error) {
              return errorHandler(response, action);
            }

            // debugger;
            const { access_token, refresh_token, expires_in } = response.result;
            return auth.getAuthCheckRequest(action.callback, null, {
              access_token,
              refresh_token,
              expires_in,
            });
          }),
          catchError(error => {
            const errorCode = `${error.status}`.split('');
            if (errorCode[0] === '4') {
              console.log('Invalid request', errorCode[0], error);

              if (
                error.response &&
                error.response.result &&
                error.response.result.error_description
              ) {
                console.log({ result: error.response.result });
                return of({
                  type: auth.SET_LOGIN_ERROR,
                  payload: {
                    status: true,
                    type: error.response.result.error_description.toLowerCase(),
                  },
                });
              }

              // return of({
              //   type: auth.SET_LOGIN_ERROR,
              //   payload: { status: true },
              // });
            }

            return errorHandler(error, action, e => {
              console.log(`########### CATCH`, e);
              const errorCode = `${error.status}`.split('');

              if (errorCode[0] === '4') {
                console.log('Invalid request', errorCode[0]);
                if (e.response && e.response.result) {
                  console.log({ result: e.response.result });
                  // return auth.getAnnonInherenceKeyRequest();
                  return of({ type: auth.GET_ANNON_INHERENCE_KEY_REQUEST });
                  // return of({
                  //   type: auth.SET_LOGIN_ERROR,
                  //   payload: {
                  //     status: true,
                  //     type: e.response.result.error_description.toLowerCase(),
                  //   },
                  // });
                }
                return of({
                  type: auth.SET_LOGIN_ERROR,
                  payload: { status: true },
                });
              }
            });
          }),
        ),
    ),
  );

/**
 * 인증토큰 - 발급 - 실패
 */
// const setLoginErrorEpic = action$ =>
//   action$.pipe(
//     ofType(auth.SET_LOGIN_ERROR),
//     delay(600),
//     mapTo({ type: auth.SET_LOGIN_DATA, payload: { error: false } }),
//   );

/**
 * 인증토큰 - 재발급
 */
const postRefreshTokenRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_REFRESH_TOKEN_REQUEST),
    mergeMap(action =>
      authAPI
        .postAuthToken$('refresh_token', {
          refresh_token: action.payload,
        })
        .pipe(
          map(response => {
            console.log(`postRefreshTokenRequestEpic `, response);
            if (response.code !== 200 || response.result.error) {
              deleteCookie('dr_access_token');
              deleteCookie('dr_refresh_token');
              deleteCookie('dr_expires_in');

              return auth.getAnnonInherenceKeyRequest();
            }

            const { access_token, refresh_token, expires_in } = response.result;
            console.log({ access_token, refresh_token, expires_in });
            // setCookie('dr_access_token', access_token, expires_in * 1000, 'ms');
            // setCookie(
            //   'dr_refresh_token',
            //   refresh_token,
            //   expires_in * 1000,
            //   'ms',
            // );

            return auth.getAuthCheckRequest(null, null, {
              access_token,
              refresh_token,
              expires_in,
            });
          }),

          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

const getUserDeviceEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_USER_DEVICE),
    mergeMap(action =>
      userAPI.getUserDevice$(action.token?.access_token).pipe(
        map(response => {
          console.log(`getUserDeviceEpic`, response, action);
          return {
            type: modal.PUSH_MODAL,
            payload: {
              type: 'DeviceList',
              data: {
                isController: action.isController,
                list: response.result,
                token: action.token,
              },
            },
          };
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const postUserDeviceRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_USER_DEVICE_REQUEST),
    mergeMap(action =>
      userAPI
        .postUserDevice$(
          action.token.access_token,
          action.token.refresh_token,
          action.id,
        )
        .pipe(
          map(response => {
            console.log(`postUserDeviceRequestEpic`, response, action);
            if (response.code !== 200)
              return auth.postUserDeviceFailure(response, action);

            console.log({ token: action.token });
            return auth.postUserDeviceSuccess(response, action);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

const postUserDeviceSuccessEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_USER_DEVICE_SUCCESS),
    map(action => auth.getAuthCheckRequest(null, null, action.action.token)),
  );

const deleteUserDeviceRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.DELETE_USER_DEVICE_REQUEST),
    mergeMap(action =>
      userAPI.deleteUserDevice$(action.token.access_token, action.id).pipe(
        map(response => {
          console.log(`deleteUserDeviceRequestEpic`, response, action);
          if (response.code !== 200)
            return auth.deleteUserDeviceFailure(response, action);

          return auth.deleteUserDeviceSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

/**
 * 가장 처음 사용자 정보 요청을 한다.
 */
const getAuthCheckRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_AUTH_CHECK_REQUEST),
    mergeMap(action =>
      authAPI.getAuthCheck$(action).pipe(
        map(response => {
          // console.log(`getAuthCheckRequestEpic`, response);
          if (response.code === 6251) {
            return auth.getUserDevice(action.token);
          }

          /**
           * 등록된 회원이 아니면 브라우저 고유키값을 발급한다.
           */
          if (response.code === 6001) {
            console.log('ㅉㅉㅉㅉㅉㅉㅉㅈ');
            // deleteCookie('dr_access_token');
            // deleteCookie('dr_refresh_token');
            return auth.getAnnonInherenceKeyRequest();
            // return auth.getAuthCheckFailure(response, action.token);
          }

          if (response.code !== 200 && response.code !== 6103) {
            deleteCookie('dr_access_token');
            deleteCookie('dr_refresh_token');

            return auth.getAuthCheckFailure(response, action.token);
          }
          return auth.getAuthCheckSuccess(
            response,
            action.callback,
            action.inherence_key,
            action.token,
          );
        }),
        takeUntil(action$.pipe(ofType(auth.GET_AUTH_CHECK_CANCELLED))),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getAuthCheckSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(auth.GET_AUTH_CHECK_SUCCESS),
    mergeMap(action => {
      // console.log('getAuthCheckSuccessEpic!!!!!', action.payload);
      const { access_token, refresh_token, expires_in } = action.token;

      const accessToken = getCookie('dr_access_token');
      const isLogin = access_token === accessToken;

      if (accessToken && !isLogin) {
        deleteCookie('dr_access_token');
        deleteCookie('dr_refresh_token');
        deleteCookie('dr_expires_in');
      }

      if (action.payload.code === 6103) {
        if (action.inherence_key) {
          return of({
            type: auth.POST_AUTH_TOKEN_REQUEST,
            payload: {
              ...auth.guestTokenData,
              username: action.inherence_key,
            },
          });
        }

        return of({
          type: modal.PUSH_MODAL,
          payload: {
            type: 'VertifyPhone',
            data: action.token,
          },
        });
      }

      if (access_token && expires_in) {
        setCookie('dr_access_token', access_token, expires_in * 1000, 'ms');
      }
      if (refresh_token && expires_in) {
        setCookie(
          'dr_refresh_token',
          refresh_token,
          (expires_in + 86400) * 1000,
          'ms',
        );
      }
      if (expires_in) {
        setCookie('dr_expires_in', expires_in, expires_in * 1000, 'ms');
      }

      // console.log({ loginCallback: window.loginCallback });
      if (window.loginCallback && typeof window.loginCallback === 'function') {
        window.loginCallback();
        delete window.loginCallback;
      } else if (typeof action.callback === 'function') {
        // console.log(`getAuthCheckSuccessEpic callback`, action.callback);
        action.callback();
      }

      const userEmail = action.payload.result.username;
      if (userEmail.match(/test_\d+@/) && window.appboy) {
        // 이메일 등록. 테스터 그룹에 포함시키기 위한 작업.
        // Braze는 유저를 구분할 수 있는 이메일/번호 대신 uuid 같은 키를 넣기를 권장하고 있다.
        userEmail && window.appboy.changeUser(userEmail);
        // 쿠폰을 받을 수 있는 유저라는 이벤트를 Braze에 날린다
        window.appboy.logCustomEvent('test_user_enableCoupon');
      }

      // vertifyPhone이나 기기연결댓수 초과 같은 함정에 빠지지 않고
      // 모든 체크를 끝냈다!
      // 로그인 이벤트는 유저 정보 확인까지 끝낸 다음에 진행한다.
      const { type, userId } = action.payload.result;
      if (window.dataLayer && type === 'USER') {
        // braze 이벤트도 로그인 이벤트를 사용한다.
        window.dataLayer.push({
          event: 'login',
          location: window.gtm.location,
          userId,
        });
      }

      return of({ type: AUTH_CHECKED_FINALLY });
      // let ofData = {
      //   type: auth.GET_AUTH_CHECK_AFTER_CALLBACK,
      //   payload: { ...action },
      // };

      // return of(ofData);
    }),
  );

//GET_AUTH_CHECK_AFTER_CALLBACK
// const getAuthCheckAfterCallbackEpic = action$ =>
//   action$.pipe(
//     ofType(auth.GET_AUTH_CHECK_AFTER_CALLBACK),
//     map(action => {
//       // const { access_token, refresh_token, expires_in } = action.token;
//       // if (access_token && expires_in) {
//       //   setCookie('dr_access_token', access_token, expires_in * 1000, 'ms');
//       // }
//       // if (refresh_token && expires_in) {
//       //   setCookie(
//       //     'dr_refresh_token',
//       //     refresh_token,
//       //     (expires_in + 86400) * 1000,
//       //     'ms',
//       //   );
//       // }
//       // if (expires_in) {
//       //   setCookie('dr_expires_in', expires_in, expires_in * 1000, 'ms');
//       // }
//       // // challenge.getChallengeStatusRequset();
//       // console.log('GET_AUTH_CHECK_AFTER_CALLBACK', action);

//       return challenge.getChallengeStatusRequset();
//       // return of({
//       //   type: challenge.GET_CHALLENGE_STATUS_REQUEST,
//       //   payload: {},
//       // });
//     }),
//   );

/**
 * 사용자 학습 정보 - 최근 학습 리스트
 */
const getLatestStudyListRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_LATEST_STUDY_LIST_REQUEST),
    mergeMap(action =>
      mediaUserAPI.getUserStudyList$().pipe(
        map(response => {
          console.log(`getLatestStudyListRequestEpic`, response);
          if (response.code !== 200) return;
          return auth.getLatestStudyListSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

/**
 * 사용자 학습 정보 - 담은 가사
 */
const getUserScriptRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_USER_SCRIPT_REQUEST),
    mergeMap(action =>
      userScriptAPI.getUserScript$().pipe(
        map(response => {
          console.log(`getUserScriptRequestEpic`, response);
          if (response.code !== 200) return;
          return auth.getUserScriptSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

/**
 * 사용자 학습 정보 - 담은 가사 제거
 */
const deleteUserScriptRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.DELETE_USER_SCRIPT_REQUEST),
    mergeMap(action =>
      scriptAPI
        .cancelLikeLyric$({
          userScriptId: { mediaScriptId: action.payload, userId: 9999 },
        })
        .pipe(
          map(response => {
            console.log(`deleteUserScriptRequestEpic`, response);
            if (response.code !== 200) return auth.deleteUserScriptFailure();
            return auth.deleteUserScriptSuccess(response);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

/**
 * 사용자 학습 정보 - 가사에 메모 저장
 */
const postUserScriptMemoRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_USER_SCRIPT_MEMO_REQUEST),
    mergeMap(action =>
      mediaUserAPI
        .postUserStudyScriptMemo$({
          note: action.note,
          mediaScriptId: action.mediaScriptId,
        })
        .pipe(
          map(response => {
            console.log(`postUserScriptMemoRequestEpic`, response);
            if (response.code !== 200) return auth.postUserScriptMemoFailure();
            return auth.postUserScriptMemoSuccess(action);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

const postUserScriptMemoSuccessEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_USER_SCRIPT_MEMO_SUCCESS),
    mapTo(toast.pushToastApp({ message: '메모 저장 완료' })),
  );

/**
 * 사용자 학습 정보 - 담은 단어
 */
const getUserDictionaryRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_USER_DICTIONARY_REQUEST),
    mergeMap(action =>
      userDictionaryAPI.getUserWordList$().pipe(
        map(response => {
          console.log(`getUserDictionaryRequestEpic`, response);
          if (response.code !== 200) return;
          return auth.getUserDictionarySuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

/**
 * 사용자 학습 정보 - 담은 단어 제거
 */
const deleteUserDictionaryRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.DELETE_USER_DICTIONARY_REQUEST),
    mergeMap(action =>
      userDictionaryAPI
        .cancelWord$({
          mediaUpc: action.payload.mediaUpc,
          userDictionaryId: {
            ...action.payload.userDictionaryId,
            userId: '9999',
          },
        })
        .pipe(
          map(response => {
            console.log(`deleteUserDictionaryRequestEpic`, response);
            if (response.code !== 200)
              return auth.deleteUserDictionaryFailure();
            return auth.deleteUserDictionarySuccess(response);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

/**
 * 회원가입
 */
const postRegisterRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_REGISTER_REQUEST),
    mergeMap(action =>
      userAPI.postRegister$(action.payload).pipe(
        mergeMap(response => {
          console.log(`postRegisterRequestEpic`, response, action);
          if (response.code !== 200)
            return of(auth.postAuthTokenFailure(response));

          window.dataLayer.push({
            event: 'sign_up',
            location: window.gtm.location,
            email: action.payload.username,
          });

          return of(
            auth.postRegisterSuccess(action.payload),
            auth.postAuthTokenRequest({
              username: action.payload.username,
              password: action.payload.password,
            }),
          );
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

// 회원가입 - 이메일 중복체크
const getCheckEmailRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_CHECK_EMAIL_REQUEST),
    mergeMap(action =>
      userAPI.getCheckUserEmail$(action.payload).pipe(
        map(response => {
          console.log(`getCheckEmailRequestEpic`, response);
          if (response.code !== 200) return auth.getCheckEmailFailure(response);
          return auth.getCheckEmailSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

// 회원가입 - 닉네임 중복체크
const getCheckNicknameRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_CHECK_NICKNAME_REQUEST),
    mergeMap(action =>
      annonUserAPI.getCheckUserNickname$(action.payload).pipe(
        map(response => {
          console.log(`getCheckNicknameRequestEpic`, response);
          if (response.code !== 200)
            return auth.getCheckNicknameFailure(response);
          return auth.getCheckNicknameSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

// 회원가입 - 핸드폰 인증번호 - 발급
const getSendPhoneAuthRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_SEND_PHONE_AUTH_REQUEST),
    mergeMap(action =>
      annonUserAPI.getSendPhoneAuth$(action.payload).pipe(
        map(response => {
          console.log(`getSendPhoneAuthRequestEpic`, response, action.payload);
          if (response.code !== 200)
            return auth.getSendPhoneAuthFailure({
              ...response,
              ...action.payload,
            });
          return auth.getSendPhoneAuthSuccess({
            ...response,
            ...action.payload,
          });
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

// 회원가입 - 핸드폰 인증번호 - 검증
const getVerifyCodeRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_VERIFY_CODE_REQUEST),
    mergeMap(action =>
      annonUserAPI
        .verifyCodeUserEmail$({
          phoneCode: action.payload.phoneCode,
          phone: action.payload.phone,
        })
        .pipe(
          map(response => {
            console.log(`getVerifyCodeRequestEpic`, response);
            if (response.code !== 200 || !response.result.verify)
              return auth.getVerifyCodeFailure({
                ...response,
                ...action.payload,
              });

            return auth.getVerifyCodeSuccess({
              ...response,
              ...action.payload,
            });
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

// 이메일 찾기 - 핸드폰 인증번호 - 검증
const getVerifyCodeInFindEmailRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_VERIFY_CODE_FIND_EMAIL_REQUEST),
    mergeMap(action =>
      annonUserAPI
        .verifyCodeUserEmail$({
          phoneCode: action.payload.phoneCode,
          phone: action.payload.phone,
        })
        .pipe(
          map(response => {
            console.log(`getVerifyCodeInFindEmailRequestEpic`, response);
            if (response.code !== 200 || !response.result.verify) {
              return auth.getVerifyCodeInFindEmailFailure({
                ...response,
                ...action.payload,
              });
            }

            console.log(`######### ${action.payload.phone}`);

            return auth.getFindPasswordRequest(action.payload.phone);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

/**
 * 아이디,비밀번호 찾기
 */
// 아이디,비밀번호 찾기 - 이메일 찾기
const getFindEmailRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_FIND_EMAIL_REQUEST),
    mergeMap(action =>
      annonUserAPI.getFindEmail$(action.payload).pipe(
        map(response => {
          console.log(`getFindEmailRequestEpic`, response.code, response);
          if (response.code !== 200) auth.getFindEmailFailure(response);
          return auth.getFindEmailSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

// 아이디,비밀번호 찾기 - 임시 비밀번호 발급
const getFindPasswordRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_FIND_PASSWORD_REQUEST),
    mergeMap(action =>
      annonUserAPI.getFindPassword$(action.payload).pipe(
        map(response => {
          console.log(`getFindPasswordRequestEpic`, response);
          if (response.code !== 200) auth.getFindPasswordFailure(response);
          return auth.getFindPasswordSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );
const getFindPasswordSuccessEpic = action$ =>
  action$.pipe(
    ofType(auth.GET_FIND_PASSWORD_SUCCESS),
    mergeMap(action => {
      if (action.payload.result === 'SUCCESS') {
        // return of(modal.pushModal({ type: 'Login' }));
      }
    }),
  );

// 상담신청 등록
const postTelemarketingRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_TELEMARKETING_REQUEST),
    mergeMap(action =>
      userAPI
        .postTelemarketing$(
          action.payload,
          getCookie('dr_access_token') ? false : true,
        )
        .pipe(
          map(
            response => {
              if (response.code !== 200) {
                return auth.postTelemarketingFailure(response, action.callback);
              }

              console.log();

              // const phone_number_hash = 'wsgdhjwbdjwsdn'; // await digestMessage(action.payload.phone);
              const dataLayerItem = {
                event: 'generate_lead',
                location: window.gtm.location,
                phone_number: action.payload.phone_hash,
              };

              // console.log({ dataLayer: window.dataLayer });
              window.dataLayer.push(dataLayerItem);

              return auth.postTelemarketingSuccess(response, action.callback);
            },
            // {
            // console.log(`postTelemarketingRequestEpic`, { response, action });
            // if (typeof action.callback === 'function') {
            //   action.callback(response);
            // }
            // if (response.code !== 200) {
            //   return auth.postTelemarketingFailure(response, action.callback);
            // } else {
            //   // const phone_number = 'wsgdhjwbdjwsdn'; // await digestMessage(action.payload.phone);
            //   // const dataLayerItem = {
            //   //   event: 'generate_lead',
            //   //   location: window.gtm.location,
            //   //   phone_number: phone_number,
            //   // };
            //   // console.log(dataLayerItem.event, { dataLayerItem }, phone_number);
            //   // console.log({ dataLayer: window.dataLayer });
            //   // window.dataLayer.push(dataLayerItem);

            //   console.log('hello');

            //   const data = {
            //     type: auth.POST_TELEMARKETING_SUCCESS,
            //     payload: response,
            //     callback: action.callback,
            //   };
            //   console.log({ data });

            //   return of({ data });

            //   // return auth.postTelemarketingSuccess(response, action.callback); // TODO 토스트 알림주
            // }
            // }
          ),
          catchError(error => {
            if (typeof action.callback === 'function') {
              action.callback(error);
            }
            return errorHandler(error, action);
          }),
        ),
    ),
  );

const postTelemarketingSuccessEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_TELEMARKETING_SUCCESS),
    map(action => {
      console.log('@@@@@@@', action);
      let message = '상담신청이 완료되었습니다.';
      if (typeof action.callback === 'string') {
        message = action.callback;
      }

      return toast.pushToastApp({
        message,
      });
    }),
  );

const postTelemarketingSuccess2Epic = action$ =>
  action$.pipe(
    ofType(auth.POST_TELEMARKETING_SUCCESS),
    map(() => modal.initModal()),
  );

const postTelemarketingFailureEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_TELEMARKETING_FAILURE),
    mergeMap(action => {
      console.log(`postTelemarketingFailureEpic`, action);
      let message = '';
      if (action.payload.code === 200) {
        message = '상담신청 접수 완료';
      } else if (action.payload.code === 6200) {
        message = `신청 실패 (${action.payload.message})`;
      } else if (action.payload.message) {
        message = `신청 실패 (${action.payload.message})`;
      } else {
        message = '상담신청 접수 실패';
      }

      console.error({ payload: action.payload, message });

      return of({
        type: toast.PUSH_TOAST_APP,
        payload: {
          message,
          index: 0,
        },
      });
    }),
  );

// const postTelemarketingFailure2Epic = action$ =>
//   action$.pipe(
//     ofType(auth.POST_TELEMARKETING_FAILURE),
//     map(action => {
//       console.log(`postTelemarketingFailure2Epic`, action);
//       if (action.payload.code === 200) {
//         return modal.initModal();
//       }

//       return of({ type: '' });
//     }),
//   );
// PUSH_TOAST_APP

// 내 번호로 등록
const postPhoneNumberRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_PHONE_NUMBER_REQUEST),
    mergeMap(action =>
      userAPI.postPhone$(action.payload, action.token).pipe(
        // payload = { name, phone }
        map(response => {
          console.log(`postPhoneNumberRequestEpic`, response);

          if (response.code === 6251) {
            return auth.getUserDevice(action.token);
          }

          if (typeof action.callback === 'function') {
            action.callback(response);
          }

          if (
            response.code !== 200 ||
            response.result !== 'SUCCESS' ||
            !action.token
          )
            return auth.postPhoneNumberFailure(
              { phone: action.payload, ...response },
              action.callback,
            );

          return auth.postPhoneNumberSuccess(
            response,
            action.token,
            action.callback,
          );
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const postPhoneNumberSuccessEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_PHONE_NUMBER_SUCCESS),
    map(action => auth.getAuthCheckRequest(null, null, action.token)),
  );

// 유저 프로필 저장
const postUserProfileRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_USER_PROFILE_REQUEST),
    mergeMap(action =>
      userAPI.postProfile$(action.payload, action.file).pipe(
        // payload = { name, phone }
        map(response => {
          const access_token = getCookie('dr_access_token');
          const refresh_token = getCookie('dr_refresh_token');
          const expires_in = getCookie('dr_expires_in');
          const inherence_key = getCookie('dr_inherence_key');

          const token = {
            access_token,
            refresh_token,
            expires_in,
          };
          console.log(`postUserProfileRequestEpic`, response);
          if (typeof action.callback === 'function') {
            action.callback(response);
          }
          if (response.code !== 200)
            return auth.postUserProfileFailure(response, action);

          return auth.getAuthCheckRequest(null, inherence_key, token);
          // return auth.postUserProfileSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );
// 유저 비밀번호 변경
const postUserPasswordRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_USER_PASSWORD_REQUEST),
    mergeMap(action =>
      userAPI.postPassword$(action.oldPassword, action.newPassword).pipe(
        map(response => {
          const access_token = getCookie('dr_access_token');
          const refresh_token = getCookie('dr_refresh_token');
          const expires_in = getCookie('dr_expires_in');
          const inherence_key = getCookie('dr_inherence_key');
          // debugger;
          const token = {
            access_token,
            refresh_token,
            expires_in,
          };

          console.log(`postUserPasswordRequestEpic`, response);
          if (typeof action.callback === 'function') {
            action.callback(response);
          }
          if (response.code !== 200)
            return auth.postUserPasswordFailure(response, action);

          return auth.getAuthCheckRequest(null, inherence_key, token);
          // return auth.postUserPasswordSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );
// 유저 마케팅정보 변경
const postMarketingRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_MARKETING_REQUEST),
    mergeMap(action =>
      userAPI.postMarketing$(action.agree).pipe(
        map(response => {
          const access_token = getCookie('dr_access_token');
          const refresh_token = getCookie('dr_refresh_token');
          const expires_in = getCookie('dr_expires_in');
          const inherence_key = getCookie('dr_inherence_key');

          const token = {
            access_token,
            refresh_token,
            expires_in,
          };
          console.log(`postMarketingRequestEpic`, response);
          if (typeof action.callback === 'function') {
            action.callback(response);
          }
          if (response.code !== 200)
            return auth.postMarketingFailure(response, action);

          return auth.getAuthCheckRequest(null, inherence_key, token);
          // return auth.postMarketingSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

// 회원 탈퇴
const deleteUserRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.DELETE_USER_REQUEST),
    mergeMap(action =>
      userAPI.deleteUser$().pipe(
        map(response => {
          console.log(`deleteUserRequestEpic`, response);

          if (response.code !== 200)
            return auth.deleteUserFailure(response, action);

          deleteCookie('dr_access_token');
          deleteCookie('dr_refresh_token');
          deleteCookie('dr_expires_in');
          deleteCookie('dr_inherence_key');

          return (window.location.href = '/');
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const postUserLectureSwitchRequestEpic = action$ =>
  action$.pipe(
    ofType(auth.POST_USER_LECTURE_SWITCH_REQUEST),
    mergeMap(action =>
      userAPI.lectureSwitch$(action.payload).pipe(
        map(response => {
          console.log('postUserLectureSwitchRequest', response);
          if (response.code !== 200)
            return auth.postUserLectureSwitchFailure(response, action);

          if (action.authRequest) {
            const access_token = getCookie('dr_access_token');
            const refresh_token = getCookie('dr_refresh_token');
            const expires_in = getCookie('dr_expires_in');
            const inherence_key = getCookie('dr_inherence_key');

            const token = {
              access_token,
              refresh_token,
              expires_in,
            };
            return auth.getAuthCheckRequest(null, inherence_key, token);
          }

          return auth.postUserLectureSwitchSuccess(response, action);
        }),
      ),
    ),
  );

const afterJoinEpic = action$ =>
  // 가입 성공 이후
  action$.ofType(auth.POST_REGISTER_SUCCESS).pipe(
    map(action => {
      const { marketing } = action.payload;
      storage.set('welcome', true); // 방금 회원가입을 완료한 사용자라는 표시
      storage.set('welcome:marketing', marketing);
      return action;
    }),
    mergeMap(action => {
      // 유저 정보 획득까지 성공한 경우에
      return action$.ofType(auth.GET_AUTH_CHECK_SUCCESS).pipe(
        take(1),
        mergeMap(() => askAgainMarketingAgreeEpic(action$)),
      );
    }),
  );
const askAgainMarketingAgreeEpic = action$ => {
  if (storage.get('welcome:marketing') === 'false') {
    return merge(
      // 1. 마케팅 수신동의 팝업을 띄운다. 동시에
      of(modal.pushModal({ type: 'MarketingAgreeContainer' })),
      // 2. 마케팅 수신 동의 팝업에서 유저가 결정 하기를 기다린다.
      action$.pipe(
        // 동의나 동의하지 않음 선택. 이 팝업은 따로 닫는 버튼도 없앴다.
        ofType(auth.POST_MARKETING_REQUEST, auth.POST_MARKETING_FAILURE),
        // 한 번만 확인할거다.
        take(1),
        // 마케팅 수신동의에 대해 한번 더 물어봤다.
        map(() => {
          storage.del('welcome:marketing');
          return { type: MARKETING_CHECKED };
        }),
      ),
    );
  }
  // 이미 동의했다면, 넘어가자
  return of({ type: MARKETING_CHECKED });
};

/**
 * 로그인 이후를 체크하는 로직이지만,
 * 실질적으로 회원가입 -> 로그인이 연달아 실행되므로
 * 회원가입 직후에도 동작한다.
 * 중복동작하지 않게 조심하자
 */
const afterLoginEpic = action$ =>
  // 로그인 이후
  action$.ofType(auth.POST_AUTH_TOKEN_REQUEST).pipe(
    switchMap(() => {
      // 회원가입 직후 로그인이냐, 그냥 로그인이냐에 따라 분기가 필요하다
      const ACTION_TO_CHECK =
        storage.get('welcome') === 'true'
          ? MARKETING_CHECKED
          : AUTH_CHECKED_FINALLY;
      // 확인 했으니 지워버린다. 로그인에 실패했다거나, 도중에 창을 닫아 괜히 로컬스토리지에 남아있으면
      // 다음 방문때 영향을 미친다
      storage.del('welcome');

      // 유저 정보 획득 / 마케팅 체크까지 성공한 경우에
      return action$.ofType(ACTION_TO_CHECK).pipe(
        // 쿠폰 발급 시도
        mergeMap(() => {
          const couponCode = storage.get('welcome:coupon');
          const action = getCouponIfAvailable(couponCode);
          storage.del('welcome:coupon');
          return action;
        }),
      );
    }),
  );

function getCouponIfAvailable(couponCode) {
  if (couponCode) {
    return of(coupon.postCoupon(couponCode));
  } else {
    return EMPTY;
  }
}

export default combineEpics(
  getUserDeviceEpic,
  postUserDeviceRequestEpic,
  deleteUserDeviceRequestEpic,
  postUserDeviceSuccessEpic,
  // postUserDeviceSuccessEpic,
  // InherenceKey
  getAnnonInherenceKeyRequestEpic,

  // 인증토큰 발급, 재발급
  postAuthTokenRequestEpic,
  postRefreshTokenRequestEpic,
  // 인증토큰 발급 - 실패
  // setLoginErrorEpic,
  // 사용자 정보
  getAuthCheckRequestEpic,
  getAuthCheckSuccessEpic,
  getAuthCheckFailureEpic,
  // getAuthCheckAfterCallbackEpic,
  // 로그아웃
  logoutEpic,
  logoutSuccessEpic,
  // 사용자 학습 정보 - 최근 학습 리스트
  getLatestStudyListRequestEpic,
  // 사용자 학습 정보 - 담은 가사 리스트
  getUserScriptRequestEpic,
  deleteUserScriptRequestEpic,
  postUserScriptMemoRequestEpic,
  postUserScriptMemoSuccessEpic,
  // 사용자 학습 정보 - 담은 단어 리스트
  getUserDictionaryRequestEpic,
  deleteUserDictionaryRequestEpic,
  // 아이디,비밀번호 찾기
  getFindEmailRequestEpic,
  getFindPasswordRequestEpic,
  getFindPasswordSuccessEpic,

  getVerifyCodeInFindEmailRequestEpic,
  // 회원가입
  getCheckEmailRequestEpic,
  getCheckNicknameRequestEpic,
  getSendPhoneAuthRequestEpic,
  getVerifyCodeRequestEpic,
  postRegisterRequestEpic,

  // 상담신청
  postTelemarketingRequestEpic,
  postTelemarketingSuccessEpic,
  postTelemarketingSuccess2Epic,
  postTelemarketingFailureEpic,
  // postTelemarketingFailure2Epic,

  // 내 번호로 등록
  postPhoneNumberRequestEpic,
  postPhoneNumberSuccessEpic,

  // 유저 프로필 저장
  postUserProfileRequestEpic,
  // 유저 비밀번호 변경
  postUserPasswordRequestEpic,
  // 유저 마케팅정보 변경
  postMarketingRequestEpic,
  // 회원 탈퇴
  deleteUserRequestEpic,
  postUserLectureSwitchRequestEpic,

  afterJoinEpic,
  afterLoginEpic,
);
