import { ofType } from 'redux-observable';
import { map, mergeMap, delay } from 'rxjs/operators';
import * as toastpopup from 'shared/actions/toastpopup.action';

const getToastPopupEpic = action$ =>
  action$.pipe(
    ofType(toastpopup.TOAST_POPUP_SHOW),
    delay(3000),
    map(action => {
      return toastpopup.toastPopupReset();
    }),
  );

export default getToastPopupEpic;
