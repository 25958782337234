export const PUSH_TOAST_APP = '@toast/PUSH_TOAST_APP';
export const INIT_TOAST_APP = '@toast/INIT_TOAST_APP';

export let toastIndex = 0;
export const pushToastApp = payload => {
  toastIndex++;
  return {
    type: PUSH_TOAST_APP,
    payload: {
      ...payload,
      index: toastIndex,
    },
  };
};

export const initToastApp = () => ({
  type: INIT_TOAST_APP,
});
