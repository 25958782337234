import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError, mapTo } from 'rxjs/operators';

import * as detail from 'shared/actions/detail.action';
import * as detailAPI from 'shared/apis/media/detail.api';
import * as userPlaylistAPI from 'shared/apis/media/userPlaylist.api';

import { errorHandler } from 'shared/epics/auth.epic';

const getDetailSongRequestEpic = action$ =>
  action$.pipe(
    ofType(detail.GET_DETAIL_SONG_REQUEST),
    mergeMap(action =>
      detailAPI.getDetailSong$(action.payload).pipe(
        map(data => detail.getDetailSongSuccess(data.result)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const saveMyPlaylistRequestEpic = action$ =>
  action$.pipe(
    ofType(detail.SAVE_MY_PLAYLIST_REQUEST),
    mergeMap(action =>
      userPlaylistAPI.postUserPlaylistItem$(action.payload).pipe(
        map(data => detail.saveMyPlaylistSuccess()),
        // catchError(err => of(detail.saveMyPlaylistFailure(err))),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const cancelMyPlaylistRequestEpic = action$ =>
  action$.pipe(
    ofType(detail.CANCEL_MY_PLAYLIST_REQUEST),
    mergeMap(action =>
      userPlaylistAPI.deleteUserPlaylistItem$(action.payload).pipe(
        map(data => detail.cancelMyPlaylistSuccess()),
        // catchError(err => of(detail.cancelMyPlaylistFailure(err))),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const initializeDetailInfoRequestEpic = action$ =>
  action$.pipe(
    ofType(detail.INITIALIZE_DETAIL_INFO_REQUEST),
    mapTo(detail.initializeDetailInfoSuccess()),
  );

export default combineEpics(
  getDetailSongRequestEpic,
  saveMyPlaylistRequestEpic,
  cancelMyPlaylistRequestEpic,
  initializeDetailInfoRequestEpic,
);
