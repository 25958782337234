import { GET, POST, publicAPI } from 'shared/utils/ajax';

const path = `/rest/sell`;

// 패키지 리스트 요청
export const getProductsPackageInfo$ = () => publicAPI.get(`/annon/products`);

// 특정 상품에 대한 정보 요청
export const getProduct$ = id => GET(`/rest/products/${id}`);

// 구매 방법 요청
export const getPurchaseWay$ = () => GET('/rest/purchase/payment-option');

//유저 구매정보 조회
export const getUserPurchase$ = payload => {
  return GET('/rest/purchase', payload);
};

export const getProductsInfo$ = () => GET(`${path}/rest/products`);
export const getOrderId$ = itemId => GET(`/rest/purchase/order/id/${itemId}`);

export const postPurchaseInfo$ = (data = {}) =>
  POST(`${path}/user/purchase`, data);
export const getPurchaseInfo$ = (data = {}) =>
  GET(`${path}/user/purchase`, data);
