import {
  TOAST_POPUP_SHOW,
  TOAST_POPUP_RESET,
} from 'shared/actions/toastpopup.action';

export const initialState = {
  popup: {
    message: '',
    show: false,
    count: 0,
  },
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case TOAST_POPUP_SHOW: {
      return {
        ...state,
        popup: {
          message: payload.message,
          show: true,
          count: state.popup.count + 1,
        },
      };
    }

    case TOAST_POPUP_RESET: {
      return {
        ...state,
        popup: {
          message: '',
          show: false,
          count: state.popup.count,
        },
      };
    }

    default:
      return state;
  }
};
