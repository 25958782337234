import { GET, POST } from 'shared/utils/ajax';

const path = '/rest/media/user';

/**
 * 단어 담기 / 취소 API
 * @param {object}
 * {
 *    mediaUpc: ,
 *    userDictionaryId: {
 *      userId: ,
 *      word:,
 *      searchWord:,
 *    }
 * }
 */
export const getUserStudyList$ = () => GET(`${path}/study`);

export const postUserStudyScriptMemo$ = ({ note, mediaScriptId }) =>
  POST(`${path}/study/memo`, {
    note,
    userScriptId: {
      mediaScriptId: mediaScriptId,
    },
  });
