import { publicAPI, GET, POST } from 'shared/utils/ajax';

const path = '/rest/coupon';

/**
 * 쿠폰 조회
 */
export const getCoupon$ = ({ code = '' }) => {
  return GET(`/rest/coupon/${code}`);
  // return publicAPI.get(`/annon/coupon?couponCode=${code}`);
};

/**
 * 쿠폰 발급
 */
export const postCoupon$ = payload => {
  let pathname = `${path}`;
  // debugger;
  if (payload) {
    pathname += `/${payload}`;
  }
  return POST(pathname);
};

/**
 * 쿠폰 관리 리스트 조회
 */
export const getMyCouponList$ = () => {
  return GET(`${path}/user`);
};

/**
 * 적용 할 쿠폰
 */
export const getApplyCoupon$ = payload => {
  let pathname = `${path}/user-coupon`;

  if (payload) {
    pathname += `?productId=${payload}`;
  }

  return GET(pathname);
};

/**
 * 무료체험 쿠폰 발급
 */
export const getFreeLectureCoupon$ = () => {
  return GET(`/annon/coupons/free-trial-coupon`);
};

/**
 * 결제페이지 쿠폰 리스트
 */
export const getPaymentCouponList$ = payload => {
  let pathname = `${path}/user-coupons`;
  if (pathname) pathname += `?productId=${payload}`;
  return GET(pathname);
};
