const initialState = {
  items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    default:
      return state;
  }
};
