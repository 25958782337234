import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as speak from 'shared/actions/speak.action';
import * as speakAPI from 'shared/apis/media/speak.api';
import * as userMediaScriptSpeakAPI from 'shared/apis/media/userMediaScriptSpeak.api';

import { errorHandler } from 'shared/epics/auth.epic';

const getSpeakRequestEpic = action$ =>
  action$.pipe(
    ofType(speak.GET_SPEAK_REQUEST),
    mergeMap(action =>
      speakAPI.getSpeak$(action.payload).pipe(
        map(data => speak.getSpeakSuccess(data.result)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const saveSpeakStepRequestEpic = action$ =>
  action$.pipe(
    ofType(speak.SAVE_SPEAK_STEP_REQUEST),
    mergeMap(action =>
      userMediaScriptSpeakAPI.saveSpeakStep$(action.payload).pipe(
        map(data => speak.saveSpeakStepSuccess(data.result)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(getSpeakRequestEpic, saveSpeakStepRequestEpic);
