import { VIDEO_PLAY_STATUS } from 'shared/actions/videoplay.action';

export const initialState = {
  playstatus: '',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case VIDEO_PLAY_STATUS: {
      return {
        ...state,
        playstatus: payload,
      };
    }

    default:
      return state;
  }
};
