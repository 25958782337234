import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';

import * as intro from 'shared/actions/intro.action';

import * as introAPI from 'shared/apis/intro.api';
import * as annonAPI from 'shared/apis/annon/annon.api';

const getLectureCurriculumRequestEpic = action$ =>
  action$.pipe(
    ofType(intro.LECTURE_CURRICULUM_REQUEST),
    mergeMap(action =>
      introAPI.getLectureCurriculum$().pipe(
        map(response => {
          console.log(`getLectureCurriculumRequestEpic `, response);
          if (response.code !== 200) {
            return;
          }
          return intro.getLectureCurriculumSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );
const getSearchRequestEpic = action$ =>
  action$.pipe(
    ofType(intro.SEARCH_REQUEST),
    mergeMap(action =>
      introAPI.getSearch$().pipe(
        map(response => {
          if (action.callback) {
            action.callback(response);
          }

          console.log(`getSearchRequestEpic `, response);
          if (response.code !== 200) {
            return;
          }

          return intro.getSearchSuccess(response);
        }),
        catchError(error => {
          if (action.callback) {
            action.callback(error);
          }

          return errorHandler(error, action);
        }),
      ),
    ),
  );
const getTeacherAllRequestEpic = action$ =>
  action$.pipe(
    ofType(intro.TEACHER_ALL_REQUEST),
    mergeMap(action =>
      introAPI.getTeacherAll$().pipe(
        map(response => {
          console.log(`getTeacherAllRequestEpic `, response);
          if (response.code !== 200) {
            return;
          }
          return intro.getTeacherAllSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const postSellingBenefitRequestEpic = action$ =>
  action$.pipe(
    ofType(intro.POST_SELLING_BENEFIT_REQUEST),
    mergeMap(action =>
      annonAPI.getAnnonBenefit$(action.payload).pipe(
        map(response => {
          console.log(`postSellingBenefitRequestEpic `, response);
          if (response.code !== 200) {
            return intro.postSellingBenefitFailure(response);
          }
          return intro.postSellingBenefitSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const getCopyrightRequestEpic = action$ =>
  action$.pipe(
    ofType(intro.GET_COPYRIGHT_REQUEST),
    mergeMap(action =>
      introAPI.getLicense$(action.payload).pipe(
        map(response => {
          console.log('getCopyrightRequest', response);
          if (response.code !== 200)
            return intro.getCopyrightFailure(response, action);

          if (typeof action.callback === 'function') action.callback(response);

          return intro.getCopyrightSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(
  getLectureCurriculumRequestEpic,
  getSearchRequestEpic,
  getTeacherAllRequestEpic,
  postSellingBenefitRequestEpic,

  // 저작권 정보
  getCopyrightRequestEpic,
);
