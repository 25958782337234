export const grade = {
  NONE: {
    title: '비회원',
    grade: 0,
    style: {
      display: 'none',
    },
  },
  FREE: {
    title: '무료회원',
    grade: 1,
    style: {
      backgroundColor: '#fff',
      borderColor: '#a8a1ec',
      color: '#a8a1ec',
    },
  },
  PAID: {
    title: '유료회원',
    grade: 2,
    style: {
      backgroundColor: '#a8a1ec',
      borderColor: '#a8a1ec',
      color: '#fff',
    },
  },
  STANDING: {
    title: '구매대기',
    grade: 3,
    style: {
      backgroundColor: '#fff',
      borderColor: '#a8a1ec',
      color: '#a8a1ec',
    },
  },
};
