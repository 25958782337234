/**
 * React-Router페이지 이동전 변경점 체크
 * 변경이 되었는데 저장이 안되었을경우, 메세지와 콜백함수실행
 * ex(/mystudy/locker/script) 데이터 수정하고 다른페이지로 이동시
 */
const getUserConfirmation = (dialogKey, callback) => {
  // use "message" as Symbol-based key
  const dialogTrigger = window[Symbol.for(dialogKey)];

  if (dialogTrigger) {
    // delegate to dialog and pass callback through
    return dialogTrigger(callback);
  }

  // Fallback to allowing navigation
  callback(true);
};

export default getUserConfirmation;
