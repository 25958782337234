import * as intro from 'shared/actions/intro.action';

export const initialState = {
  lectureCurriculum: [],
  search: {},
  teacherAll: [],
};

let nextData = {};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case intro.LECTURE_CURRICULUM_SUCCESS:
      // console.log({ state, type, payload });
      nextData = { ...state };
      nextData.lectureCurriculum = payload.result;

      return nextData;
    case intro.SEARCH_SUCCESS:
      // console.log({ state, type, payload });
      nextData = { ...state };
      nextData.search = payload.result;

      return nextData;
    case intro.TEACHER_ALL_SUCCESS:
      // console.log({ state, type, payload });
      nextData = { ...state };
      nextData.teacherAll = payload.result;

      return nextData;
    default:
      return state;
  }
};
