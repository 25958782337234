import * as detail from 'shared/actions/detail.action';
import * as lecture from 'shared/actions/lecture.action';

export const initialState = {
  artist: '',
  artistEng: '',
  completeListen: false,
  descritionCount: 0,
  genre: {
    genreList: [],
    genreNameList: [],
  },
  length: 0,
  level: 0,
  levelText: '',
  mediaScriptLecture: {
    lectureList: [],
  },
  mediaScriptListenCount: 0,
  mediaScriptListenText: '',
  mediaScriptListenTotalCount: 0,
  mediaScriptSpeak: [],
  mediaUrl: '',
  mood: {},
  releasedYear: '',
  teacherId: '',
  thumbnail: '',
  title: '',
  titleEng: '',
  upc: '',
  userPlayListChoice: false,
  userSpeakSort: 0,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case lecture.GET_LECTURE_SUCCESS:
      return { ...state, ...payload };
    case detail.INITIALIZE_DETAIL_INFO_SUCCESS:
      return initialState;
    case detail.GET_DETAIL_SONG_SUCCESS:
      return {
        ...state,
        ...payload,
      };
    case detail.SAVE_MY_PLAYLIST_SUCCESS:
      return {
        ...state,
        userPlayListChoice: true,
      };
    case detail.CANCEL_MY_PLAYLIST_SUCCESS:
      return {
        ...state,
        userPlayListChoice: false,
      };
    default:
      return state;
  }
};
