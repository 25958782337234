/**
 *
 */
export const SET_INIT = '@lecture/SET_INIT';

export const setInit = () => ({
  type: SET_INIT,
});

/**
 * 특정 강의 요청api
 * /study/602527773728/lecture/1
 * payload 로 강의 upc를 요청
 */
export const GET_LECTURE_REQUEST = '@lecture/GET_LECTURE_REQUEST';
export const GET_LECTURE_SUCCESS = '@lecture/GET_LECTURE_SUCCESS';
export const GET_LECTURE_FAILURE = '@lecture/GET_LECTURE_FAILURE';

export const getLectureRequest = (payload, step) => ({
  type: GET_LECTURE_REQUEST,
  payload,
  step,
});

export const getLectureSuccess = (payload, step) => ({
  type: GET_LECTURE_SUCCESS,
  payload,
  step,
});

export const getLectureFailure = payload => ({
  type: GET_LECTURE_FAILURE,
  payload,
});

/**
 *
 */
export const SAVE_LECTURE_REQUEST = '@lecture/SAVE_LECTURE_REQUEST';
export const SAVE_LECTURE_SUCCESS = '@lecture/SAVE_LECTURE_SUCCESS';
export const SAVE_LECTURE_FAILURE = '@lecture/SAVE_LECTURE_FAILURE';

export const saveLectureRequest = (payload, upc) => ({
  type: SAVE_LECTURE_REQUEST,
  payload,
  upc,
});

export const saveLectureSuccess = payload => ({
  type: SAVE_LECTURE_SUCCESS,
  payload,
});

export const saveLectureFailure = payload => ({
  type: SAVE_LECTURE_FAILURE,
  payload,
});
