import * as register from 'shared/actions/register.action';

export const initialState = {
  show: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case register.REGISTER_SHOW:
      return {
        ...state,
        show: true,
      };
    case register.REGISTER_HIDE:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};
