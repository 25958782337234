import 'react-app-polyfill/ie9'; // For IE 9 support
import 'shared/utils/polyfill';
import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as Sentry from '@sentry/browser';

import * as serviceWorker from './serviceWorker';

// 전역 CSS Load
import 'shared/styles/_main.scss';
import './index.css';

Sentry.init({
  dsn:
    'https://c54562c6df89446f9e6ecfafabf45984@o350493.ingest.sentry.io/5194355',
});

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
