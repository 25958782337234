import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';
import { setCookie } from 'shared/utils/cookie';

import * as mediaInfo from 'shared/actions/mediaInfo.action';
import * as searchTypeAPI from 'shared/apis/media/searchType.api';
import * as mediaAPI from 'shared/apis/media/index.api';

const getSearchTypeRequestEpic = action$ =>
  action$.pipe(
    ofType(mediaInfo.GET_SEARCH_TYPE_REQUEST),
    mergeMap(action =>
      searchTypeAPI.getSearchType$().pipe(
        map(response => {
          console.log(`getMediaGenreRequestEpic `, response);
          if (response.code !== 200) return;
          return mediaInfo.getSearchTypeSuccess(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );
const getMediaKeyRequestEpic = action$ =>
  action$.pipe(
    ofType(mediaInfo.GET_MEDIA_KEY_REQUEST),
    mergeMap(action =>
      mediaAPI
        .authKey$({
          type: action.payload.type,
          step: action.payload.step,
          mediaUrl: action.payload.mediaUrl,
        })
        .pipe(
          map(response => {
            console.log(`getMediaKeyRequestEpic `, response);
            if (response.code !== 200)
              return mediaInfo.getMediaKeyFailure(response);

            if (response.result) {
              const { keyPairId, policy, signature } = response.result;
              setCookie('CloudFront-Policy', policy, 60000);
              setCookie('CloudFront-Signature', signature, 60000);
              setCookie('CloudFront-Key-Pair-Id', keyPairId, 60000);
            }

            // console.log({ callback: typeof action.callback });
            if (typeof action.callback === 'function') {
              action.callback();
            }
            return mediaInfo.getMediaKeySuccess(response, action.callback);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

const getAnnonMediaKeyRequestEpic = action$ =>
  action$.pipe(
    ofType(mediaInfo.GET_ANNON_MEDIA_KEY_REQUEST),
    mergeMap(action =>
      mediaAPI
        .authKeyAnnon$({
          type: action.payload.type,
          step: action.payload.step,
          mediaUrl: action.payload.mediaUrl,
        })
        .pipe(
          map(response => {
            // console.log(`getMediaKeyRequestEpic `, response);
            if (response.code !== 200)
              return mediaInfo.getAnnonMediaKeyFailure(response);

            if (response.result) {
              const { keyPairId, policy, signature } = response.result;
              setCookie('CloudFront-Policy', policy, 60000);
              setCookie('CloudFront-Signature', signature, 60000);
              setCookie('CloudFront-Key-Pair-Id', keyPairId, 60000);
            }

            if (typeof action.callback === 'function') action.callback();
            return mediaInfo.getAnnonMediaKeySuccess(response, action.callback);
          }),
          catchError(error => errorHandler(error, action)),
        ),
    ),
  );

const getStudyStartRequestEpic = action$ =>
  action$.pipe(
    ofType(mediaInfo.GET_STUDY_START_REQUEST),
    mergeMap(action =>
      mediaAPI.getStudyStart$(action.payload).pipe(
        map(response => {
          console.log(`getStudyStartRequestEpic `, response);
          if (response.code !== 200)
            return mediaInfo.getMediaKeyFailure(response);

          return mediaInfo.getMediaKeySuccess(response, action.callback);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(
  getSearchTypeRequestEpic,
  getMediaKeyRequestEpic,
  getAnnonMediaKeyRequestEpic,
  getStudyStartRequestEpic,
);
