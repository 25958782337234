import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { errorHandler } from 'shared/epics/auth.epic';
import { of, EMPTY } from 'rxjs';

import * as coupon from 'shared/actions/coupon.action';
import * as modal from 'shared/actions/modal.action';
import * as loading from 'shared/actions/loading.action';

import * as couponAPI from 'shared/apis/coupon.api';

/**
 * 쿠폰 조회 후 발급가능한 쿠폰 있으면 팝업 노출
 */
const getCouponRequestEpic = action$ =>
  action$.pipe(
    ofType(coupon.getCoupon.request.type),
    mergeMap(action => {
      // console.log(action, 'COUPON!!!!');
      return couponAPI.getCoupon$(action.payload).pipe(
        map(response => {
          // console.log('getCouponRequestEpic', { response });
          if (action.payload.callback) {
            action.payload.callback(response);
          }
          if (response.code !== 200) {
            return coupon.getCoupon.failure(response);
          }
          return coupon.getCoupon.success(response);
        }),
        catchError(error => errorHandler(error, action)),
      );
    }),
  );

/**
 * 쿠폰 발급
 */
const postCouponRequestEpic = action$ =>
  action$.pipe(
    ofType(coupon.postCoupon.request.type),
    mergeMap(action => {
      // debugger;
      return couponAPI.postCoupon$(action.payload).pipe(
        map(response => {
          // console.log('postCouponRequestEpic', { response });
          if (response.code !== 200) {
            return coupon.postCoupon.failure(response);
          }
          console.log('postCouponRequestSuccess', { response });
          return coupon.postCoupon.success(response);
          // return coupon.postCoupon.success(response);
        }),
        catchError(error => errorHandler(error, action)),
      );
    }),
  );

/**
 * 쿠폰 성공 후 로딩 닫기
 */
const postCouponSuccessEpic = action$ =>
  action$.pipe(
    ofType(coupon.postCoupon.success.type),
    mergeMap(action => {
      const response = action.payload;
      if (response !== null) {
        return of(
          modal.pushModal({
            type: 'CouponSuccessContainer',
            data: response.result,
          }),
          coupon.getMyCoupon(),
          loading.finishLoading(),
        );
      }
      return EMPTY;
    }),
  );

/**
 * 무료체험 쿠폰 조회
 */
const getFreeLectureCouponEpic = action$ =>
  action$.pipe(
    ofType(coupon.getFreeLectureCoupon.request.type),
    mergeMap(action => {
      return couponAPI.getFreeLectureCoupon$(action.payload).pipe(
        map(response => {
          console.log('getFreeLectureCoupon', { response });
          if (response.code !== 200) {
            return coupon.getFreeLectureCoupon.failure(response);
          }
          return coupon.getFreeLectureCoupon.success(response);
        }),
        catchError(error => errorHandler(error)),
      );
    }),
  );

/**
 * 쿠폰 마이 리스트
 */
const getMyCouponRequestEpic = action$ =>
  action$.pipe(
    ofType(coupon.getMyCoupon.request.type),
    mergeMap(action => {
      return couponAPI.getMyCouponList$(action.payload).pipe(
        map(response => {
          console.log('getMyCouponRequestEpic', response.payload);
          if (response.code !== 200) {
            return coupon.getMyCoupon.failure(response);
          }
          return coupon.getMyCoupon.success(response);
        }),
        catchError(error => errorHandler(error, action)),
      );
    }),
  );

/**
 * 적용 가능한 쿠폰 가져오기
 */
const getApplyCouponRequestEpic = action$ =>
  action$.pipe(
    ofType(coupon.getApplyCoupon.request.type),
    mergeMap(action =>
      couponAPI.getApplyCoupon$(action.payload).pipe(
        map(response => {
          if (response.code !== 200) {
            return coupon.getApplyCoupon.failure(response);
          }
          return coupon.getApplyCoupon.success(response);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

/**
 * 무료체험 쿠폰 받기 위한 플레이영상 상태값
 */
// const getCouponVideoPlayStatusEpic = (action$, store) =>
//   action$.pipe(
//     ofType(coupon.getVideoPlayStatusCoupon.request.type),
//     map(action => {
//       const response = action.payload;
//       console.log({ response, store });
//       const status =
//         store?.value?.shared?.coupon?.couponVideoPlayingStatus?.uid;
//       if (
//         status?.zero.length === 4 ||
//         status?.one.length === 4 ||
//         status?.two.length === 4 ||
//         status?.three.length === 4 ||
//         status?.four.length === 4
//       ) {
//         return modal.pushModal({ type: 'goSelling' });
//       }
//       if (!response) {
//         return coupon.getVideoPlayStatusCoupon.failure(response);
//       }
//       return coupon.getVideoPlayStatusCoupon.success(response);
//     }),
//     catchError(error => errorHandler(error)),
//   );

/**
 * 결제페이지 쿠폰 리스트
 */
const getPaymentCouponListEpic = action$ =>
  action$.pipe(
    ofType(coupon.getPaymentCouponList.request.type),
    mergeMap(action => {
      return couponAPI.getPaymentCouponList$(action.payload).pipe(
        map(response => {
          // console.log('getPaymentCouponListEpic', { response });
          if (response.code !== 200) {
            return coupon.getPaymentCouponList.failure(response);
          }
          return coupon.getPaymentCouponList.success(response);
        }),
        catchError(error => errorHandler(error)),
      );
    }),
  );

export default combineEpics(
  getCouponRequestEpic,
  postCouponRequestEpic,
  postCouponSuccessEpic,
  getMyCouponRequestEpic,
  getApplyCouponRequestEpic,
  // getCouponVideoPlayStatusEpic,
  getFreeLectureCouponEpic,
  getPaymentCouponListEpic,
);
