import * as webPlayer from 'shared/actions/webPlayer.action';
// import * as auth from 'shared/actions/auth.action';
import storage from 'shared/utils/storage';

export const initialState = {
  playerId: 'web-player-div',
  playing: false,
  disable: true,
  upc: '',
  title: '',
  artist: '',
  artistEng: '',
  image: '',
  autostart: true,
  mute: storage.get('mute') === 'true' ? true : false,
  // mute: true,
  shuffle: storage.get('shuffle') === 'true' ? true : false,
  repeat: storage.get('repeat') ? +storage.get('repeat') : 0, // { 0: none, 1: single, 2: all, 3: 소절 반복 }
  repeatScript: null,
  volume: storage.get('volume') ? +storage.get('volume') : 100, // range: 1 ~ 100
  timestamp: {
    current: 0,
    duration: 0,
    percent: 0, // max = 100%
  },
  script: {
    upc: '',
    mediaScript: [],
  },
  action: {
    getType: '',
    search: {},
    upc: '',
  },
  mediaScript: [],
  shuffleList: storage.get('shuffleList')
    ? JSON.parse(`[${storage.get('shuffleList')}]`)
    : [],
  playlist: [],
  playItemOption: [],
};

let nextData = {};
let res = null;
export default (state = initialState, { type, payload, action }) => {
  switch (type) {
    case webPlayer.INITIAL_STATE:
      return initialState;

    case webPlayer.GET_MEDIA_PLAYLIST_REQUEST:
      nextData = { ...state };
      nextData.action = payload;

      nextData.playlist = [];
      nextData.playItemOption = [];

      return nextData;
    // GET MEDIA PLAYLIST
    case webPlayer.GET_MEDIA_PLAYLIST_SUCCESS:
      nextData = { ...state };
      nextData.action = action;

      const gmpsr = Array.isArray(payload.result)
        ? payload.result
        : [payload.result];

      if (!gmpsr.length) {
        nextData = { ...initialState };
        nextData.action = action;
        return nextData;
      }

      nextData.playItemOption = [];
      nextData.playlist = gmpsr.map(item => {
        console.log('gmpsr', { item });
        const {
          mediaUrl,
          audioUrl,
          thumbnail,
          userPlayListChoice,
          ...rest
        } = item;

        nextData.playItemOption.push({
          upc: rest.upc,
          userPlayListChoice,
        });

        let script = {
          mediaScript: [],
          upc: '',
        };

        return {
          ...rest,
          file: audioUrl,
          image: thumbnail,
          script,

          withCredentials: true,
        };
      });
      return nextData;

    // SET STATE
    case webPlayer.SET_STATE:
      return {
        ...state,
        ...payload,
      };

    // GET MEDIA SCRIPT
    case webPlayer.GET_MEDIA_SCRIPT_SUCCESS:
      res = payload.result;
      nextData = { ...state };

      nextData.script = {
        upc: res.upc,
        mediaScript: res.mediaScript,
      };

      return nextData;

    // POST_USER_PLAYLIST
    case webPlayer.POST_USER_PLAYLIST_ITEM_REQUEST:
      nextData = { ...state };
      nextData.playItemOption = userPlayListChoiceChanger(
        state.playItemOption,
        payload,
        true,
      );
      return nextData;
    case webPlayer.POST_USER_PLAYLIST_ITEM_FAILURE:
      nextData = { ...state };
      nextData.playItemOption = userPlayListChoiceChanger(
        state.playItemOption,
        payload,
        false,
      );
      return nextData;

    // DELETE_USER_PLAYLIST
    case webPlayer.DELETE_USER_PLAYLIST_ITEM_REQUEST:
      nextData = { ...state };
      nextData.playItemOption = userPlayListChoiceChanger(
        state.playItemOption,
        payload,
        false,
      );

      if (action.isUserPlaylist) {
        // userPlaylist 일 경우
        const deleteItemIndex = nextData.playlist.findIndex(
          item => item.upc === action.upc,
        );

        if (deleteItemIndex >= 0) {
          nextData.playlist.splice(deleteItemIndex, 1);
          nextData.playItemOption.splice(deleteItemIndex, 1);
        }

        if (nextData.playlist.length === 0) {
          nextData = initialState;
          nextData.disable = false;
          // nextData.title = initialState.title;
          // nextData.artist = initialState.artist;
          // nextData.image = initialState.image;
          // nextData.upc = initialState.upc;
          // nextData.action = initialState.action;
        }
      }

      return nextData;
    case webPlayer.DELETE_USER_PLAYLIST_ITEM_FAILURE:
      nextData = { ...state };
      // nextData.playlist = userPlayListChoiceChanger(
      //   state.playlist,
      //   payload,
      //   true,
      // );

      return nextData;

    case webPlayer.POST_USER_MEDIA_PLAY_SUCCESS_GET_PLAYLIST:
      nextData = { ...state };
      nextData.playlist = payload.result;
      return nextData;

    default:
      return state;
  }
};

const userPlayListChoiceChanger = (playlist, upc, bool) => {
  const gmpsr = Array.isArray(playlist) ? playlist : [playlist];
  const res = gmpsr.map(item => {
    const { userPlayListChoice, ...rest } = item;
    if (item.upc === upc) {
      return { ...rest, userPlayListChoice: bool };
    }
    return item;
  });
  return res;
};
