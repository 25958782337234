import { GET } from 'shared/utils/ajax';

const path = '/rest/media/userScript';

/**
 * 단어 담기 / 취소 API
 * @param {object}
 * {
 *    mediaUpc: ,
 *    userDictionaryId: {
 *      userId: ,
 *      word:,
 *      searchWord:,
 *    }
 * }
 */
export const getUserScript$ = () => GET(path);
