import * as practice from 'shared/actions/practice.action';

export const initialState = {
  upc: '',
  artist: '',
  titleEng: '',
  mediaUrl: '',
  scriptIndex: -1,
  stepIndex: -1,
  mediaScript: [],
  complete: [],
  stepSize: 0,
  error: '',
  visible: false,
  purchase: null,
  record: {
    status: 'standby', // ['standby', 'request', "success", "failure"],
    message: '',
    resMsg: '',
    result: {},
  },
};

let nextData = {};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case practice.REVISIT_CHECK:
      nextData = { ...state };
      if (nextData.scriptIndex < 0) return nextData;

      if (nextData.scriptIndex >= nextData.complete.length) {
        nextData.error = '잘못된 접근입니다.';
        return nextData;
      }
      if (!nextData.complete.length) return nextData;
      if (
        nextData.complete[nextData.scriptIndex] &&
        nextData.complete[nextData.scriptIndex].userMediaScriptSpeakPractice
      ) {
        nextData.stepIndex = 0;
      } else {
        nextData.stepIndex = 1;
      }
      return nextData;
    case practice.SET_INIT:
      nextData = { ...initialState };
      return nextData;
    case practice.SET_SCRIPT_INDEX:
      nextData = { ...state };
      nextData.scriptIndex = payload;
      return nextData;
    case practice.SET_NEXT_STEP:
      nextData = { ...state };
      nextData.stepIndex = nextData.stepIndex + 1;
      if (nextData.stepIndex > nextData.mediaScript.length) {
        nextData.stepIndex = nextData.mediaScript.length - 1;
      }
      return nextData;
    case practice.SET_STATE:
      nextData = { ...state, ...payload };
      return nextData;
    case practice.GET_PRACTICE_USER_STATUS_SUCCESS:
      nextData = { ...state };
      if (payload) {
        let complete = payload.map(item => {
          const { userMediaScriptSpeakPractice = {} } = item;
          const { affixSentence = '' } = userMediaScriptSpeakPractice;

          let resData = item;
          if (affixSentence) {
            resData.userMediaScriptSpeakPractice.affixSentence = replaceCoverTag(
              affixSentence,
              /(~|`)[a-zA-Z]*(~|`)/,
              'span',
              'wrong',
            );
          }

          return resData;
        });

        nextData.complete = complete;
      }

      // nextData.record.resMsg =

      return nextData;
    case practice.GET_PRACTICE_SUCCESS:
      nextData = { ...state };
      nextData.mediaScript = payload.mediaScript;
      nextData.upc = payload.upc;
      nextData.artist = payload.artist;
      nextData.titleEng = payload.titleEng;
      nextData.stepSize = payload.mediaScript.length;
      nextData.stepIndex = 1;
      nextData.teacher = payload.teacher;
      nextData.purchase = payload.purchase;

      return nextData;

    case practice.SET_PRACTICE_RECORD_DATA_STANDBY:
      nextData = { ...state };
      nextData.record.status = 'standby';
      nextData.record.message = '';
      nextData.record.result = {};
      return nextData;
    case practice.POST_PRACTICE_RECORD_DATA_REQUEST:
      nextData = { ...state };
      nextData.record.status = 'request';
      nextData.record.message = '';
      nextData.record.result = {};
      nextData.record.resMsg = '';
      return nextData;
    case practice.POST_PRACTICE_RECORD_DATA_FAILURE:
      nextData = { ...state };
      // console.log(`POST_PRACTICE_RECORD_DATA_FAILURE : `, { payload });
      // Error Code = {5251: "오디오 형식이 아닙니다.", 5252: "오디오 길이가 너무 짧습니다.", 5253: "오디오로 인식되지 않습니다." };
      nextData.record.status = 'failure';
      nextData.record.code = payload.code;
      // nextData.record.message = `제가 잘 이해하지 못했어요.\n한 번 더 말해주시겠어요?`;
      nextData.record.result = {};
      if (payload.result) {
        // 분석결과는 나오지만 75% 이하
        // let originMsg =
        //   nextData.mediaScript[nextData.scriptIndex].mediaScriptSpeak[0]
        //     .mediaScriptSpeakAudio.sentence;
        // let splitMsg = originMsg.split(' ').map((sp, i) => {
        //   console.log(
        //     payload.result.missMatchIndex.filter(mIdx => mIdx === i),
        //     payload.result.missMatchIndex,
        //   );
        //   if (payload.result.missMatchIndex.filter(mIdx => mIdx === i).length) {
        //     return `<span class="wrong">${sp}</span>`;
        //   }
        //   return sp;
        // });

        // const resMsg = splitMsg.join('&nbsp;');

        // console.log({ originMsg, splitMsg, resMsg });
        // nextData.record.resMsg = resMsg;
        let affixSentence = payload.result.affixSentence;
        // let affixSentence =
        //   '__ __ __ ~country~ ~music~ `wonderful` good job `wow` ad';

        nextData.record.resMsg = replaceCoverTag(
          affixSentence,
          /(~|`)[a-zA-Z]*(~|`)/,
          'span',
          'wrong',
        );
        nextData.record.result = payload.result;
      }

      if (nextData.record.code === 5253) {
        nextData.record.message = `제가 잘 이해하지 못했어요..\n한 번 더 말해주시겠어요?`;
      } else if (nextData.record.code === 5252) {
        nextData.record.message = `너무 짧아서 분석할 수 없어요.\n다시 말해주시겠어요?`;
      } else if (nextData.record.code === 5251) {
        nextData.record.message = `제가 알아들을 수 없었어요.\n고객센터로 문의해주세요.`;
      } else if (nextData.record.code === 5210) {
        nextData.record.message = `음성을 확인 할 수 없었어요.\n고객센터로 문의해주세요.`;
      } else if (nextData.record.code === 5254) {
        nextData.record.message = `주변 소음이 심한것 같아요.\n한 번 더 말해주시겠어요?`;
      } else if (nextData.record.code === 5100) {
        nextData.record.message = `단어를 인식 할 수 없었어요.\n고객센터로 문의해주세요.`;
      } else if (nextData.record.code === 5000) {
        nextData.record.message = `음성을 인식 할 수 없었어요.\n고객센터로 문의해주세요.`;
      }

      // nextData.record.message = payload.message;
      return nextData;
    case practice.POST_PRACTICE_RECORD_DATA_SUCCESS:
      nextData = { ...state };
      // console.log(`POST_PRACTICE_RECORD_DATA_SUCCESS : `, { payload });
      nextData.record.status = 'success';
      nextData.record.result = payload;
      // console.log(
      //   nextData.complete,
      //   nextData.scriptIndex,
      //   nextData.complete[nextData.scriptIndex].userMediaScriptSpeakPractice,
      //   payload,
      // );
      nextData.complete[
        nextData.scriptIndex
      ].userMediaScriptSpeakPractice = payload;
      // console.log(nextData.complete[nextData.scriptIndex]);

      return nextData;

    case practice.GET_PRACTICE_USER_STUDY_STATUS_SUCCESS:
      nextData = { ...state };
      // console.log({ payload });

      if (payload) nextData.complete = payload;
      nextData.stepSize = payload.length;

      return nextData;
    default:
      return state;
  }
};

const replaceCoverTag = (str, regExp, coverTag, className) => {
  const regexp = regExp || /((~|`)[(a-zA-Z']*(~|`))/;

  const res = regexp.exec(str);
  res &&
    res.forEach(() => {
      const reg = /(~|`)/;
      const target = str.match(regexp);
      if (!target) return;
      const matchSentence = target && target[0];
      const replaceSectence = matchSentence
        .replace(reg, `<${coverTag} class="${className ? className : ''}">`)
        .replace(reg, `</${coverTag}>`);
      str = str.replace(matchSentence, replaceSectence);
    });
  if (str.match(regexp)) {
    return replaceCoverTag(str, regExp, coverTag, className);
  }
  return str.split('__ ').join(``);
};

// let affixSentence = '__ __ __ ~country~ ~music~ `wonderful` good job `wow` ad';
// const data = replaceCoverTag(affixSentence, null, 'span', 'wrong');
