import * as word from 'shared/actions/word.action';

export const initialState = {
  recommendWords: [],
  result: null,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case word.GET_RECOMMEND_WORDS_SUCCESS:
      return {
        ...state,
        recommendWords: payload,
      };
    case word.INIT_SEARCH_WORD_SUCCESS:
      return {
        ...state,
        result: null,
      };

    case word.GET_SEARCH_WORD_REQUEST:
      return {
        ...state,
        result: null,
        isLoading: true,
      };

    case word.GET_SEARCH_WORD_SUCCESS:
      return {
        ...state,
        result: payload,
        isLoading: false,
      };

    case word.SAVE_WORD_SUCCESS:
      return {
        ...state,
        recommendWords: state.recommendWords.map(item =>
          item.word === payload ? { ...item, wordChoice: true } : item,
        ),
      };
    case word.CANCEL_WORD_SUCCESS:
      return {
        ...state,
        recommendWords: state.recommendWords.map(item =>
          item.word === payload ? { ...item, wordChoice: false } : item,
        ),
      };

    default:
      return state;
  }
};
