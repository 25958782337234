// Challenge Daily Mission // 미션체크 조회
/**
 * 개인 페이백 챌린지 미션체크 조회
 */
export const GET_CHALLENGE_DAILY_MISSION_REQUEST =
  '@challenge/GET_CHALLENGE_DAILY_MISSION_REQUEST';
export const GET_CHALLENGE_DAILY_MISSION_FAILURE =
  '@challenge/GET_CHALLENGE_DAILY_MISSION_FAILURE';
export const GET_CHALLENGE_DAILY_MISSION_SUCCESS =
  '@challenge/GET_CHALLENGE_DAILY_MISSION_SUCCESS';

export const getChallengeDailyMissionRequset = (payload = {}) => ({
  type: GET_CHALLENGE_DAILY_MISSION_REQUEST,
  payload,
});

export const getChallengeDailyMissionFailure = payload => ({
  type: GET_CHALLENGE_DAILY_MISSION_FAILURE,
  payload,
});

export const getChallengeDailyMissionSuccess = payload => ({
  type: GET_CHALLENGE_DAILY_MISSION_SUCCESS,
  payload,
});

// Challenge Daily Mission mission Complete // 미션체크 조회
export const POST_CHALLENGE_STUDY_COMPLETE_REQUEST =
  '@challenge/POST_CHALLENGE_STUDY_COMPLETE_REQUEST';
export const POST_CHALLENGE_STUDY_COMPLETE_FAILURE =
  '@challenge/POST_CHALLENGE_STUDY_COMPLETE_FAILURE';
export const POST_CHALLENGE_STUDY_COMPLETE_SUCCESS =
  '@challenge/POST_CHALLENGE_STUDY_COMPLETE_SUCCESS';

export const postChallengeStudyCompleteRequset = (payload = {}) => ({
  type: POST_CHALLENGE_STUDY_COMPLETE_REQUEST,
  payload,
});

export const postChallengeStudyCompleteFailure = payload => ({
  type: POST_CHALLENGE_STUDY_COMPLETE_FAILURE,
  payload,
});

export const postChallengeStudyCompleteSuccess = payload => ({
  type: POST_CHALLENGE_STUDY_COMPLETE_SUCCESS,
  payload,
});

// Challenge List // 진행내역 조회
/**
 * 페이백 챌린지 진행내역 조회
 */
export const GET_CHALLENGE_LIST_REQUEST =
  '@challenge/GET_CHALLENGE_LIST_REQUEST';
export const GET_CHALLENGE_LIST_FAILURE =
  '@challenge/GET_CHALLENGE_LIST_FAILURE';
export const GET_CHALLENGE_LIST_SUCCESS =
  '@challenge/GET_CHALLENGE_LIST_SUCCESS';

export const getChallengeListRequset = payload => ({
  type: GET_CHALLENGE_LIST_REQUEST,
  payload,
});

export const getChallengeListFailure = payload => ({
  type: GET_CHALLENGE_LIST_FAILURE,
  payload,
});

export const getChallengeListSuccess = payload => ({
  type: GET_CHALLENGE_LIST_SUCCESS,
  payload,
});

// Challenge Status // 뱃지 배너 정보
/**
 * 개인 챌린지 뱃지 정보 요청 api
 */
export const GET_CHALLENGE_STATUS_REQUEST =
  '@challenge/GET_CHALLENGE_STATUS_REQUEST';
export const GET_CHALLENGE_STATUS_FAILURE =
  '@challenge/GET_CHALLENGE_STATUS_FAILURE';
export const GET_CHALLENGE_STATUS_SUCCESS =
  '@challenge/GET_CHALLENGE_STATUS_SUCCESS';

export const getChallengeStatusRequset = payload => ({
  type: GET_CHALLENGE_STATUS_REQUEST,
  payload,
});

export const getChallengeStatusFailure = payload => ({
  type: GET_CHALLENGE_STATUS_FAILURE,
  payload,
});

export const getChallengeStatusSuccess = payload => ({
  type: GET_CHALLENGE_STATUS_SUCCESS,
  payload,
});

export const SET_MISSION = '@challenge/SET_MISSION';
export const setMission = payload => ({
  type: SET_MISSION,
  payload,
});

// Challenge Status // 뱃지 배너 정보
export const POST_CHALLENGE_START_REQUEST =
  '@challenge/POST_CHALLENGE_START_REQUEST';
export const POST_CHALLENGE_START_FAILURE =
  '@challenge/POST_CHALLENGE_START_FAILURE';
export const POST_CHALLENGE_START_SUCCESS =
  '@challenge/POST_CHALLENGE_START_SUCCESS';

export const postChallengeStartRequest = payload => ({
  type: POST_CHALLENGE_START_REQUEST,
  payload,
});

export const postChallengeStartFailure = payload => ({
  type: POST_CHALLENGE_START_FAILURE,
  payload,
});

export const postChallengeStartSuccess = payload => ({
  type: POST_CHALLENGE_START_SUCCESS,
  payload,
});

// Challenge Status // 뱃지 배너 정보
/**
 * 페이백 챌린지 하루미션 완료 리퀘스트 api
 */
export const POST_CHALLENGE_DAY_MISSION_REQUEST =
  '@challenge/POST_CHALLENGE_DAY_MISSION_REQUEST';
export const POST_CHALLENGE_DAY_MISSION_FAILURE =
  '@challenge/POST_CHALLENGE_DAY_MISSION_FAILURE';
export const POST_CHALLENGE_DAY_MISSION_SUCCESS =
  '@challenge/POST_CHALLENGE_DAY_MISSION_SUCCESS';

export const postChallengeDayMissionRequest = (payload, date) => ({
  type: POST_CHALLENGE_DAY_MISSION_REQUEST,
  payload,
  date,
});

export const postChallengeDayMissionFailure = payload => ({
  type: POST_CHALLENGE_DAY_MISSION_FAILURE,
  payload,
});

export const postChallengeDayMissionSuccess = payload => ({
  type: POST_CHALLENGE_DAY_MISSION_SUCCESS,
  payload,
});

// Challenge Need Paper * 신분증사본 등록
/**
 * 페이백 챌린지 신분증,통장 업로드 api
 */
export const POST_CHALLENGE_PAPER_REQUEST =
  '@challenge/POST_CHALLENGE_PAPER_REQUEST';
export const POST_CHALLENGE_PAPER_SUCCESS =
  '@challenge/POST_CHALLENGE_PAPER_SUCCESS';
export const POST_CHALLENGE_PAPER_FAILURE =
  '@challenge/POST_CHALLENGE_PAPER_FAILURE';

export const postChallengePaperRequest = payload => ({
  type: POST_CHALLENGE_PAPER_REQUEST,
  payload,
});

export const postChallengePaperSuccess = (payload, action) => ({
  type: POST_CHALLENGE_PAPER_SUCCESS,
  payload,
  action,
});

export const postChallengePaperFailure = (payload, action) => ({
  type: POST_CHALLENGE_PAPER_FAILURE,
  payload,
  action,
});

/**
 * 페이백 채린지 신분증,통장 사본 내역 확인 api
 */
export const GET_CHALLENGE_PAPER_REQUEST =
  '@challenge/GET_CHALLENGE_PAPER_REQUEST';
export const GET_CHALLENGE_PAPER_SUCCESS =
  '@challenge/GET_CHALLENGE_PAPER_SUCCESS';
export const GET_CHALLENGE_PAPER_FAILURE =
  '@challenge/GET_CHALLENGE_PAPER_FAILURE';

export const getChallengePaperRequest = payload => ({
  type: GET_CHALLENGE_PAPER_REQUEST,
  payload,
});

export const getChallengePaperSuccess = payload => ({
  type: GET_CHALLENGE_PAPER_SUCCESS,
  payload,
});

export const getChallengePaperFailure = payload => ({
  type: GET_CHALLENGE_PAPER_FAILURE,
  payload,
});

export const mutateChallengeForTest = payload => ({
  type: '@develop/MUTATE_CHALLENGE_FOR_TEST',
  payload
})