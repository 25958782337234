import * as customer from 'shared/actions/customer.action';

export const initialState = {
  faq: [],
  review: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case customer.GET_CUSTOMER_FAQ_SUCCESS:
      let faq = [];
      const keys = Object.keys(payload.result);
      if (payload.result && keys) {
        keys.forEach((key, i) => {
          let obj = {};
          if (payload.result[key]) {
            obj.name = payload.result[key][0].category;
            obj.list = payload.result[key].map(li => {
              if (li.category) delete li.category;
              return li;
            });
          }

          faq.push(obj);
          return obj;
        });
      }

      return {
        ...state,
        faq: faq,
      };

    case customer.GET_CUSTOMER_REVIEW_SUCCESS:
      let review = payload.result;

      return {
        ...state,
        review: review,
      };

    default:
      return state;
  }
};
