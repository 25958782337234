export const PUSH_MODAL = '@modal/PUSH_MODAL';
export const INIT_MODAL = '@modal/INIT_MODAL';

export let modalIndex = 0;
export const pushModal = payload => {
  modalIndex++;
  return {
    type: PUSH_MODAL,
    payload: {
      ...payload,
      index: modalIndex,
    },
  };
};

export const initModal = () => ({
  type: INIT_MODAL,
});

export const POST_LIST_ITEM_NAME_REQUEST = '@modal/POST_LIST_ITEM_NAME_REQUEST';
export const postListItemNameRequest = (id, name, i) => ({
  type: POST_LIST_ITEM_NAME_REQUEST,
  payload: {
    name,
    i,
    id,
  },
});
export const POST_LIST_ITEM_NAME_SUCCESS = '@modal/POST_LIST_ITEM_NAME_SUCCESS';
export const postListItemNameSuccess = (payload, action) => ({
  type: POST_LIST_ITEM_NAME_SUCCESS,
  payload,
  action,
});
export const POST_LIST_ITEM_NAME_FAILURE = '@modal/POST_LIST_ITEM_NAME_FAILURE';
export const postListItemNameFailure = (payload, action) => ({
  type: POST_LIST_ITEM_NAME_FAILURE,
  payload,
  action,
});
