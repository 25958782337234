import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';

import * as listen from 'shared/actions/listen.action';
import * as listenAPI from 'shared/apis/media/listen.api';
import * as userMediaScriptLitenAPI from 'shared/apis/media/userMediaScriptListen.api';

import { errorHandler } from 'shared/epics/auth.epic';

const getListenRequestEpic = action$ =>
  action$.pipe(
    ofType(listen.GET_LISTEN_REQUEST),
    mergeMap(action =>
      listenAPI.getListen$(action.payload).pipe(
        map(data => listen.getListenSuccess(data.result)),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

const saveListenStepRequestEpic = action$ =>
  action$.pipe(
    ofType(listen.SAVE_LISTEN_STEP_REQUEST),
    mergeMap(action =>
      userMediaScriptLitenAPI.saveListenStep$(action.payload).pipe(
        map(data => {
          if (data.code !== 200)
            return listen.saveListenStepFailure(data.result);
          return listen.saveListenStepSuccess(data.result);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(getListenRequestEpic, saveListenStepRequestEpic);
