import { publicAPI } from 'shared/utils/ajax';

const path = `/annon/user`;
const encode = (value = '') => {
  // console.log(value);
  return encodeURI(value);
};

export const getInherenceKey$ = () => publicAPI.get('/annon/web/key');

// JOIN
// export const getCheckUserEmail$ = (email = '') =>
//   publicAPI.get(`${path}/email?email=${encode(email)}`);
export const getCheckUserNickname$ = (nickname = '') =>
  publicAPI.get(`${path}/nickname?nickname=${encode(nickname)}`);
export const getSendPhoneAuth$ = ({ phone = '' }) =>
  publicAPI.get(`${path}/sendPhoneAuth?phone=${encode(phone)}`);

// export const postRegister$ = (userData = {}) =>
//   publicAPI.post(`${path}/register`, userData);

export const getVerifyCode$ = ({ phoneCode = '', phone = '' }) =>
  publicAPI.get(
    `${path}/verifyCode?code=${encode(phoneCode)}&phone=${encode(phone)}`,
  );
export const verifyCodeUserEmail$ = ({ phoneCode = '', phone = '' }) =>
  publicAPI.get(
    `${path}/verifyCodeUserEmail?code=${encode(phoneCode)}&phone=${encode(
      phone,
    )}`,
  );

// FIND
export const getFindEmail$ = (phone = '') =>
  publicAPI.get(`${path}/findEmail?phone=${encode(phone)}`);
export const getFindPassword$ = (phone = '') =>
  publicAPI.get(`${path}/sendTempPassword?phone=${encode(phone)}`);
