import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { getCookie } from 'shared/utils/cookie';
import { detect } from 'detect-browser';

const browser = detect();

const { REACT_APP_AUTH_USERNAME, REACT_APP_AUTH_PASSWORD } = process.env;

const make_basic_auth = (user, pass) => {
  var tok = user + ':' + pass;
  var hash = window.btoa(tok);
  return 'Basic ' + hash;
};

const auth = make_basic_auth(REACT_APP_AUTH_USERNAME, REACT_APP_AUTH_PASSWORD);

const ajaxConfig = (method, url, body, option, error) => {
  const settings = {
    method,
    // url:
    //   process.env.NODE_ENV === 'development'
    //     ? url
    //     : process.env.REACT_APP_API_URL + url,
    url: process.env.REACT_APP_API_URL + url,
    timeout: 10000,
    withCredentials: true,
    crossDomain: true,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      ...option,
    },
    body,
  };
  return ajax(settings).pipe(map(data => data.response));
};

const { APP_VERSION } = process.env;
const browserVersion = browser.name ? browser.name : 'browser';
console.log({ browserVersion, browser });

const tokenOption = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Authorization: auth,
  osType: 'WEB',
  osVersion: browserVersion,
  appVersion: APP_VERSION,
  // 'Access-Control-Allow-Origin': '*',
  // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
  // 'Access-Control-Allow-Headers':
  //   'Content-Type, Authorization, Content-Length, X-Requested-With',
};

export const privateOption = dr_access_token => {
  const access_token = getCookie('dr_access_token');
  const osType = getCookie('dr_os_type');
  const version = getCookie('dr_version');
  const appVersion = getCookie('dr_app_version');

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${dr_access_token ? dr_access_token : access_token}`,
    osType: osType ? osType : 'WEB',
    osVersion: version ? version : browserVersion,
    appVersion: appVersion ? appVersion : APP_VERSION,
  };
};

export const Token = (url, body) => ajaxConfig('POST', url, body, tokenOption);

export const GET = (url, error) =>
  ajaxConfig('GET', url, null, privateOption(), error);
export const POST = (url, body, option) =>
  ajaxConfig('POST', url, body, { ...privateOption(), ...option });
export const PUT = (url, body) => ajaxConfig('PUT', url, body, privateOption());
export const PATCH = (url, body) =>
  ajaxConfig('PATCH', url, body, privateOption());
export const DELETE = (url, body) =>
  ajaxConfig('DELETE', url, body, privateOption());

export const GET_CUSTOM_TOKEN = (url, dr_access_token) =>
  ajaxConfig('GET', url, null, privateOption(dr_access_token));
export const POST_CUSTOM_TOKEN = (url, body, dr_access_token) =>
  ajaxConfig('POST', url, body, privateOption(dr_access_token));
export const DELETE_CUSTOM_TOKEN = (url, body, dr_access_token) =>
  ajaxConfig('DELETE', url, body, privateOption(dr_access_token));

const option = {
  'Content-Type': 'application/json',
  Authorization: auth,
  osType: 'WEB',
};
export let publicAPI = {};
publicAPI.get = url => ajaxConfig('GET', url, null, option);
publicAPI.post = (url, data) => ajaxConfig('POST', url, data, option);
publicAPI.put = (url, data) => ajaxConfig('PUT', url, data, option);
publicAPI.patch = (url, data) => ajaxConfig('PATCH', url, data, option);
publicAPI.delete = (url, data) => ajaxConfig('DELETE', url, data, option);
