import {
  createRequestAction,
  createActionCreator,
} from 'shared/utils/requestActionCreator';

/**
 * 쿠폰 조회
 */
export const getCoupon = createRequestAction('@coupon', 'GET_COUPON');
// getCoupon.type == '@coupon/GET_COUPON_REQUEST'

/**
 * 쿠폰 발급
 */
export const postCoupon = createRequestAction('@coupon', 'POST_COUPON');

/**
 * 쿠폰 마이 리스트 조회
 */
export const getMyCoupon = createRequestAction('@coupon', 'GET_MY_COUPON');

/**
 * 적용 가능한 쿠폰 조회
 */
export const getApplyCoupon = createRequestAction(
  '@coupon',
  'GET_APPLY_COUPON',
);

/**
 *  무료체험 영상4개 다 시청한 상태값
 */
export const getVideoPlayStatusCoupon = createRequestAction(
  '@coupon',
  'GET_VIDEO_PLAY_STATUS_COUPON',
);

/**
 * 무료체험 쿠폰 조회
 */
export const getFreeLectureCoupon = createRequestAction(
  '@coupon',
  'GET_FREE_LECTURE_COUPON',
);

/**
 * 결제페이지 쿠폰 리스트
 */
export const getPaymentCouponList = createRequestAction(
  '@coupon',
  'GET_PAYMENT_COUPON_LIST',
);

/**
 * 비디오 상태값 초기화
 */
export const getVideoStatusReset = createActionCreator(
  '@coupon',
  'GET_VIDEO_STATUS_RESET',
);
