export const GET_SPEAK_REQUEST = '@speak/GET_SPEAK_REQUEST';
export const GET_SPEAK_SUCCESS = '@speak/GET_SPEAK_SUCCESS';
export const GET_SPEAK_FAILURE = '@speak/GET_SPEAK_FAILURE';

export const getSpeakRequest = payload => ({
  type: GET_SPEAK_REQUEST,
  payload,
});

export const getSpeakSuccess = payload => ({
  type: GET_SPEAK_SUCCESS,
  payload,
});

export const getSpeakFailure = payload => ({
  type: GET_SPEAK_FAILURE,
  payload,
});

export const SAVE_SPEAK_STEP_REQUEST = '@speak/SAVE_SPEAK_STEP_REQUEST';
export const SAVE_SPEAK_STEP_SUCCESS = '@speak/SAVE_SPEAK_STEP_SUCCESS';
export const SAVE_SPEAK_STEP_FAILURE = '@speak/SAVE_SPEAK_STEP_FAILURE';

export const saveSpeakStepRequest = payload => ({
  type: SAVE_SPEAK_STEP_REQUEST,
  payload,
});

export const saveSpeakStepSuccess = payload => ({
  type: SAVE_SPEAK_STEP_SUCCESS,
  payload,
});

export const saveSpeakStepFailure = payload => ({
  type: SAVE_SPEAK_STEP_FAILURE,
  payload,
});
