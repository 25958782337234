import * as coupon from 'shared/actions/coupon.action';

export const initialState = {
  couponInfo: {}, //쿠폰번호
  couponFreeLectureInfo: {},
  couponPaymentList: [],
  couponError: {},
  couponList: [],
  couponApply: {},
  couponVideoPlayingStatus: {
    uid: {
      '0': [],
      '1': [],
      '2': [],
      '3': [],
      '4': [],
    },
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case coupon.getCoupon.success.type:
      return {
        ...state,
        couponInfo: payload.result,
      };

    case coupon.getCoupon.failure.type:
      return {
        ...state,
        couponError: payload.couponError,
      };

    case coupon.getFreeLectureCoupon.success.type:
      return {
        ...state,
        couponFreeLectureInfo: payload.result,
      };

    case coupon.getPaymentCouponList.success.type:
      return {
        ...state,
        couponPaymentList: payload.result,
      };

    case coupon.getMyCoupon.success.type:
      if (payload.result.length === state.couponList.length) return state;
      return {
        ...state,
        couponList: payload.result,
      };

    case coupon.getApplyCoupon.success.type:
      return {
        ...state,
        couponApply: payload.result,
      };

    case coupon.getVideoPlayStatusCoupon.type:
      return {
        ...state,
        couponVideoPlayingStatus: {
          uid: {
            ...state.couponVideoPlayingStatus.uid,
            [payload.index]: [
              ...state.couponVideoPlayingStatus.uid[payload.index],
              payload.id,
            ],
          },
        },
      };

    case coupon.getVideoStatusReset.type:
      return {
        ...state,
        couponVideoPlayingStatus: initialState.couponVideoPlayingStatus,
      };

    default:
      return state;
  }
};
