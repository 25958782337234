import cloneDeep from 'lodash/cloneDeep';

import * as toast from 'shared/actions/toast.action';
// import * as auth from 'shared/actions/auth.action';

export const initialState = {
  app: null,
};

// let nextState;
export default (state = initialState, { type, payload }) => {
  switch (type) {
    // case auth.GET_FIND_PASSWORD_SUCCESS:
    //   return postMan(state, '임시 비밀번호를 발송하였습니다.');

    case toast.INIT_TOAST_APP:
      // nextState = _.cloneDeep(initialState);
      return cloneDeep(initialState);

    case toast.PUSH_TOAST_APP:
      return postMan(state, payload.message, payload);
    default:
      return state;
  }
};

const postMan = (currentState, message, payload) => {
  let nextData = cloneDeep(currentState);
  nextData.app = {
    message,
    index:
      currentState.app && currentState.app.index
        ? currentState.app.index + 1
        : payload.index + 1,
  };
  return nextData;
};
