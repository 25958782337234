import { GET, POST } from 'shared/utils/ajax';

const media = `/rest/media`;
const path = media + `/userMedia`;

export const latestStudyList$ = () => GET(`${path}`);

export const userMediaPlay$ = upc => POST(`${path}Play/${upc}`);

/**
 *
 * @param {object} data
 * {
 *   completed: boolean,
 *   progress: number,
 *   userMediaId: {
 *     mediaUpc: string
 *   }
 * }
 *
 */
export const completeSong$ = data => POST(path, data);
