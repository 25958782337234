export const GET_SEARCH_SONG_REQUEST = '@searchSong/GET_SEARCH_SONG_REQUEST';
export const GET_SEARCH_SONG_SUCCESS = '@searchSong/GET_SEARCH_SONG_SUCCESS';
export const GET_SEARCH_SONG_EMPTY = '@searchSong/GET_SEARCH_SONG_EMPTY';
export const GET_SEARCH_SONG_INIT = '@searchSong/GET_SEARCH_SONG_INIT';
export const SET_KEYWORD = '@searchSong/SET_KEYWORD';
export const setKeyword = payload => ({
  type: SET_KEYWORD,
  payload,
});

export const getSearchSongRequset = payload => ({
  type: GET_SEARCH_SONG_REQUEST,
  payload,
});

export const getSearchSongSuccess = payload => ({
  type: GET_SEARCH_SONG_SUCCESS,
  payload,
});
export const getSearchSongEmpty = payload => ({
  type: GET_SEARCH_SONG_EMPTY,
  payload,
});

export const getSearchSongInit = payload => ({
  type: GET_SEARCH_SONG_INIT,
  payload,
});

export const SET_STATUS_ON = '@searchSong/SET_STATUS_ON';
export const SET_STATUS_OFF = '@searchSong/SET_STATUS_OFF';
export const setStatusOn = () => ({
  type: SET_STATUS_ON,
});
export const setStatusOff = () => ({
  type: SET_STATUS_OFF,
});
