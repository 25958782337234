import { POST } from 'shared/utils/ajax';

/**
 * 강의 저장
 * @param {upc} string
 * @param {object} data
 * {
 *   "completed": boolean,
 *   "progress": number,
 *   "userLectureId": {
 *     "step": number
 *   }
 * }
 */
export const saveLecture$ = (data, upc) =>
  POST(`/rest/lecture/userLecture/${upc}`, data);
