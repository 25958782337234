export const GET_FREE_LECTURE_REQUEST = '@free/GET_FREE_LECTURE_REQUEST';
export const GET_FREE_LECUTRE_SUCCESS = '@free/GET_FREE_LECUTRE_SUCCESS';
export const GET_FREE_LECTURE_FAILURE = '@free/GET_FREE_LECTURE_FAILURE';

export const getFreeLectureRequest = payload => ({
  type: GET_FREE_LECTURE_REQUEST,
  payload,
});

export const getFreeLectureSuccess = payload => ({
  type: GET_FREE_LECUTRE_SUCCESS,
  payload,
});

export const getFreeLectureFailure = payload => ({
  type: GET_FREE_LECTURE_FAILURE,
  payload,
});
