import { GET } from 'shared/utils/ajax';

const media = `/rest/media`;

export const getMediaPlaylist$ = (getType, search = {}, upc) => {
  let path = `${media}`;
  // if (getType === 'level') path += '/search';
  path += '/' + getType;
  if (!getType) path += 'userMediaPlay'; //upc;

  let query =
    (search.type ? '/' + upperUriForm(search.type) : '') +
    (search.value ? '/' + upperUriForm(search.value) : '');
  path += query;

  return GET(path);
};

export const upperUriForm = param => {
  return encodeURI(param.toUpperCase());
};
