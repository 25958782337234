import { publicAPI } from 'shared/utils/ajax';

const path = `/annon`;

export const getAnnonFAQKey$ = () => publicAPI.get(`${path}/faq`);
export const getAnnonReview$ = count =>
  publicAPI.get(`${path}/review?showCount=${count}`);

export const getAnnonBenefit$ = (data = {}) =>
  publicAPI.post(
    `${path}/ask/telemarketing/event/${data.event}?code=${data.code}`,
  );
