import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import { BrowserRouter } from 'react-router-dom';

import Offline from 'shared/routes/Offline';

// import eventSource from 'shared/utils/eventSource';
import getUserConfirmation from 'web/libs/getUserConfirmation';
import asyncComponent from './asyncComponent';
import { isMobile, isTablet } from 'react-device-detect';
import { getCookie } from 'shared/utils/cookie';

const App = asyncComponent(() => {
  const isWebview =
    getCookie('dr_os_type') === 'IOS' || getCookie('dr_os_type') === 'ANDROID';
  window.isMobile = isMobile || isTablet || isWebview;
  return isMobile ? import('./mobile/App') : import('./web/App');

  // 서비스 로딩에 앞서서 브라우저의 USER AGENT 값을 가반으로 모바일과 데스크탑 환경을 구분하여 프로젝트 로딩
});

class Root extends Component {
  state = {
    online: window.navigator.onLine,
    // 인터넷 연결상태 값을 설정해주고
  };

  componentDidMount() {
    // 인터넷 연결상태를 리스닝 하다가
    window.addEventListener('online', e => {
      console.log('online');
      // 연결상태의 변동이 생기면 상태를 변경해주고
      this.setState({ online: true });
    });
  }

  render() {
    const { online = false } = this.state;

    return (
      <Provider store={store}>
        {/* getUserConfirmation의 역할은
         * React-Router페이지 이동전 변경점 체크
         * 변경이 되었는데 저장이 안되었을경우, 메세지와 콜백함수실행
         * ex(/mystudy/locker/script) 데이터 수정하고 다른페이지로 이동시 모달창이 뜸
         */}
        <BrowserRouter getUserConfirmation={getUserConfirmation}>
          {online ? <App /> : <Offline />}
          {/** 온라인 상태가 아닐 때 발생하는 버그 방지를 위해 오프라인 상태에 대한 안내를 진행 */}
        </BrowserRouter>
      </Provider>
    );
  }
}

export default Root;
