import { GET, POST, DELETE } from 'shared/utils/ajax';

const path = '/rest/media/userDictionary';

/**
 * 단어 담기 / 취소 API
 * @param {object}
 * {
 *    mediaUpc: ,
 *    userDictionaryId: {
 *      userId: ,
 *      word:,
 *      searchWord:,
 *    }
 * }
 */
export const getUserWordList$ = () => GET(path);

export const saveWord$ = ({ mediaUpc, userDictionaryId }) =>
  POST(path, { mediaUpc, userDictionaryId });

export const cancelWord$ = ({ mediaUpc, userDictionaryId }) =>
  DELETE(path, { mediaUpc, userDictionaryId });
