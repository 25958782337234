export const REGISTER_SHOW = '@register/REGISTER_SHOW';
export const REGISTER_HIDE = '@register/REGISTER_HIDE';

export const registerShow = payload => ({
  type: REGISTER_SHOW,
  payload,
});

export const registerHide = payload => ({
  type: REGISTER_HIDE,
  payload,
});
