import { ofType, combineEpics } from 'redux-observable';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { errorHandler } from 'shared/epics/auth.epic';
import * as userAPI from 'shared/apis/user.api';

import * as modal from 'shared/actions/modal.action';

const postListItemNameRequestEpic = action$ =>
  action$.pipe(
    ofType(modal.POST_LIST_ITEM_NAME_REQUEST),
    mergeMap(action =>
      userAPI.deviceName$(action.payload.id, action.payload.name).pipe(
        map(response => {
          console.log(`postListItemNameRequestEpic `, response);
          if (response.code !== 200) {
            return modal.postListItemNameFailure(response, action);
          }
          return modal.postListItemNameSuccess(response, action);
        }),
        catchError(error => errorHandler(error, action)),
      ),
    ),
  );

export default combineEpics(postListItemNameRequestEpic);
