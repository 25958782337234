import { GET, POST } from 'shared/utils/ajax';

const path = '/rest/challenge';

// 챌린지 미션체크 조회
export const getChallengeDailyMisiion$ = ({ date = '' }) => {
  let pathname = `${path}/month/day`;

  if (date) {
    pathname += `?date=${date}`;
  }

  return GET(pathname);
};

export const postChallengeStudyComplete$ = ({ date = '' }) => {
  if (!date) return console.error('date 형식이 맞지 않습니다.');
  let pathname = `${path}/month/day/${date}/study/complete`;

  return POST(pathname);
};

// 챌린지 진행내역 조회
export const getChallengeList$ = payload => {
  return GET(`${path}/month/list`);
};

// 환금 챌린지 배너 여부
export const getChallengeStatus$ = payload => {
  return GET(`${path}/status`);
};

// 환금 챌린지 배너 여부
export const postChallengeStart$ = payload => {
  return POST(`${path}/start`);
};

// 환금 챌린지 배너 여부
export const postChallengeDayMission$ = (
  {
    recommendScore = 0, // Number
    recommendReason = '',
    studyImage = '',
    studyReview = '',
    studyScore = 0, // Number
  },
  date = '',
) => {
  let body = {
    recommendScore,
    recommendReason,
    studyImage,
    studyReview,
    studyScore,
    uploadDate: new Date(),
  };

  // if (recommendScore) body.recommendScore = recommendScore;
  // if (recommendReason) body.recommendReason = recommendReason;
  // if (studyImage) body.studyImage = studyImage;
  // if (studyReview) body.studyReview = studyReview;
  // if (studyScore) body.studyScore = studyScore;

  console.log(JSON.stringify(body, null, 2));

  let pathname = `${path}/month/day`;
  if (date) pathname += `/${date}`;

  return POST(pathname, body);
};

// 페이백 필요서류등록 포스트
export const postChallengePaper$ = ({ file = null, pathName = '' }) => {
  console.log({ apiFile: file });
  return POST(`${path}/doc/${pathName}`, { base64Image: file });
};

// 페이백 필요서류등록 조회
export const getChallengePaper$ = payload => {
  console.log('getChallegnePaper', { payload });
  return GET(`${path}/user`);
};
