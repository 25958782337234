export const GET_STUDY_LIST_ALL_REQUEST =
  '@studyList/GET_STUDY_LIST_ALL_REQUEST';
export const GET_STUDY_LIST_ALL_SUCCESS =
  '@studyList/GET_STUDY_LIST_ALL_SUCCESS';

export const getStudyListAllRequset = payload => ({
  type: GET_STUDY_LIST_ALL_REQUEST,
  payload,
});

export const getStudyListAllSuccess = payload => ({
  type: GET_STUDY_LIST_ALL_SUCCESS,
  payload,
});
