export const GET_LECTURE_RECOMMEND_WORDS_REQUEST =
  '@word/GET_LECTURE_RECOMMEND_WORDS_REQUEST';
export const GET_SONG_RECOMMEND_WORDS_REQUEST =
  '@word/GET_SONG_RECOMMEND_WORDS_REQUEST';
export const GET_RECOMMEND_WORDS_SUCCESS = '@word/GET_RECOMMEND_WORDS_SUCCESS';
export const GET_RECOMMEND_WORDS_FAILURE = '@word/GET_RECOMMEND_WORDS_FAILURE';

export const getLectureRecommendWordsRequest = payload => ({
  type: GET_LECTURE_RECOMMEND_WORDS_REQUEST,
  payload,
});

export const getSongRecommendWordsRequest = payload => ({
  type: GET_SONG_RECOMMEND_WORDS_REQUEST,
  payload,
});

export const getRecommendWordsSuccess = payload => ({
  type: GET_RECOMMEND_WORDS_SUCCESS,
  payload,
});

export const getRecommendWordsFailure = payload => ({
  type: GET_RECOMMEND_WORDS_FAILURE,
  payload,
});

//강의 사전열기 api
//강의(/study) 페이지의 강의,팝송 멜로딕 트레이닝 강의
export const INIT_SEARCH_WORD_REQUEST = '@word/INIT_SEARCH_WORD_REQUEST';
export const INIT_SEARCH_WORD_SUCCESS = '@word/INIT_SEARCH_WORD_SUCCESS';

export const initSearchWordRequest = () => ({
  type: INIT_SEARCH_WORD_REQUEST,
});

export const initSearchWordSuccess = () => ({
  type: INIT_SEARCH_WORD_SUCCESS,
});

//강의 사전 검색요청 api
export const GET_SEARCH_WORD_REQUEST = '@word/GET_SEARCH_WORD_REQUEST';
export const GET_SEARCH_WORD_SUCCESS = '@word/GET_SEARCH_WORD_SUCCESS';
export const GET_SEARCH_WORD_FAILURE = '@word/GET_SEARCH_WORD_FAILURE';

export const getSearchWordRequest = payload => ({
  type: GET_SEARCH_WORD_REQUEST,
  payload,
});

export const getSearchWordSuccess = payload => ({
  type: GET_SEARCH_WORD_SUCCESS,
  payload,
});

export const getSearchWordFailure = payload => ({
  type: GET_SEARCH_WORD_FAILURE,
  payload,
});

/**
 * 사전담기 클릭시 요청하는 api
 */
export const SAVE_WORD_REQUEST = '@word/SAVE_WORD_REQUEST';
export const SAVE_WORD_SUCCESS = '@word/SAVE_WORD_SUCCESS';
export const SAVE_WORD_FAILURE = '@word/SAVE_WORD_FAILURE';

export const saveWordRequest = (payload, word) => ({
  type: SAVE_WORD_REQUEST,
  payload,
  word,
});

export const saveWordSuccess = payload => ({
  type: SAVE_WORD_SUCCESS,
  payload,
});

export const saveWordFailure = payload => ({
  type: SAVE_WORD_FAILURE,
  payload,
});

/**
 * 담기취소 클릭시 요청하는 api
 */
export const CANCEL_WORD_REQUEST = '@word/CANCEL_WORD_REQUEST';
export const CANCEL_WORD_SUCCESS = '@word/CANCEL_WORD_SUCCESS';
export const CANCEL_WORD_FAILURE = '@word/CANCEL_WORD_FAILURE';

export const cancelWordRequest = (payload, word) => ({
  type: CANCEL_WORD_REQUEST,
  payload,
  word,
});

export const cancelWordSuccess = payload => ({
  type: CANCEL_WORD_SUCCESS,
  payload,
});

export const cancelWordFailure = payload => ({
  type: CANCEL_WORD_FAILURE,
  payload,
});
