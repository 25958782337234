/**
 * 멜로딕 듣기 트레이닝 요청 api
 */
export const GET_LISTEN_REQUEST = '@listen/GET_LISTEN_REQUEST';
export const GET_LISTEN_SUCCESS = '@listen/GET_LISTEN_SUCCESS';
export const GET_LISTEN_FAILURE = '@listen/GET_LISTEN_FAILURE';

export const getListenRequest = payload => ({
  type: GET_LISTEN_REQUEST,
  payload,
});

export const getListenSuccess = payload => ({
  type: GET_LISTEN_SUCCESS,
  payload,
});

export const getListenFailure = payload => ({
  type: GET_LISTEN_FAILURE,
  payload,
});

/**
 * 멜로딕 듣기 트레이닝 단계별 요청 api
 */
export const SAVE_LISTEN_STEP_REQUEST = '@listen/SAVE_LISTEN_STEP_REQUEST';
export const SAVE_LISTEN_STEP_SUCCESS = '@listen/SAVE_LISTEN_STEP_SUCCESS';
export const SAVE_LISTEN_STEP_FAILURE = '@listen/SAVE_LISTEN_STEP_FAILURE';

export const saveListenStepRequest = payload => ({
  type: SAVE_LISTEN_STEP_REQUEST,
  payload,
});

export const saveListenStepSuccess = payload => ({
  type: SAVE_LISTEN_STEP_SUCCESS,
  payload,
});

export const saveListenStepFailure = payload => ({
  type: SAVE_LISTEN_STEP_FAILURE,
  payload,
});
