export const GET_MEDIA_PLAYLIST_REQUEST =
  '@webPlayer/GET_MEDIA_PLAYLIST_REQUEST';
export const GET_MEDIA_PLAYLIST_SUCCESS =
  '@webPlayer/GET_MEDIA_PLAYLIST_SUCCESS';
export const GET_MEDIA_SCRIPT_REQUEST = '@webPlayer/GET_MEDIA_SCRIPT_REQUEST';
export const GET_MEDIA_SCRIPT_SUCCESS = '@webPlayer/GET_MEDIA_SCRIPT_SUCCESS';
export const POST_USER_PLAYLIST_ITEM_REQUEST =
  '@webPlayer/POST_USER_PLAYLIST_ITEM_REQUEST';
export const POST_USER_PLAYLIST_ITEM_SUCCESS =
  '@webPlayer/POST_USER_PLAYLIST_ITEM_SUCCESS';
export const POST_USER_PLAYLIST_ITEM_FAILURE =
  '@webPlayer/POST_USER_PLAYLIST_ITEM_FAILURE';
export const DELETE_USER_PLAYLIST_ITEM_REQUEST =
  '@webPlayer/DELETE_USER_PLAYLIST_ITEM_REQUEST';
export const DELETE_USER_PLAYLIST_ITEM_SUCCESS =
  '@webPlayer/DELETE_USER_PLAYLIST_ITEM_SUCCESS';
export const DELETE_USER_PLAYLIST_ITEM_FAILURE =
  '@webPlayer/DELETE_USER_PLAYLIST_ITEM_FAILURE';

export const SET_STATE = '@webPlayer/SET_STATE';
export const INITIAL_STATE = '@webPlayer/INITIAL_STATE';

export const setState = payload => ({
  type: SET_STATE,
  payload,
});

// GET MediaGenre
export const getMediaPlaylistRequest = (
  getType,
  search = {},
  upc = '',
  getPlaylist = false,
) => ({
  type: GET_MEDIA_PLAYLIST_REQUEST,
  payload: { getType, search, upc, getPlaylist },
});

export const getMediaPlaylistSuccess = (response, action) => ({
  type: GET_MEDIA_PLAYLIST_SUCCESS,
  payload: response,
  action,
});

//GET MediaScript
export const getMediaScriptRequest = payload => ({
  type: GET_MEDIA_SCRIPT_REQUEST,
  payload,
});

export const getMediaScriptSuccess = payload => ({
  type: GET_MEDIA_SCRIPT_SUCCESS,
  payload,
});

export const postUserPlaylistItemRequest = payload => ({
  type: POST_USER_PLAYLIST_ITEM_REQUEST,
  payload,
});

export const postUserPlaylistItemFailure = payload => ({
  type: POST_USER_PLAYLIST_ITEM_FAILURE,
  payload,
});

export const postUserPlaylistItemSuccess = payload => ({
  type: POST_USER_PLAYLIST_ITEM_SUCCESS,
  payload,
});

export const deleteUserPlaylistItemRequest = (
  payload,
  action,
  isUserPlaylist,
) => ({
  type: DELETE_USER_PLAYLIST_ITEM_REQUEST,
  payload,
  action: {
    ...action,
    isUserPlaylist,
  },
});

export const deleteUserPlaylistItemFailure = payload => ({
  type: DELETE_USER_PLAYLIST_ITEM_FAILURE,
  payload,
});

export const deleteUserPlaylistItemSuccess = payload => ({
  type: DELETE_USER_PLAYLIST_ITEM_SUCCESS,
  payload,
});

export const initialState = () => ({
  type: INITIAL_STATE,
});

// POST_USER_MEDIA_PLAY
export const POST_USER_MEDIA_PLAY_REQUEST =
  '@webPlayer/POST_USER_MEDIA_PLAY_REQUEST';
export const POST_USER_MEDIA_PLAY_SUCCESS =
  '@webPlayer/POST_USER_MEDIA_PLAY_SUCCESS';
export const POST_USER_MEDIA_PLAY_SUCCESS_GET_PLAYLIST =
  '@webPlayer/POST_USER_MEDIA_PLAY_SUCCESS_GET_PLAYLIST';
export const postUserMediaPlayRequest = (payload, getPlaylist = false) => ({
  type: POST_USER_MEDIA_PLAY_REQUEST,
  payload: {
    upc: payload,
    getPlaylist,
  },
});
export const postUserMediaPlaySuccess = payload => ({
  type: POST_USER_MEDIA_PLAY_SUCCESS,
  payload,
});

export const postUserMediaPlaySuccessGetPlaylist = payload => ({
  type: POST_USER_MEDIA_PLAY_SUCCESS_GET_PLAYLIST,
  payload,
});
