import { combineEpics } from 'redux-observable';

import studyListEpic from './studyList.epic';
import searchSongEpic from './searchSong.epic';
import songEpic from './song.epic';
import wordEpic from './word.epic';
import listenEpic from './listen.epic';
import webPlayerEpic from './webPlayer.epic';
import mediaInfoEpic from './mediaInfo.epic';
import authEpic from './auth.epic';
import detailEpic from './detail.epic';
import practiceEpic from './practice.epic';
import lectureEpic from './lecture.epic';
import speakEpic from './speak.epic';
import modalEpic from './modal.epic';
import introEpic from './intro.epic';
import paymentEpic from './payment.epic';
import customerEpic from './customer.epic';
import challengeEpic from './challenge.epic';
import couponEpic from './coupon.epic';
import getToastPopupEpic from './toastpopup.epic';
import getFreeLectureRequestEpic from './freelecture.epic';

export default combineEpics(
  studyListEpic,
  searchSongEpic,
  songEpic,
  wordEpic,
  listenEpic,
  webPlayerEpic,
  mediaInfoEpic,
  authEpic,
  detailEpic,
  practiceEpic,
  lectureEpic,
  speakEpic,
  modalEpic,
  introEpic,
  paymentEpic,
  customerEpic,
  challengeEpic,
  couponEpic,
  getToastPopupEpic,
  getFreeLectureRequestEpic,
);
