import * as challenge from 'shared/actions/challenge.action';

export const initialState = {
  isMonthlyPayback: false, // 월 환급 챌린지 유저인지
  isMonthlyPaybackEnded: false, // 월 환급 챌린지 종료!
  isMonthlyPaybackEverSuccess: false, // 환급 조건 만족, 서류제출 페이지 보여주기 ( 월 환급 시 한번이라도 월 챌린지 성공 시, 연 환급 시 12개월 연속 성공 시 )
  isScholarship: false, // 장학금 챌린지 유저인지
  isScholarshipSuccess: false, // 장학금 수령조건 만족!
  isScholarshipFailed: false, // 한번이라도 실패함
  scholarship: 0, // 연환급 금액
  badge: {
    service: false, // 버튼을 노출 할 지 하지 않을지에 대한 부분
    status: null, // NONE, READY, ACTIVE, FAIL, DONE
    text: '',
    on: false,
    badge: '',
  },
  status: null,
  round: {
    roundMonth: 0, // Number // 회차 (월)
    totalMonth: 0, // Number // 총회차 (월)
  },
  media: {
    thumbnail: '', // String { Path } // 곡 썸네일
    title: '', // String // 곡 제목
    artist: '', // String // 곡 아티스트
  },
  mission: {
    roundDay: 0, // Number // x 일차 (일)
    date: '', // Date // 미션 완료까지 남은 시간
    completeInfo: {}, // 미션 완료정보
    recommendScore: null, // Number 점수
    recommendReason: '', // String 점수에 대한 코멘트
    studyImage: '', // String { Path } // 학습 인증샷
    studyScore: 5, //Number // 학습 만족도
    studyReview: '', // String LongText // 학습 후기
    file: '',
  },
  dayList: [], // 학습 현황
  progress: {
    // 진행내역조회
    active: [],
    done: [],
  },
  paper: {
    identity: { status: null },
    passbook: { status: null },
  },
};

const yesterDay = stringDate => {
  const gotDate = new Date(stringDate);
  gotDate.setDate(gotDate.getDate() - 1);
  return gotDate;
};

let nextData = {};
export default (state = initialState, { type, payload, action = {} }) => {
  // console.log({ state });

  switch (type) {
    case challenge.GET_CHALLENGE_STATUS_SUCCESS:
      nextData = state;

      let on = false;
      if (
        payload.result.status === 'READY' ||
        payload.result.status === 'ACTIVE'
      ) {
        on = true;
        if (payload.result.status === 'ACTIVE')
          nextData.badge.badge = '챌린지 진행중';
        else nextData.badge.badge = `챌린지 ${payload.result.text}`;
      }

      nextData.badge.on = on;
      nextData.badge.service = payload.result.service;
      nextData.badge.status = payload.result.status;
      nextData.badge.text = payload.result.text;

      return nextData;

    case challenge.GET_CHALLENGE_DAILY_MISSION_FAILURE: {
      nextData = state;
      if (payload.code === 8100) {
        nextData.status = 'NONE';
      } else {
        nextData.status = null;
      }

      return nextData;
    }
    case challenge.GET_CHALLENGE_DAILY_MISSION_SUCCESS:
      nextData = state;

      const {
        isMonthlyPayback = false,
        isMonthlyPaybackEnded = false,
        isMonthlyPaybackEverSuccess = false,
        isScholarship = false,
        isScholarshipSuccess = false,
        isScholarshipFailed = false,
        scholarship,
        media = {},
        userChallenge = {},
        userChallengeMonth = {},
        userChallengeMonthDay = {},
        userChallengeMonthDayList = [],
      } = payload.result;

      nextData.status = userChallengeMonth.challengeStatus;

      const {
        // 초기화
        studyImage = '',
        studyScore = 5,
        studyReview = '',

        recommendReason = '',
        recommendScore = null,
      } = userChallengeMonthDay;

      nextData.isMonthlyPayback = isMonthlyPayback;
      nextData.isMonthlyPaybackEnded = isMonthlyPaybackEnded;
      nextData.isMonthlyPaybackEverSuccess = isMonthlyPaybackEverSuccess;
      nextData.isScholarship = isScholarship;
      nextData.isScholarshipSuccess = isScholarshipSuccess;
      nextData.isScholarshipFailed = isScholarshipFailed;
      nextData.scholarship = scholarship;

      nextData.media = {
        upc: media.upc,
        artist: media.artist,
        title: media.titleEng,
        thumbnail: media.thumbnail,
      };

      nextData.round = {
        roundMonth: userChallengeMonth.roundMonth,
        totalMonth: userChallenge.totalRound + userChallenge.addRound,
        challengeStatus: userChallengeMonth.challengeStatus,
      };
      nextData.mission = {
        ...state.mission,
        info: {
          ...checkMissionType(userChallengeMonthDay.missionType),
          status: checkStatus(userChallengeMonthDay.completeStatus),
        },
        roundDay:
          userChallengeMonthDay.roundDay < 25
            ? userChallengeMonthDay.roundDay
            : 25, // userChallenge.roundDay, // Number // x 일차 (일)
        completeInfo: userChallengeMonthDay.completeInfo, // 미션 완료정보
        studyImage, // String { Path } // 학습 인증샷
        studyScore, //Number // 학습 만족도
        studyReview, // String LongText // 학습 후기
        recommendReason,
        recommendScore,
      };

      if (nextData.status === 'READY') {
        nextData.mission.endDate = yesterDay(userChallenge.startDateAsString);
      } else if (nextData.mission.info.type === 'monthly') {
        nextData.mission.endDate = userChallengeMonth.endDateAsString; // 월간 미션일 말일까지로 endDate 변경
      } else {
        nextData.mission.endDate = userChallengeMonthDay.dateAsString; // 일간 미션일
      }

      // console.log({ userChallengeMonthDayList });
      nextData.dayList = myMissionList25(userChallengeMonthDayList);

      return nextData;
    //////////////////// GET MISSION DATA END

    case challenge.SET_MISSION:
      nextData = state;
      nextData.mission = {
        ...state.mission,
        ...payload,
      };

      return nextData;

    case challenge.GET_CHALLENGE_LIST_SUCCESS:
      nextData = state;
      let active = payload.result && payload.result.active;
      let done = payload.result && payload.result.done;

      nextData.progress = {
        ...state.progress,
        active: active.reverse(),
        done: done.reverse(),
      };
      return nextData;

    case challenge.POST_CHALLENGE_PAPER_SUCCESS:
      nextData = state;

      // const { payload = {} } = action;
      // const { pathName = '' } = payload;
      // let fileName = pathName;

      nextData.paper = {
        ...state.paper,
        // [fileName]: {
        //   status: 'DONE',
        // },
      };

      console.log('POST_CHALLENGE_PAPER_SUCCESS', { nextData });
      return nextData;

    case challenge.POST_CHALLENGE_PAPER_FAILURE:
      nextData = state;

      // const { payload = {} } = action;
      // const { pathName = '' } = payload;
      // let fileName = pathName;

      nextData.paper = {
        ...state.paper,

        // [fileName]: {
        //   status: 'NONE',
        // },
      };

      console.log('POST_CHALLENGE_PAPER_FAILURE', { payload });
      alert(JSON.stringify(payload));

      return nextData;

    case challenge.GET_CHALLENGE_PAPER_SUCCESS:
      nextData = state;

      nextData.paper = {
        ...state.paper,
        identity: {
          ...state.paper.identity,
          status: payload.result.identityUploadStatus,
        },
        passbook: {
          ...state.paper.passbook,
          status: payload.result.passbookUploadStatus,
        },
      };
      console.log('GET_CHALLENGE_PAPER_SUCCESS', { nextData });
      return nextData;

    case '@develop/MUTATE_CHALLENGE_FOR_TEST': {
      const { status, isMonthlyPayback } = payload;
      return {
        ...state,
        status,
        isMonthlyPayback,
      };
    }
    default:
      return state;
  }
};

const checkStatus = (completeStatus = '') => {
  let res = null;
  if (completeStatus === 'NONE') {
    res = null;
  } else if (completeStatus === 'DONE') {
    res = true;
    // } else if (completeStatus === 'FAIL') {
    //   res = false;
  } else {
    res = null;
  }
  return res;
};

const checkMissionType = (missionType = '') => {
  let res = {};
  if (missionType === 'NONE') {
    // ERROR
    res.type = 'error';
    res.review = false;
  } else {
    const none = missionType.match('NONE');
    const lecture = missionType.match('LECTURE');
    const monthly = missionType.match('MONTHLY');
    const learning = missionType.match('LEARNING');
    const review = missionType.match('REVIEW');
    const popsong = missionType.match('POPSONG');
    console.log({ none, lecture, monthly, learning, review });
    if (!none) {
      if (lecture) res.type = 'lecture';
    }

    if (popsong) res.type = 'popsong';
    if (monthly) res.type = 'monthly';

    if (learning) res.review = false;
    if (review) res.review = true;
  }
  // console.log({ missionType, res });
  return res;
};

const myMissionList = (maxDay = 25) => userChallengeMonthDayList => {
  let res = [];
  for (let i = 0; i < maxDay; i++) {
    res.push({
      // day: i + 1,
      type: null,
    });
  }

  // console.log(res);

  userChallengeMonthDayList.forEach((li, j) => {
    let status = null;
    if (li.completeStatus === 'DONE') status = 'done';
    else if (li.completeStatus === 'PASS') status = 'pass';
    else if (li.completeStatus === 'FAIL') status = 'fail';
    else status = null;

    let roundDay = li.roundDay - 1;

    if (roundDay > 23) roundDay = 24;

    const item = res[roundDay] || {};
    item.type = status;
  });

  // console.log({ res });

  return res;
};

const myMissionList25 = myMissionList(25);
